import { Row, Col, Label, Input, FormGroup } from 'reactstrap'
import { formatPhoneNumber, months } from '../../../../util/utils'
import { DatePicker } from 'reactstrap-date-picker'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'

export const TextInputField = ({ label, name, required, onChange, value }) => {
  return (
    <Row>
      <Col xs="12">
        <Label className="format-label" for={name}>{`${required ? '* ' : ''}${label}`}</Label>
        <Input
          style={{ width: '100%' }}
          type="text"
          name={name}
          // id={name}
          onChange={onChange}
          value={value}
        />
      </Col>
    </Row>
  )
}

export const PhoneInputField = ({ key, label, required, onChange, value }) => {
  return (
    <Row>
      <Col xs="12">
        <Label className="format-label">
          {required ? '* ' : ''}
          {label ? label : key === 'front' ? 'Front Phone' : `Phone Number ${key}`}
        </Label>
        <Input
          type="phone"
          className="number-format-field"
          value={value}
          name={`address_phone_${key}`}
          id={`address_phone_${key}`}
          onChange={onChange}
          autoComplete="false"
        />
      </Col>
    </Row>
  )
}

export const AddressInputDropdown = ({ label, value, onChange, options }) => {
  return (
    <Row style={{ maxHeight: '59px' }}>
      <Col xs="12">
        <Label className="format-label">{label}</Label>
        <Input type="select" value={value} onChange={onChange}>
          <option value="-1">Please Select</option>
          {options}
        </Input>
      </Col>
    </Row>
  )
}

// TODO: prevent selection of yesterday
export const ArtworkDateInput = (artworkField, value, handleChange, currentState) => {
  const { name, html_label, is_required, min_value, max_value } = artworkField

  const handleChangeDate = (dateTime) => {
    if (dateTime === null && !is_required) {
      handleChange(dateTime, artworkField)
      return
    }
    const incomingDate = new Date(dateTime)
    // if min_value, datetime can not be greater than today + min_value (days)
    if (min_value) {
      let minDate = new Date()
      minDate.setDate(minDate.getDate() + min_value)
      if (incomingDate < minDate) {
        toast.info('Date must be greater than ' + minDate.toDateString())
        handleChange(minDate, artworkField)
        return
      }
    }
    if (max_value) {
      let maxDate = new Date()
      maxDate.setDate(maxDate.getDate() + max_value)
      if (incomingDate > maxDate) {
        toast.info('Date must be less than ' + maxDate.toDateString())
        handleChange(maxDate, artworkField)
        return
      }
    }

    handleChange(dateTime, artworkField)
  }

  return (
    <>
      <Label className="number-format-label" for={name}>{`${is_required ? '* ' : ''}${html_label}`}</Label>
      <DatePicker
        id={artworkField.id}
        key={artworkField.id}
        value={value}
        onChange={handleChangeDate}
        calendarPlacement="top"
        placeholder={artworkField.placeholder ? artworkField.placeholder : ''}
      />
    </>
  )
}

export const ArtworkTextInput = (artworkField, value, handleChange, currentState) => {
  const { name, html_label, is_required } = artworkField
  let handleChangeText = (e) => {
    let targetValue = e.target.value
    const textValue = targetValue
    handleChange(textValue, artworkField)
  }

  let handleBlurText = (e) => {
    let pastedValue = e.target.value
    let textValue = ''

    if (artworkField.is_url){
      if (pastedValue.startsWith('https://')) {
        textValue = pastedValue
      } else {
        textValue = 'https://' + pastedValue
      }
    }
    handleChange(textValue, artworkField)
  }


  let newPlaceHolder = artworkField.placeholder ? artworkField.placeholder : ''
  if (artworkField.is_url === true){
    newPlaceHolder = newPlaceHolder.toLowerCase()
  }

  return (
    <>
      <Label className="format-label" for={name}>{`${is_required ? '* ' : ''}${html_label}`}</Label>
      <Input
        // className={artworkField.is_url ? 'no-transform-placeholder' : ''}
        autoComplete="new-off"
        key={artworkField.id}
        style={{ width: '100%' }}
        type="text"
        name={name}
        placeholder={newPlaceHolder}
        onChange={handleChangeText}
        value={value}
      />
    </>
  )
}

export const ArtworkNumberInput = (artworkField, value, handleChange, currentState) => {
  const { name, html_label, is_required } = artworkField
  const handleChangeText = (e) => {
    const textValue = e.target.value

    const parsedValue = parseFloat(textValue)

    // only continue if value is a number
    if (parsedValue > 0 || parsedValue <= 0) {
      // apply value constraints if any
      let maxValue = artworkField.max_value
      let minValue = artworkField.min_value
      if ((!minValue || parsedValue >= minValue) && (!maxValue || parsedValue <= maxValue)) {
        handleChange(textValue, artworkField)
      } else {
        alert(`Value must be bewteen ${minValue} and ${maxValue}`)
      }
    }
  }
  return (
    <>
      <Label className="format-label" for={name}>{`${is_required ? '* ' : ''}${html_label}`}</Label>
      <Input
        autoComplete="new-off"
        key={artworkField.id}
        style={{ width: '100%' }}
        type="number"
        name={name}
        placeholder={artworkField.placeholder ? artworkField.placeholder : ''}
        onChange={handleChangeText}
        value={value}
      />
    </>
  )
}

export const ArtworkPhoneInput = (artworkField, value, handleChange, currentState, canVanityPhone) => {
  const { name, html_label, placeholder, is_required } = artworkField
  const handleChangePhone = (e) => {
    let useVanity = false
    if (document.getElementById('can_vanity_phone')) {
      useVanity = document.getElementById('can_vanity_phone').checked
    }
    let phoneFormat = artworkField.field_format
    let phoneField = document.getElementById('phone')
    if (e.target.id == 'can_vanity_phone' && e.target.checked) {
      phoneFormat = 'none' // as it doesn't exist, no format will be applied.
      phoneField.placeholder = 'Enter the vanity phone'
    }else{
      phoneField.placeholder = placeholder
    }
    let thisValue = e.target.value
    if (e.target.value == 'on') {
      thisValue = ''
    }
    const formattedPhone = formatPhoneNumber(thisValue, artworkField.field_format, useVanity)
    handleChange(formattedPhone, artworkField)
  }
  return (
    <>
      <Label className="format-label" for={name}>
        {`${is_required ? '* ' : ''}${html_label}`}
      </Label>

      {artworkField.can_vanity_phone == true ? (
        <FormGroup check>
          <Label check className="format-label" for='can_vanity_phone'>
            <Input
              type="checkbox"
              id="can_vanity_phone"
              onChange={handleChangePhone}
              />
            Use Vanity Format - Ex: 800-CALL-NOW
          </Label>
          <p><small>(Please review the proof )</small></p>
        </FormGroup>
      ) : (
        null
      )}

      <Input
        id="phone"
        autoComplete="nope"
        style={{ width: '100%' }}
        type="text"
        name={name}
        onChange={handleChangePhone}
        value={value}
        placeholder={artworkField.placeholder ? artworkField.placeholder : ''}
      />
    </>
  )
}

export const ArtworkSelectInput = (artworkField, value, handleChange, currentState) => {
  const { name, html_label, is_required } = artworkField
  const handleChangeSelect = (e) => {
    const textValue = e
    handleChange(textValue, artworkField)
  }
  return (
    <>
      <Label className="format-label" for={name}>{`${is_required ? '* ' : ''}${html_label}`}</Label>
      <ReactSelect
        options={artworkField.select_options}
        placeholder={artworkField.placeholder ? artworkField.placeholder : ''}
        onChange={(state) => handleChangeSelect(state, artworkField)}
      />
    </>
  )
}

export const ArtworkAddressLine1Fields = (artworkField, value) => {
  return (
    <>
      <Label className="format-label">{label}</Label>
      <Input
        key={artworkField.id}
        style={{ width: '100%' }}
        type="text"
        name={artworkField.name}
        placeholder={artworkField.placeholder ? artworkField.placeholder : ''}
        // id={name}
        onChange={(e) => handleChange(e, artworkField)}
        value={value}
      />
    </>
  )
}
