/**
 * call to action component
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


export default function CallToAction(props) {

   const { CallToActionData } = props;
   return (
      <div>
        <Row className="iron-call-to-action-wrap">
        {CallToActionData.length === 1 &&
          CallToActionData.sort((a, b) => a.order - b.order).map((callData, index) => {
            return (
              <Col key={index} xs={12} sm={12} md={12} lg={12} xl={12} className="iron-col">
                <div className="iron-cta-item custom-cta">
                  <Link to={callData.link}>
                    <img src={callData.image.url} alt="call-to-action" />
                    <h4>{callData.title}</h4>
                    <p>{callData.sub_title}</p>
                  </Link>
                </div>
              </Col>
            );
          })
        }

        {CallToActionData.length === 2 &&
          CallToActionData.sort((a, b) => a.order - b.order).map((callData, index) => {
            return (
              <Col key={index} xs={12} sm={6} md={6} lg={6} xl={6} className="iron-col">
                <div className="iron-cta-item custom-cta">
                  <Link to={callData.link}>
                    <img src={callData.image.url} alt="call-to-action" />
                    <h4>{callData.title}</h4>
                    <p>{callData.sub_title}</p>
                  </Link>
                </div>
              </Col>
            );
          })
        }

        {CallToActionData.length > 2 &&
          CallToActionData.sort((a, b) => a.order - b.order).map((callData, index) => {
            return (
              <Col key={index} xs={12} sm={6} md={4} lg={4} xl={4} className="iron-col">
                <div className="iron-cta-item custom-cta">
                  <Link to={callData.link}>
                    <img src={callData.image.url} alt="call-to-action" />
                    <h4>{callData.title}</h4>
                    <p>{callData.sub_title}</p>
                  </Link>
                </div>
              </Col>
            );
          })
        }
      </Row>
      </div>
   )
}
