import React, { useState, useEffect } from 'react'
import {
  fetchProducts,
  fetchLocationProducts,
  fetchPortalCategoryProducts,
  fetchLocationCategories,
} from '../../actions'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ProductCard from './ProductCard'
import InlineContentLoader from '../global/loaders/InlineContentLoader'
import CategoryFilter from './CategoryFilter'
import TagSetFilter from './TagSetFilter'
import { Container, Row, Col, Badge, Collapse, ListGroup, ListGroupItem } from 'reactstrap'
import './ProductList.css'
import 'smart-webcomponents-react/source/styles/smart.default.css'
import LoadingSpinner from '../general/LoadingSpinner'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import MFPager from '../Pager'

import _ from 'lodash'

const ProductList = props => {
  const {
    categories,
    customerGroup,
    portal,
    currentLocation,
    paginated_products,
    userCredits,
    fetchPortalCategoryProducts,
    fetchLocationProducts,
    fetchLocationCategories,
    fetchProducts,
  } = props

  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)
  const page = parseInt(searchParams.get('page'), 10) || 1
  const searchQuery = searchParams.get('search') || ''
  const categoryUrlParams = searchParams.get('categories') ? searchParams.get('categories').split(',').map(Number) : []

  const [productsLoading, setProductsLoading] = useState(true)
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [pagerLoading, setPagerLoading] = useState(true)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [productSearch, setProductSearch] = useState('')
  const [selectedTags, setSelectedTags] = useState({})
  const [coopPercent, setCoopPercent] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [expandCategories, setExpandCategories] = useState(false)
  const [expandTagSets, setExpandTagSets] = useState({})
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [prevLocationId, setPrevLocationId] = useState(currentLocation.id)
  const [prevLocationSearch, setPrevLocationSearch] = useState(location.search)
  const [categoriesFromUrl, setCategoriesFromUrl] = useState(categoryUrlParams)
  const [initialLoad, setInitialLoad] = useState(true)
  const isMobile = windowWidth < 768

  useEffect(() => {
    if (currentLocation.id) {
      const fetchData = async () => {
        await fetchLocationCategories(currentLocation.id)
        setCategoriesLoading(false)
        calculateCoopPercent()
        fetchDataBasedOnURL()
      }
      fetchData()

      const handleResize = () => {
        setWindowWidth(window.innerWidth)
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [categoriesFromUrl, currentLocation.id])

  useEffect(() => {
    if (prevLocationId !== currentLocation.id) {
      let categoryAvailable = false

      for (let categoryId of selectedCategories) {
        const category = categories.find(cat => cat.id === categoryId)
        if (category) {
          if (category.location_ids.length === 0 || category.location_ids.includes(currentLocation.id)) {
            categoryAvailable = true
            const searchParams = new URLSearchParams(location.search)
            setActivePage(1)
            searchParams.set('page', '1')
            history.replace({ search: location.search })
            fetchDataBasedOnURL()
          }
        }
      }

      if (!categoryAvailable) {
        setSelectedCategories([])
        setSelectedTags({})
        setProductSearch('')
        setActivePage(1)
        setExpandCategories(false)
        setExpandTagSets({})
        fetchDataBasedOnURL()
      }

      setPrevLocationId(currentLocation.id)
    }
  }, [currentLocation.id])

  useEffect(() => {
    if (!productsLoading && location.search !== prevLocationSearch) {
      fetchDataBasedOnURL()
      setPrevLocationSearch(location.search)
    }
  }, [location.search])

  useEffect(() => {
    if (!initialLoad) {
      updateURL()
    }
  }, [selectedCategories, selectedTags, productSearch, activePage])

  useEffect(() => {
    if (!categoriesLoading) {
      setInitialLoad(false)
    }
  }, [categoriesLoading])

  const updateURL = () => {
    const params = new URLSearchParams()
    if (selectedCategories.length > 0) params.set('categories', selectedCategories.join(','))
    if (Object.keys(selectedTags).length > 0) {
      Object.entries(selectedTags).forEach(([key, value]) => params.set(`tag_${key}`, value.join(',')))
    }
    if (productSearch) params.set('search', productSearch)
    if (activePage > 1) params.set('page', activePage.toString())

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  const calculateCoopPercent = () => {
    let coopPercent = 0

    // Check if userCredits and its coop_credit and percentage are available and use it
    if (userCredits?.coop_credit?.percentage) {
      coopPercent = userCredits.coop_credit.percentage / 100
    }
    // If not, check portal's coop settings
    else if (portal?.coop_enabled) {
      // Use default location's coop settings if available and enabled
      if (portal.default_location?.coop_enable) {
        if (portal.default_location.coop_overwrite) {
          coopPercent = portal.default_location.coop_percentage_100 / 100
        } else {
          // Use portal's global coop percentage if overwrite is not enabled
          coopPercent = portal.coop_percentage_100 / 100
        }
      }
    }

    setCoopPercent(coopPercent)
  }

  const getCategoriesFromUrl = () => {
    const searchParams = new URLSearchParams(location.search)
    const page = parseInt(searchParams.get('page'), 10) || 1
    const searchQuery = searchParams.get('search') || ''
    const categories = searchParams.get('categories') ? searchParams.get('categories').split(',').map(Number) : []
    return categories
  }

  const fetchDataBasedOnURL = () => {
    const searchParams = new URLSearchParams(location.search)
    const page = parseInt(searchParams.get('page'), 10) || 1
    const searchQuery = searchParams.get('search') || ''
    const categories = getCategoriesFromUrl()

    if (JSON.stringify(categories) !== JSON.stringify(categoriesFromUrl)) {
      setCategoriesFromUrl(categories)
    }
    const tagFilters = {}

    searchParams.forEach((value, key) => {
      if (key.startsWith('tag_')) {
        tagFilters[key.replace('tag_', '')] = value.split(',').map(Number)
      }
    })

    // if (activePage !== page) setActivePage(page)
    if (productSearch !== searchQuery) setProductSearch(searchQuery)
    if (JSON.stringify(selectedCategories) !== JSON.stringify(categories)) setSelectedCategories(categories)
    if (JSON.stringify(selectedTags) !== JSON.stringify(tagFilters)) setSelectedTags(tagFilters)

    refreshProducts(page, searchQuery, tagFilters, categories)
  }

  const refreshProducts = (
    page = activePage,
    searchQuery = productSearch.trim(),
    tagFilters = selectedTags,
    categories = selectedCategories
  ) => {
    setPagerLoading(true)
    if (categories && categories.length !== 0) {
      fetchPortalCategoryProducts(categories, currentLocation.id, tagFilters, page, searchQuery).then(() => {
        setProductsLoading(false)
        setPagerLoading(false)
        setActivePage(page)
      })
    } else if (!categories || categories.length === 0) {
      fetchLocationProducts(tagFilters, page, searchQuery).then(() => {
        setSelectedCategories([])
        setProductsLoading(false)
        setPagerLoading(false)
        setActivePage(page)
      })
    }

    if (!currentLocation.id) {
      fetchProducts(activePage).then(() => {
        setProductsLoading(false)
        setPagerLoading(false)
        setActivePage(page)
      })
    }
  }

  const loadCategoryProducts = category => {
    let locationId = currentLocation.id
    let previouslySelectedCategories = _.uniq(_.compact(_.concat(selectedCategories)))
    let newCategories = previouslySelectedCategories.includes(category)
      ? previouslySelectedCategories.filter(el => el !== category)
      : _.concat(previouslySelectedCategories, category)

    setProductsLoading(true)
    setSelectedCategories(newCategories)

    if (newCategories.length > 0) {
      fetchPortalCategoryProducts(newCategories, locationId, selectedTags, activePage, productSearch).then(() => {
        setProductsLoading(false)
        setPagerLoading(false)
      })
    } else {
      fetchLocationProducts(selectedTags, activePage, productSearch).then(() => {
        setSelectedCategories([])
        setProductsLoading(false)
        setPagerLoading(false)
      })
    }
  }

  const pageChangeHandler = page => {
    refreshProducts(page, productSearch)
  }

  const toggleExpandCategories = () => {
    setExpandCategories(!expandCategories)
    setExpandTagSets({})
  }

  const toggleTagSet = id => {
    setExpandTagSets(prevExpandTagSets => ({
      ...prevExpandTagSets,
      [id]: !prevExpandTagSets[id],
    }))
    setExpandCategories(false)
  }

  const changeTagSetFilter = (selectedTag, tagSet) => {
    setSelectedTags(prevTags => {
      const updatedTags = { ...prevTags }
      if (selectedTag.length > 0) {
        updatedTags[tagSet.id] = selectedTag
      } else {
        delete updatedTags[tagSet.id]
      }
      return updatedTags
    })
  }

  const displayProductGrid = results => {
    if (!customerGroup.id) {
      return <InlineContentLoader />
    } else {
      let filteredProducts = results.filter(
        res => res && !customerGroup.hidden_products_list.find(p => p.id === res.id)
      )

      return (
        <Container>
          <Row>
            {filteredProducts.length === 0 ? (
              <div style={{ width: '100%', textAlign: 'center' }}>No Results</div>
            ) : (
              filteredProducts.map((el, index) => (
                <Col xs={12} sm={6} md={6} lg={4} className="mb-30 d-flex justify-content-center" key={index}>
                  <ProductCard product={el} coopPercent={coopPercent} />
                </Col>
              ))
            )}
          </Row>
        </Container>
      )
    }
  }

  if (!portal || categoriesLoading || productsLoading) {
    return (
      <div className="shop-page-wrap">
        <InlineContentLoader />
      </div>
    )
  }

  let filteredCategories = categories.filter(
    cat => cat && customerGroup.hidden_categories_list && !customerGroup.hidden_categories_list.find(c => c.id === cat)
  )

  return (
    <div className="shop-page-wrap">
      <div className="product-list section-pad iron-shop-wrapper">
        <Container className="products-container">
          <Row>
            <Col xs={12} md={3}>
              <div>
                {filteredCategories.length !== 0 && (
                  <ListGroup>
                    <ListGroupItem tag="button" onClick={toggleExpandCategories} style={{ lineHeight: '24px' }}>
                      <span>Categories</span>
                      {selectedCategories.length > 0 && (
                        <>
                          &nbsp;
                          <Badge pill style={{ fontSize: 15 }}>
                            {selectedCategories.length}
                          </Badge>
                        </>
                      )}
                      <span style={{ float: 'right' }}>
                        {expandCategories ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </span>
                    </ListGroupItem>
                    <Collapse isOpen={expandCategories}>
                      <ListGroupItem>
                        <div className="category-filter">
                          <CategoryFilter
                            loadCategoryProducts={loadCategoryProducts}
                            selectedCategories={selectedCategories}
                            categories={filteredCategories}
                            hidden_categories={customerGroup.hidden_categories_list}
                          />
                        </div>
                      </ListGroupItem>
                    </Collapse>
                  </ListGroup>
                )}

                {portal.portal_tag_sets.length > 0 &&
                  portal.portal_tag_sets.map((tagSet, i) => {
                    if (tagSet.is_filter) {
                      return (
                        <React.Fragment key={i}>
                          <br />
                          <ListGroup>
                            <ListGroupItem style={{ lineHeight: '24px' }} tag="button" onClick={() => toggleTagSet(i)}>
                              <span>{tagSet.name}</span>
                              {selectedTags[tagSet.id] && Object.keys(selectedTags[tagSet.id]).length > 0 && (
                                <Badge pill style={{ marginLeft: '20px' }}>
                                  {Object.keys(selectedTags[tagSet.id]).length}
                                </Badge>
                              )}
                              <span style={{ float: 'right' }}>
                                {expandTagSets[i] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </span>
                            </ListGroupItem>
                            <Collapse isOpen={expandTagSets[i]}>
                              <ListGroupItem>
                                <div className="category-filter">
                                  <TagSetFilter
                                    updateFilter={selectedTag => changeTagSetFilter(selectedTag, tagSet)}
                                    tagSet={tagSet}
                                    selectedTags={selectedTags[tagSet.id]}
                                  />
                                </div>
                              </ListGroupItem>
                            </Collapse>
                          </ListGroup>
                          <br />
                        </React.Fragment>
                      )
                    }
                    return null
                  })}
              </div>
            </Col>

            <Col xs={12} md={9}>
              {!customerGroup.id || customerGroup.hide_products ? (
                <div style={{ width: '100%', textAlign: 'center' }}>No Results</div>
              ) : (
                <>
                  {!isMobile && (
                    <MFPager
                      activePage={activePage}
                      pageCount={parseInt(paginated_products?.total_pages, 10)}
                      // maxPages={
                      //   parseInt(paginated_products?.total_pages, 10) > 5 ? 5 : parseInt(paginated_products.total_pages)
                      // }
                      onChange={pageChangeHandler}
                      range={{ totalItems: paginated_products?.total_records, itemsPerPage: 12 }}
                    />
                  )}
                  <br />
                  {(productsLoading || pagerLoading) && <LoadingSpinner />}
                  {displayProductGrid(paginated_products.products)}
                  <MFPager
                    activePage={activePage}
                    pageCount={parseInt(paginated_products?.total_pages, 10)}
                    // maxPages={
                    //   parseInt(paginated_products?.total_pages, 10) > 5 ? 5 : parseInt(paginated_products.total_pages)
                    // }
                    onChange={pageChangeHandler}
                    range={{ totalItems: paginated_products?.total_records, itemsPerPage: 12 }}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: Object.values(state.categories),
    customerGroup: state.customerGroup,
    portal: state.portal,
    userCredits: state.userCredits,
    paginated_products: state.paginated_products,
    currentLocation: state.currentLocation,
    searchQuery: state.searchQuery,
  }
}

export default connect(mapStateToProps, {
  fetchProducts,
  fetchLocationProducts,
  fetchPortalCategoryProducts,
  fetchLocationCategories,
})(ProductList)
