import React, { useState, useEffect } from 'react'
import { Col, Row, Button, ButtonGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import HeaderMenu from './HeaderMenu'
import HeaderCart from './Cart'
import { Link } from 'react-router-dom'
import { setCurrentLocation, deleteSession, fetchLocations, fetchMyDownloads } from '../../actions'
import { legacyLogOut, enterpriseLogOut } from '../../util/utils'
import branditRails from '../../api/brandit_rails'
import SearchBar from './SearchBar'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import CreditDisplayBox from './CreditDisplayBox'
import SelectPaginate from '../general/SelectPaginate'
import { dispatchSweetAlert } from '../../actions/index'
import './headerDropdown.css'
import LoadingSpinner from '../general/LoadingSpinner'

const HeaderOne = props => {
  const [showLinks, setShowLinks] = useState(false)
  const [fixedHeader, setFixedHeader] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { userCredits, portal, customerGroup, currentLocation, currentUser } = props

  const calculateNoCredits = userCredits => {
    const { coop_credit, credit_note, gift_card_credit, lms_points } = userCredits || {}
    return [coop_credit, credit_note, gift_card_credit, lms_points].every(credit => {
      return credit && credit.remaining === 0
    })
  }

  const [noCredits, setNoCredits] = useState(calculateNoCredits(userCredits))

  useEffect(() => {
    updateWindowWidth()
    window.addEventListener('resize', updateWindowWidth)
    window.addEventListener('scroll', hideBar)
    return () => {
      window.removeEventListener('scroll', hideBar)
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, [currentLocation.id, currentUser.id])

  useEffect(() => {
    checkCredits()
  }, [userCredits])

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  const changeLocation = event => {
    const selectedLocation = event
    props.setCurrentLocation(selectedLocation).then(() => {
      setDropdownOpen(false)
    })
  }

  const hideBar = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    if (scrollTop >= 200) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  }

  const signOut = () => {
    legacyLogOut()
    const isEnterpriseLogout = enterpriseLogOut()

    if (!isEnterpriseLogout) {
      document.location.href = '/log-in'
    }
  }

  const fetchAsyncLocations = (search, page) => {
    return props.fetchLocations(search, page)
  }

  const handleClickLogOut = () => {
    props.dispatchSweetAlert({
      title: 'Are you sure you want to sign out?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, sign out',
      confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      cancelButtonText: 'No, cancel',
      cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
      showLoaderOnConfirm: true,
      onConfirm: signOut,
    })
  }

  const checkCredits = () => {
    setNoCredits(calculateNoCredits(props.userCredits))
  }

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  const logInUrl = '/' + (portal.custom_login_url || 'log-in')
  const isMobile = windowWidth < 768
  const accountColSize = noCredits ? 12 : 12
  const creditColSize = noCredits ? 0 : 6

  const handleAdminSSOLogin = () => {
    branditRails
      .get(`/get_sso_url_admin_show`)
      .then(response => {
        const url = response.data.url
        // set current URL to localstorage
        // localStorage.setItem('storeLoginUrl', window.location.href)
        if (url) {
          window.open(url + `?store_domain=${window.location.href}`, '_self')
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  if (!portal || props.sessionLoading) {
    return null
  }

  const currentTime = new Date()

  return (
    <div>
      {dropdownOpen && <div className="dropdown-backdrop" onClick={toggleDropdown}></div>}
      {portal.show_announcement && 
        (!portal.announcement_starts_at || new Date(portal.announcement_starts_at) <= currentTime) &&
        (!portal.announcement_ends_at || new Date(portal.announcement_ends_at) >= currentTime) ? (
          <div
            className="text-center"
            style={{
              color: portal.announcement_hex_color,
              backgroundColor: portal.announcement_hex_bg,
              padding: '15px',
            }}
            dangerouslySetInnerHTML={{ __html: portal.announcement_text }}
          />
        ) : null}

      <AppBar
        position="static"
        className={`iron-header-wrapper header-color iron-header-v1 ${fixedHeader ? 'header-fixed' : ''}`}
      >
        {portal.portal_has_top_menu === true && (
          <div className="top-links-container">
            <div className="top-links-area-mobile">
              <Row className="mobile-top-row">
                {isMobile ? (
                  <Col xs={0} className="mobile-credits">
                    {' '}
                    <CreditDisplayBox />{' '}
                  </Col>
                ) : null}
                {isMobile && !currentUser.id ? (
                  <>
                    <Link to={logInUrl}>
                      <Button className="button mf-primary-nav-btn" style={{ width: '80px' }}>
                        {portal.portal_has_header_login_link && 'Sign In'}
                      </Button>
                    </Link>
                  </>
                ) : null}

                <Col xs={`${isMobile ? 0 : accountColSize}`} className="mobile-account">
                  {!isMobile ? (
                    <div
                      className="first navbar-location"
                      style={{ minHeight: '50px', marginRight: '20px', maxWidth: '170px' }}
                    >
                      {currentUser && props.locations.length > 1 && (
                        <SelectPaginate
                          id="location"
                          className={dropdownOpen ? 'dropdown-background' : ''}
                          onChange={changeLocation}
                          defaultValue={localStorage.getItem('currentlocationId') || props.currentLocation}
                          value={props.currentLocation}
                          fetchData={fetchAsyncLocations}
                          data="locations"
                        />
                      )}
                    </div>
                  ) : null}

                  {currentUser.id ? (
                    <ButtonGroup>
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} group>
                        <DropdownToggle
                          href="/"
                          tag="a"
                          className={`nav-link dropdown-user-link mf-primary-nav-btn`}
                          onClick={e => e.preventDefault()}
                        >
                          <div className="text-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <span>
                                <span className="my-account">My Account</span>
                                <span>
                                  <i className="fa-duotone fa-user icon-right-margin"></i>
                                </span>
                              </span>
                            </div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu right className="account-drop-down-mobile">
                          {currentUser && currentUser.admin_id && (
                            <>
                              <DropdownItem className="dropdown-item-full-width">
                                <Button
                                  onClick={handleAdminSSOLogin}
                                  className="mf-outline-btn dropdown-button-full-width d-flex align-items-center justify-content-center btn-smaller-height"
                                >
                                  <div className="d-flex align-items-center">
                                    <i className="fa-duotone fa-shield-keyhole icon-right-margin icon-size-20 me-2"></i>
                                    <span>Access Admin</span>
                                  </div>
                                </Button>
                              </DropdownItem>

                              <DropdownItem divider />
                            </>
                          )}
                          <DropdownItem header>Hi, {currentUser.first_name}!</DropdownItem>

                          {currentUser.id && (
                            <>
                              <DropdownItem
                                tag={Link}
                                to="/customer/account/dashboard"
                                className="dropdown-item-custom"
                              >
                                <i className="fa-duotone fa-gauge icon-right-margin icon-size-20"></i>
                                Dashboard
                              </DropdownItem>
                              <DropdownItem tag={Link} to="/customer/account/profile" className="dropdown-item-custom">
                                <i className="fa-duotone fa-user icon-right-margin icon-size-20"></i>
                                Profile
                              </DropdownItem>
                              <DropdownItem tag={Link} to="/customer/account/orders" className="dropdown-item-custom">
                                <i className="fa-duotone fa-box-taped icon-right-margin icon-size-20"></i>
                                Orders
                              </DropdownItem>
                              {props.downloadableItems.length > 0 && (
                                <DropdownItem
                                  tag={Link}
                                  to="/customer/account/downloads"
                                  className="dropdown-item-custom"
                                >
                                  <i className="fa-duotone fa-download icon-right-margin icon-size-20"></i>
                                  Downloads
                                </DropdownItem>
                              )}
                              <DropdownItem
                                tag={Link}
                                to="/customer/account/credit-balance"
                                className="dropdown-item-custom"
                              >
                                <i className="fa-duotone fa-credit-card-blank icon-right-margin icon-size-20"></i>
                                Credit Balance
                              </DropdownItem>
                              <DropdownItem
                                tag={Link}
                                to="/customer/account/addresses"
                                className="dropdown-item-custom"
                                style={{ marginBottom: '10px' }}
                              >
                                <i className="fa-duotone fa-address-book icon-right-margin icon-size-20"></i>
                                Addresses
                              </DropdownItem>

                              <div style={{ width: '250px', marginLeft: '25px' }}>
                                {isMobile && currentUser && props.locations.length > 1 && (
                                  <Dropdown className="first navbar-location">
                                    <SelectPaginate
                                      id="location"
                                      onChange={changeLocation}
                                      defaultValue={localStorage.getItem('currentlocationId') || props.currentLocation}
                                      value={props.currentLocation}
                                      fetchData={fetchAsyncLocations}
                                      data="locations"
                                    />
                                  </Dropdown>
                                )}
                              </div>
                            </>
                          )}
                          <DropdownItem divider />
                          {currentUser.id && (
                            <DropdownItem className="dropdown-item-full-width">
                              <Button
                                onClick={handleClickLogOut}
                                className="mf-outline-btn dropdown-button-full-width d-flex align-items-center justify-content-center btn-smaller-height"
                              >
                                <div className="d-flex align-items-center">
                                  <i className="fa-duotone fa-right-from-bracket icon-right-margin icon-size-20 me-2"></i>
                                  <span>Sign Out</span>
                                </div>
                              </Button>
                            </DropdownItem>
                          )}
                          <div
                            className={`d-flex ${
                              portal.portal_has_terms_and_conditions_link ? '' : 'justify-content-center'
                            }`}
                            style={{ width: '100%' }}
                          >
                            {portal.portal_has_terms_and_conditions_link ? (
                              <>
                                {portal.portal_has_terms_and_conditions_link && (
                                  <DropdownItem className="terms-and-conditions-dropdown">
                                    <div className="terms-and-conditions-link-container">
                                      <Link to={portal.terms_and_conditions_link} className="terms-and-conditions-link">
                                        Terms and Conditions
                                      </Link>
                                    </div>
                                  </DropdownItem>
                                )}
                                <div className="divider">|</div>
                              </>
                            ) : null}
                            <DropdownItem
                              className={`${
                                portal.portal_has_terms_and_conditions_link
                                  ? 'contact-us-dropdown'
                                  : 'dropdown-item-center'
                              }`}
                            >
                              <div
                                className={`${
                                  portal.portal_has_terms_and_conditions_link
                                    ? 'contact-us-link-container'
                                    : 'contact-us-center-container'
                                }`}
                              >
                                <a href={'tel:' + portal.portal_header_phone_number} className="contact-us-link">
                                  Contact Us
                                </a>
                              </div>
                            </DropdownItem>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </ButtonGroup>
                  ) : (
                    <ul id="headerlinks" className={`links ${showLinks ? 'show' : ''}`}>
                      <li className="first">
                        <span>
                          Welcome {currentUser.id ? `${currentUser.first_name} ` : ''}
                          {portal.id !== 31 ? `to ${portal.name}` : ''}
                        </span>
                      </li>
                      {currentUser.id && props.locations.length > 1 && (
                        <li className="first navbar-location">
                          <SelectPaginate
                            id="location"
                            onChange={changeLocation}
                            defaultValue={localStorage.getItem('currentlocationId') || props.currentLocation}
                            value={props.currentLocation}
                            fetchData={fetchAsyncLocations}
                            data="locations"
                          />
                        </li>
                      )}
                      <li className="first">
                        {props.sessionLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <Link to={logInUrl}>
                            <Button className="button mf-primary-nav-btn" style={{ width: '80px' }}>
                              {portal.portal_has_header_login_link && 'Sign In'}
                            </Button>
                          </Link>
                        )}
                      </li>
                    </ul>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        )}

        {isMobile ? (
          // Render for mobile view
          <React.Fragment>
            <Row className="px-sm py-10 mx-lg">
              {/* LOGO */}
              <Col xs={12} className="d-flex justify-content-center align-items-center px-0" style={{ margin: '0' }}>
                <div className="iron-app-logo">
                  <a href="/">
                    {portal.logo?.url ? (
                      <img src={portal.logo.url} alt="UserImage" key={portal.logo.url} style={{ maxHeight: '100%' }} />
                    ) : null}
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center search-credit-row" style={{ marginRight: 0 }}>
              {/* SEARCH BAR */}
              <Col xs={10} className="header-search-bar px-0">
                <SearchBar mobile={true} />
              </Col>

              {/* CREDIT BOXES & CART ICON */}
              <Col xs={2} className="credit-boxes px-0">
                <HeaderCart />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          // Render for non-mobile view
          <Row
            className="px-sm py-30 mx-lg non-mobile-logo-row"
            id="logo-search-credit-cart"
            style={{ justifyContent: 'space-evenly' }}
          >
            {/* LOGO */}
            <Col
              md={3}
              className="d-flex justify-content-center align-items-center px-0"
              style={{ margin: '0', paddingRight: '10px' }}
            >
              <div className="iron-app-logo">
                <a href="/">
                  {portal.logo?.url ? (
                    <img src={portal.logo.url} alt="UserImage" key={portal.logo.url} style={{ maxHeight: '100%' }} />
                  ) : null}
                </a>
              </div>
            </Col>

            {/* SEARCH BAR */}
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center header-search-bar px-0"
              style={{ width: '100%', margin: 0 }}
            >
              <SearchBar mobile={false} />
            </Col>

            {/* CREDIT BOXES & CART ICON */}
            <Col
              md={3}
              className="d-flex justify-content-end align-items-center header-col-sm credit-boxes px-0"
              style={{ margin: 'auto 0' }}
            >
              <CreditDisplayBox mobile={false} />
              &nbsp;&nbsp;
              <HeaderCart />
            </Col>
          </Row>
        )}
        <div className="d-flex iron-header-bottom text-center flex-align-center justify-content-middle">
          {customerGroup.id && currentLocation.hide_header_menu !== true && (
            <HeaderMenu location={currentLocation} hidden_categories={customerGroup.hidden_categories_list} />
          )}
        </div>
      </AppBar>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userCredits: state.userCredits,
    locations: Object.values(state.locations),
    portal: state.portal,
    customerGroup: state.customerGroup,
    cartItemNumber: state.carts,
    currentLocation: state.currentLocation,
    currentUser: state.currentUser,
    downloadableItems: state.myDownloads.items,
    sessionLoading: state.loading.session,
  }
}

export default connect(mapStateToProps, {
  setCurrentLocation,
  fetchLocations,
  fetchMyDownloads,
  deleteSession,
  dispatchSweetAlert,
})(HeaderOne)
