import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Row, Col, Container, Input } from 'reactstrap'
import CheckBox from '../general/CheckBox'
import { clearShippingQuote, setShippingReduction, setShippingLoading } from './checkoutFunctions'
import { addressLabel } from '../../util/utils'
import { dispatchSweetAlert, clearSweetAlert } from '../../actions'

const AddressList = props => {
  const [showMore, setShowMore] = useState(false)
  const [displayedAddresses, setDisplayedAddresses] = useState(3)
  const [searchedAddresses, setSearchAddresses] = useState([])
  const [addressSearch, setAddressSearch] = useState('')
  const [changeAddressConfirm, setChangeAddressConfirm] = useState(false)

  useEffect(() => {
    // if a shipping address is selected,
    // only show the selected address, unless showMore is true
    const shippingAddressIsEmpty = _.isEmpty(props.selectedDeliveryAddress)
    if ( props.addressType === 'delivery' && !shippingAddressIsEmpty && displayedAddresses > 1 && !showMore) {
      setDisplayedAddresses(1)
    }
  }, [props.selectedDeliveryAddress])

  useEffect(() => {
    // if a billing address is selected,
    // only show the selected address, unless showMore is true
    const billingAddressIsEmpty = _.isEmpty(props.selectedBillingAddress)
    if (
      props.addressType === 'billing' &&
      !billingAddressIsEmpty &&
      displayedAddresses > 1 &&
      !showMore
    ) {
      setDisplayedAddresses(1)
    } 
    
  }, [props.selectedBillingAddress])

  useEffect(() => {
    if (_.isEmpty(props.selectedBillingAddress) && displayedAddresses === 1 && props.addressType === 'billing') {
      setDisplayedAddresses(3)
    }
  }, [props.selectedBillingAddress, props.useShippingAsBilling])
  // if delivery address is empty, always show at least three options

  useEffect(() => {
    if (showMore === true) {
      setDisplayedAddresses(props.addresses.length)
    }
  }, [showMore])

  useEffect(() => {
    if (props.addresses) {
      setSearchAddresses(props.addresses)
    }
  }, [props.addresses])

  // for multi-address, when user confirms, then we can set address
  useEffect(() => {
    if (changeAddressConfirm && props.addressType === 'delivery') {
      props.dispatchSweetAlert({
        type: 'info',
        alertMessage: 'Change shipping address for all items in your cart?',
        showCancel: true,
        onConfirm: () => handleSelectAddress(changeAddressConfirm),
        confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
        onCancel: () => props.clearSweetAlert(),
      })
    }
  }, [changeAddressConfirm])

  const handleSelectAddress = address => {
    // if multi-address on this portal, make user confirm first,
    // unless no address selections have been made yet
    if (props.portal.multiple_address_shipping) {
      const addressSelectionHasBeenMade = props.checkout.multiAddressSelection.length > 0
      // only ask for confirmation if there is no address already selected
      if (!changeAddressConfirm && _.isEmpty(props.selectedDeliveryAddress)) {
        // if (!changeAddressConfirm && (addressSelectionHasBeenMade)) {
        setChangeAddressConfirm(address)
        return
      }
    }

    // for delivery address list, there are shipping concerns:
    if (props.addressType === 'delivery') {
      props.setShippingLoading(true)
      // clear any shipping quote/reduction, as it may change
      props.clearShippingQuote()
      props.setShippingReduction(0)
    }

    // if already selected, AND we are clicking on that one, de-select it
    if (!_.isEmpty(props.selectedAddress) && address.id === props.selectedAddress.id) {
      setShowMore(false)
      setDisplayedAddresses(3)
      setSearchAddresses(props.addresses)
      setAddressSearch('')
      props.handleSelectAddress(address, true) // pass true for deselect
    }
    // otherwise, select the address and collapse options
    else {
      // collapse to single address when selection made
      setShowMore(false)
      setDisplayedAddresses(1)
      setSearchAddresses(props.addresses)
      setAddressSearch('')
      props.handleSelectAddress(address)
    }
    // reset confirmation for multi-address
    setChangeAddressConfirm(false)
  }

  // Clickable row displaying address
  const AddressDisplayCard = (address = {}, i) => {
    return (
      <Row
        key={i}
        className={`cart-flex border pt-2 pb-2 ${
          props.selectedAddress.id === address.id ? 'alert-success' : 'white-background'
        }`}
        style={{ cursor: 'pointer', borderRadius: '3px' }}
        onClick={() => handleSelectAddress(address)}
      >
        <Col sm={10}>{addressLabel(address)}</Col>

        <Col sm={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <CheckBox checked={props.selectedAddress.id === address.id} onChange={() => {}} />
        </Col>
      </Row>
    )
  }

  const handleShowMore = () => {
    if (showMore === false) {
      setDisplayedAddresses(props.addresses.length)
      setShowMore(true)
    } else {
      setDisplayedAddresses(3)
      setShowMore(false)
    }
  }

  const handleSearchAddresses = e => {
    setAddressSearch(e.target.value)
    let searchQuery = e.target.value.toLowerCase()

    let addressesList = props.addresses.map(obj => {
      return Object.keys(obj).reduce((accumulator, key) => {
        if (
          key === 'id' ||
          key === 'address_line_1' ||
          key === 'address_line_2' ||
          key === 'address_phone' ||
          key === 'city' ||
          key === 'country' ||
          key === 'country_code' ||
          key === 'full_name' ||
          key === 'state' ||
          key === 'zip_code' ||
          key === 'company_name'
        ) {
          accumulator[key] = obj[key]
        }
        return accumulator
      }, {})
    })

    if (searchQuery.includes(' ')) {
      searchQuery.split(' ').map(query => {
        addressesList = addressesList.filter(obj =>
          Object.values(obj)
            .filter(val => !!val && typeof val === 'string')
            .some(val => val.toLowerCase().includes(query))
        )
      })
    } else {
      addressesList = addressesList.filter(obj =>
        Object.values(obj)
          .filter(val => !!val && typeof val === 'string')
          .some(val => val.toLowerCase().includes(searchQuery))
      )
    }
    setSearchAddresses(props.addresses.filter(address => addressesList.map(address => address.id).includes(address.id)))
  }

  // If we are in the billing list, and address matches shipping, and both selections are present, render null
  if (
    props.selectedBillingAddress.id &&
    props.selectedDeliveryAddress.id &&
    props.selectedBillingAddress.id === props.selectedDeliveryAddress.id &&
    props.addressType === 'billing'
  ) {
    return null
  }

  return (
    <Container>
      {/* Multi-address info text */}
      {props.addressType === 'delivery' &&
      props.portal.multiple_address_shipping &&
      _.isEmpty(props.selectedDeliveryAddress) ? (
        <p style={{ paddingTop: '5px' }}>
          <strong>Multi-Address Shipping Available</strong> - Select default shipping address below, then optionally
          assign additional addresses to each product / product quantity.
        </p>
      ) : null}

      {props.checkout.multiAddressSelection.length > 0 &&
      props.checkout.multiAddressSelection
        .map(sel => sel.address && sel.address.id)
        .filter((id, i, arr) => arr.indexOf(id) === i).length > 1 ? (
        <p style={{ paddingTop: '5px' }}>
          <em>Multiple Addresses selected - See Below</em>
        </p>
      ) : null}

      {/* Selected address goes here, if present, at top of list */}
      {props.selectedAddress && !_.isEmpty(props.selectedAddress) ? AddressDisplayCard(props.selectedAddress) : null}

      {/* {Search For Addresses Input} */}
      {showMore && displayedAddresses > 1 ? (
        <Row className={props.selectedAddress && !_.isEmpty(props.selectedAddress) ? null : 'mt-3'}>
          <Col>
            <Input
              placeholder="Search For Addresses"
              onChange={handleSearchAddresses}
              spellCheck="true"
              type="text"
              value={addressSearch}
            />
          </Col>
        </Row>
      ) : null}

      {/* Then map through other address */}
      {searchedAddresses &&
        searchedAddresses.map((address, i) => {
          if (i > displayedAddresses - 1) {
            return
          }
          // if there is a selected address and this is it, don't render it in the list - it is rendered above
          else if (!_.isEmpty(props.selectedAddress) && props.selectedAddress.id === address.id) {
            return
          } else if (displayedAddresses === 1 && !_.isEmpty(props.selectedAddress)) {
            return
          } else {
            return AddressDisplayCard(address, i)
          }
        })}

      {/* "Show More" button */}
      {!showMore && ((props.addresses && props.addresses.length > 3) || Object.keys(props.selectedAddress) > 0) ? (
        <Row>
          <Col>
            <p className="m-0" style={{ cursor: 'pointer' }} onClick={handleShowMore}>
              Show More...
            </p>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    checkout: state.checkout,
    portal: state.portal,
  }
}

export default connect(mapStateToProps, {
  setShippingReduction,
  clearShippingQuote,
  setShippingLoading,
  dispatchSweetAlert,
  clearSweetAlert,
})(AddressList)
