import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Row, Col, Container} from 'reactstrap'
import { Link } from 'react-router-dom'

class FooterOne extends Component {
  render() {
    if (!this.props.portal.id) {
      return null
    }
    return (
      <footer id='application-footer' className="iron-footer-v1" >
        <div className="iron-footer-top" >
          <Container>
            <Row>
              {/* Footer Left Half */}
              <Col xs={12} md={6} lg={6} xl={6} >
                <h6>CUSTOMER SERVICE & SUPPORT</h6>
                <div dangerouslySetInnerHTML={{ __html: this.props.portal.footer_text_1}}></div>
                {this.props && this.props.portal.portal_has_terms_and_conditions_link && (
                          <Link to={this.props.portal.terms_and_conditions_link}>Terms And Conditions</Link>
                      )}
                {this.props.portal.show_footer_contact_us ? 
                  <p style={{marginBottom: '34px'}} className='footer-contact-us'>
                    <a href="/contact-us">Contact Us</a>
                  </p> 
                : null}
              </Col>  

              {/* Footer Right Half */}
              <Col xs={12} md={6} lg={6} xl={6} className="footer-right">
                <h6>&nbsp;</h6>
                <div dangerouslySetInnerHTML={{ __html: this.props.portal.footer_text_2}}></div>
                <p style={{marginBottom: '34px'}}>©Copyright {new Date().getFullYear()}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    portal: state.portal
  };
};

export default connect(mapStateToProps, {  })(FooterOne);
