import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const CurrencyIcon = ({usesLmsPoints = false, price}) => {
   const [currency, setCurrency] = useState(usesLmsPoints ? 'Points' : '$')

   useEffect(() => {
		if (usesLmsPoints) {
			setCurrency(price === '1' ? "Point" : "Points")
		}
   }, [price])

  return (
		<span>{currency}</span>
  )
}

// map state to props
const mapStateToProps = ({ currentLocation }) => {
  return { 
    currentLocation
   }
}

export default connect(mapStateToProps)(CurrencyIcon);