import { useSelector } from 'react-redux'
import dollar_price from '../general/DollarPrice'
import { Table, Tooltip, ListInlineItem, Col, Row, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

export const RenderTemplateFields = templateFields => {
  return (
    templateFields &&
    templateFields.map((templateField, i) => {
      return <li key={i}>{RenderTemplateFieldExtraInfo(templateField)}</li>
    })
  )
}

export const RenderTemplateFieldExtraInfo = field => {
  let displayValue
  // for fields with options, render them in a list
  switch (field.input_type) {
    case 'dropdown':
    case 'multiselect':
    case 'radio':
    case 'image_selection':
    case 'checkbox':
      displayValue = (
        <ul style={{ display: 'inline' }}>
          {field.nested_data.map((option, key) => {
            const optionLabel = (
              <>
                {option.option_title}
                {parseInt(option.price, 10) > 0 ? (
                  <>
                    {' '}
                    - {dollar_price(option.price)} {option.quantity ? `[QTY: ${option.quantity}]` : ''}
                  </>
                ) : (
                  ''
                )}
              </>
            )
            return <ListInlineItem key={key}>{optionLabel}</ListInlineItem>
          })}
        </ul>
      )
      break
    case 'date':
      displayValue = moment.tz(field.value ? JSON.parse(field.value) : '', moment.tz.guess()).format('ll')
      break
    case 'input':
      const containsPrice =
        field.nested_data &&
        field.nested_data.length > 0 &&
        !!field.nested_data[0].price &&
        parseInt(field.nested_data[0].price, 10) > 0
      displayValue = field.value ? (
        containsPrice ? (
          <>
            {JSON.parse(field.value)} - {dollar_price(field.nested_data[0].price)}
          </>
        ) : (
          JSON.parse(field.value)
        )
      ) : (
        ''
      )
      break
    default:
      displayValue = field.value ? JSON.parse(field.value) : ''
      if (typeof displayValue === 'object' && Object.keys(displayValue).length === 0) {
        displayValue = ''
      }
      break
  }
  const fieldTitle = field.field_title.slice(0, 1).toUpperCase() + field.field_title.slice(1)
  return (
    <>
      <strong>{fieldTitle}:</strong> {displayValue}
    </>
  )
}

export const RenderConfigurableExtraInfo = variant => {
  const option = variant.nested_data[0]
  const currentLocation = useSelector(state => state.currentLocation)
  return (
    <>
      <strong>{variant.display_name ? variant.display_name : variant.name}</strong>: {option.name}
      {/* for user-seleted configurable  */}
      {currentLocation.show_price && option.has_price_modifier ? (
        <> + {dollar_price(option.price_modifier.price_modifier_cents)}</>
      ) : null}
      {/* for pre-configured configurable */}
      {currentLocation.show_price && option.configurable_has_price_modifier ? (
        <> + {dollar_price(option.price_modifier.price_modifier_cents)}</>
      ) : null}
    </>
  )
}

export const RenderArtworkExtraInfo = (
  artwork,
  fileType = null,
  uniqueKey,
  mini = false,
  tooltipOpen,
  toggleArtworkPreview
) => {
  const thisKey = `artwork_preview_${uniqueKey}`
  if (fileType === 'pdf') {
    return artwork.name
  } else {
    return (
      <>
        <span style={{ textDecoration: 'underline' }} id={thisKey} href="#">
          <strong>{artwork.artwork_type} Artwork:</strong> {artwork.name}
        </span>
        {mini ? null : (
          <Tooltip
            placement="right"
            target={thisKey}
            isOpen={tooltipOpen[thisKey]}
            toggle={e => toggleArtworkPreview(thisKey, e)}
          >
            <img src={artwork.image2} alt="artwork-thumbnail" />
          </Tooltip>
        )}
      </>
    )
  }
}

export const RenderCartItemInfo = (cartItem, mini, tooltipOpen, toggleArtworkPreview) => {
  const portal = useSelector(state => state.portal)
  const currentLocation = useSelector(state => state.currentLocation)
  return (
    <>
      {/* Product Name */}
      <strong>
        <Link to={`/product/${cartItem.product_id}/${portal.id}/${currentLocation.id}`}>{cartItem.name}</Link>
      </strong>

      <div id="cartItemAdditionalInfo">
        {/* Selected Configurable Variant Options */}
        {cartItem.selectedOptions && cartItem.selectedOptions.length > 0
          ? cartItem.selectedOptions.map((option, i) => {
              return (
                <p key={i} style={{ fontSize: '.9em', margin: 'auto' }}>
                  <strong>{option.variant.display_name ? option.variant.display_name : option.variant.name}:</strong>{' '}
                  {option.name}
                </p>
              )
            })
          : null}

        {/* Backorder Text */}
        {cartItem && cartItem.show_backorder_text && (
          <span style={{ fontSize: '0.95em' }} className="danger-color">
            {portal.backorder_text}
          </span>
        )}

        {/* Digital Proofing Info */}
        <div className="dpInfoTopCart">
          {/* <div className="number-format-label"> */}
          {cartItem &&
          cartItem.is_digital_proofing &&
          cartItem.digitalProofing.nested_data &&
          cartItem.digitalProofing.nested_data[0] &&
          cartItem.digitalProofing.nested_data[0].approved_date ? (
            <>
              {'Approved ' +
                moment
                  .tz(cartItem.digitalProofing.nested_data[0].approved_date, moment.tz.guess())
                  .format('MMMM Do YYYY, h:mm a')}
              <br />
            </>
          ) : null}
          {cartItem.digitalProofing ? (
            <a
              href={cartItem.digitalProofing && cartItem.digitalProofing[0] ? cartItem.digitalProofing[0].artwork.pdf_preview.url : '#'}
              target="_blank"
            >
              View Proofing Preview
            </a>
          ) : null}
        </div>

        {/* Template Info */}
        {cartItem && cartItem.template
          ? RenderLineItemExtraInfo(
              cartItem.template.template_fields,
              'templateFields',
              cartItem,
              mini,
              tooltipOpen,
              toggleArtworkPreview
            )
          : null}

        {/* Configurable Info */}
        {cartItem && cartItem.flags && cartItem.flags.is_configurable
          ? RenderLineItemExtraInfo(
              cartItem.variants,
              'configurable',
              cartItem,
              mini,
              tooltipOpen,
              toggleArtworkPreview
            )
          : null}

        {/* Bundle Info */}
        {cartItem && cartItem.is_bundle === true
          ? RenderLineItemExtraInfo(
              cartItem.bundle.products,
              'bundleProduct',
              cartItem,
              mini,
              tooltipOpen,
              toggleArtworkPreview
            )
          : null}

        {/* Artwork Info (Simple Product) */}
        {cartItem && cartItem.flags && cartItem.flags.is_artwork
          ? RenderLineItemExtraInfo(
              cartItem.artwork,
              'artwork',
              cartItem.cart_item_id,
              cartItem,
              mini,
              tooltipOpen,
              toggleArtworkPreview
            )
          : null}

        {/*  Setup Charge */}
        {cartItem && !!cartItem.setup_charge && cartItem.setup_charge > 0 ? (
          <span>
            <strong>Setup Charge: </strong>
            {dollar_price(cartItem.setup_charge)}
          </span>
        ) : null}
      </div>
    </>
  )
}

// iterable - array of: (infoTypes)
// - artwork       - bundle products
// - configurable  - templateFields
export const RenderLineItemExtraInfo = (
  iterable,
  infoType,
  cartItemId,
  mini = false,
  tooltipOpen,
  toggleArtworkPreview
) => {
  if (iterable.nested_data) {
    iterable = iterable.nested_data
  }
  // breakable bundles will have products nested in a single group
  if (iterable[0]?.bundle_group_products) {
    iterable = iterable[0].bundle_group_products
  }

  const RenderBundleProduct = (bundleProduct, mini = false, tooltipOpen, toggleArtworkPreview) => {
    let bundleProductTdContent = null
    // ARTWORK EXTRA INFO
    const artwork = bundleProduct.artwork ? bundleProduct.artwork[0]?.nested_data[0] : null
    if (artwork) {
      bundleProductTdContent = () => (
        <>
          <strong>
            {bundleProduct.product_name} x {bundleProduct.product_quantity}
          </strong>
          <br />
          {RenderArtworkExtraInfo(
            artwork,
            null,
            `${artwork.cart_item_conf_product_id}`,
            mini,
            tooltipOpen,
            toggleArtworkPreview
          )}
        </>
      )
    }
    // CONFIG/TEMPLATE EXTRA INFO
    else if (bundleProduct.nested_data && bundleProduct.nested_data.length > 0) {
      let configurableSelections = _.filter(
        bundleProduct.nested_data,
        option => option.configurable_variant_id || option.configurable_option_id
      )
      if (configurableSelections && configurableSelections[0] && configurableSelections[0].configurable_option_sort_order) {
        configurableSelections = _.orderBy(configurableSelections, 'configurable_option_sort_order', 'asc')
      } else {
        configurableSelections = _.orderBy(configurableSelections, 'configurable_variant_sort_order', 'asc')
      }
      bundleProductTdContent = () => (
        <>
          <strong>
            {bundleProduct.product_name} x {bundleProduct.product_quantity}
          </strong>
          <ul style={{ listStyleType: 'none' }}>
            {configurableSelections.map((option, i) => {
              return <li key={i}>{RenderConfigurableExtraInfo(option)}</li>
            })}
          </ul>
        </>
      )
    }
    // Else simply render name & qty
    else {
      bundleProductTdContent = () => (
        <>
          <strong>
            {bundleProduct.product_name} x {bundleProduct.product_quantity}
          </strong>
        </>
      )
    }

    return (
      <td>
        {bundleProductTdContent()}

        {!_.isEmpty(bundleProduct.template) ? (
          <ul style={{ listStyleType: 'none' }}>{RenderTemplateFields(bundleProduct.template)}</ul>
        ) : null}
      </td>
    )
  }

  const tdContent = (item, mini, tooltipOpen, toggleArtworkPreview) => {
    switch (infoType) {
      case 'bundleProduct':
        return RenderBundleProduct(item, mini, tooltipOpen, toggleArtworkPreview)
      case 'templateFields':
        return <td>{RenderTemplateFieldExtraInfo(item)}</td>
      case 'configurable':
        const option = item
        return <td>{RenderConfigurableExtraInfo(option)}</td>
      case 'artwork':
        const artwork = item
        return (
          <td>
            {RenderArtworkExtraInfo(
              artwork,
              null,
              `${
                artwork.cart_item_conf_product_id
                  ? artwork.cart_item_conf_product_id
                  : `${cartItemId}${artwork.id}${artwork.artwork_id}`
              }`,
              mini,
              tooltipOpen,
              toggleArtworkPreview
            )}
          </td>
        )
      default:
        return <td></td>
    }
  }

  return (
    <Table className="mb-0">
      <tbody className="cartItemExtraInfo">
        {iterable &&
          iterable.map((item, index) => {
            // item: artwork, option, etc.
            return (
              <tr key={index} className="cartItemExtraInfo">
                {tdContent(item, mini, tooltipOpen, toggleArtworkPreview)}
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
}
