import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT_DETAILS_SKU,
  FETCH_PORTAL_CATEGORY_PRODUCTS,
  FETCH_PRODUCT_SUGGESTIONS,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return action.payload
    case FETCH_PRODUCT_DETAILS_SKU:
      return { ...state, [action.payload.product_id]: action.payload }
    case FETCH_PORTAL_CATEGORY_PRODUCTS:
      return action.payload
    case FETCH_PRODUCT_SUGGESTIONS:
      return action.payload
    default:
      return state
  }
}
