import React, { useState, useEffect } from 'react'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { deleteCartItem, clearSweetAlert, dispatchSweetAlert } from '../../actions'
import { compose } from 'redux'
import './headerCart.css'
import HeaderCartItemsList from '../Cart/HeaderCartItemsList'
import { ShoppingCart } from 'react-feather'

const Cart = props => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (props.location.pathname === '/cart' && isOpen) {
      setIsOpen(false)
    }
  }, [props.location.pathname, isOpen])

  const handleClose = () => {
    setIsOpen(false)
    props.history.push('/cart')
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const cartIsEmpty = () => !props.cartItems || (props.cartItems && props.cartItems.length === 0)

  return (
    <>
      <Button
        id="cart-popover"
        type="button"
        color="inherit"
        variant="contained"
        className="icon-btn"
        style={{
          minWidth: '51px',
        }}
      >
        <ShoppingCart fill="currentColor" style={{ color: 'white' }} />
        {props.cartItems?.length && props.cartItems.length > 0 ? (
          <span className="cart-icon-badge">{props.cartItems.length}</span>
        ) : null}
      </Button>

      <Popover
        trigger="hover click"
        boundariesElement="viewport"
        positionFixed={true}
        placement="bottom-start"
        target="cart-popover"
        isOpen={isOpen}
        toggle={toggle}
        modifiers={{
          offset: {
            enabled: true,
            offset: '-500px',
          },
          computeStyle: {
            enabled: true,
            y: 'right',
          },
        }}
        className="cart-popper-shadow"
      >
        <PopoverHeader className="p-0 mf-sticky">
          {!cartIsEmpty() ? (
            props.cartItems && props.cartItems.length > 0 ? (
              <Button
                onClick={handleClose}
                className="btn mf-primary-btn m-3"
                style={{
                  width: 'calc(100% - 2rem)',
                  // width: '100%',
                  height: '100%',
                  borderRadius: '3px',
                }}
              >
                Go To Cart
              </Button>
            ) : null
          ) : (
            <p className="p-3 m-0">Your cart is empty</p>
          )}
        </PopoverHeader>

        {!cartIsEmpty() ? (
          <PopoverBody>
            <HeaderCartItemsList isCheckout={true} mini={true} />
          </PopoverBody>
        ) : null}
      </Popover>
    </>
  )
}

const mapStateToProps = state => {
  return {
    portal: state.portal,
    customerGroup: state.customerGroup,
    cartItems: state.cartItems,
    currentLocation: state.currentLocation,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    deleteCartItem,
    dispatchSweetAlert,
    clearSweetAlert,
  })
)(Cart)
