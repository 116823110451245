import _ from 'lodash'

export default (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_CART_LOCALLY':
      return []
    case 'ADD_CART_ITEM':
      return state
    case 'SET_CART_ITEMS':
      return action.payload || []
    case 'UPDATE_CART_ITEMS':
      return action.payload
    case 'DELETE_CART_ITEM':
      // response returns ID of deleted cartItem - return state without that item
      return _.reject(state, e => parseInt(e.cart_item_id) === parseInt(action.payload))
    case 'UPDATE_CART_ITEM':
      // Find item index using _.findIndex
      var index = _.findIndex(state, { cart_item_id: action.payload.id })
      const oldItem = state[index]

      // Replace item at index using native splice
      state.splice(index, 1, { ...oldItem, quantity: action.payload.quantity })
      return state
    case 'CLEAR_CART':
      return []
    case 'UPDATE_CART_ITEM_INVENTORY':
      const cartItemsOutOfStock = action.payload.cart_items;
      if (cartItemsOutOfStock?.length > 0) {
        return state.map(item => {
          const updatedItem = cartItemsOutOfStock.find(i => i.cart_item_id === item.cart_item_id)
          if (updatedItem) {
            return { ...item, flags: { ...item.flags, has_inventory: updatedItem.has_inventory } }
          }
          return item
        })
      }
      return state;
    default:
      return state
  }
}
