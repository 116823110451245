import {useEffect} from 'react'
import ProductTemplateField from './ProductTemplateField';
import {connect} from 'react-redux'
import _ from 'lodash'

const ProductTemplateFields = props => {

  const { isBundle, bundleProduct, templateFields, templateFieldsSelection } = props

  const valueIsValid = (v) => {
    if (typeof v === 'object') {
      return Object.keys(v).length > 0 && Object.keys(v).map(key => v[key] === true)
    } else if (typeof v === 'string') {
      return v && v.length > 0
    } else {
      return (parseInt(v, 10) > 0) && !!v
    }
  }

  const validateTemplateField = (field) => {
    if (valueIsValid(field.value) && field.is_required) {
      return true
    } else if (!field.is_required) {
      return true
    } else {
      return false
    }
  }

  const validateTemplateFields = fieldsWithValues => {
    return fieldsWithValues.every( templateField => {
      return validateTemplateField(templateField)
    })
  }

  // Should return true if dependency requirements are met, or if no dependency
  const dependencyRequirementsMet = (field) => {
    const dependencyFieldIds = field.dependency_field_ids
    const values = dependencyFieldIds.map( field_id => {
      const field = isBundle && templateFieldsSelection[bundleProduct.bundle_group_product_id] ? templateFieldsSelection[bundleProduct.bundle_group_product_id][field_id] : templateFieldsSelection[field_id]
      return field && field.value
    })
    switch (field.dependency) {
      case 'NO':
        return true
      case 'AND':
        // Check if all "AND" dependencies have a value
        if ( values.every( val => valueIsValid(val)) ) {
          return true
        }
        break;
      case 'OR':
        // Check if any "OR" dependencies are selected
        if ( values.some( val => valueIsValid(val)) ) {
          return true
        }
        break;
      default:
        return false
    }
  }
  return (
    <>
      {templateFields && templateFields.sort((a,b) => (b.sort_order != null) - (a.sort_order != null) || a.sort_order - b.sort_order)
      .map( (field, index) => {
        return (
          <div key={index} className="description-container">
            <div className="mb-10">
              <ProductTemplateField
                field={field}
                dependencyRequirementsMet={dependencyRequirementsMet}
                isBundle={isBundle === true}
                bundleProduct={bundleProduct}
              />
            </div>
          </div>
        )
      })}
   </>
  )
}

const mapStateToProps = state => ({ 
  templateFieldsSelection: state.productPage.templateFields
})

export default connect(mapStateToProps)(ProductTemplateFields)