import { Button } from 'reactstrap';
import InlineContentLoader2 from '../global/loaders/InlineContentLoader2';

function Loading(props) {
  if (props.error) {
    return (
      <div className="w-100 text-center">
        <h3>Oops, something went wrong...</h3>
        <Button className="button btn-active btn-md mb-4" onClick={() => window.location.reload()}>
          Please Click Here to Refresh your Session
        </Button>
        <p>If the above button does not resolve the issue, please contact us.</p>
      </div>
    )
  } else if (props.timedOut) {
    return (
      <div className="w-100 text-center">
        <h3>This is taking a while...</h3>
        <Button className="button btn-active btn-md mb-4" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    )
  } else if (props.pastDelay) {
    return <InlineContentLoader2 />
  } else {
    return null
  }
}

export default Loading