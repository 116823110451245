// Hamburger Menu that displays next to Cart icon in header

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { saveSearchQuery } from '../../actions'
import SidebarMenu from './SidebarMenu'
import LoadingSpinner from '../general/LoadingSpinner'
import _ from 'lodash'
import { Menu } from 'react-feather'
const HeaderMenu = props => {
  const [loading, setLoading] = useState(false)
  const [displayedMenuItems, setDisplayedMenuItems] = useState([])
  const [open, setOpen] = useState(false)

  const menuItems = props.portal.portal_menu?.menu_items || []

  const toggleDrawer = () => {
    setOpen(!open)
  }
  // change menu items based on categories
  useEffect(() => {
    const formattedMenuItems = buildMenuItems(menuItems, props.categories)
    setDisplayedMenuItems(formattedMenuItems)
  }, [props.portal, props.categories])

  const handleNav = () => {
    setLoading(true)
    window.scrollTo(0, 0)
    props.saveSearchQuery('')
    setTimeout(() => {
      setLoading(false)
    }, 650)
  }

  const buildMenuItems = (portalMenuItems, categories) => {
    return (
      portalMenuItems
        // filter out menu items that visible === false
        .filter(a => a.visible === true) //.sort((a, b) => a.order - b.order)
        // filter category menu items linked location categories outside of this location
        .filter(menuItem => {
          if (menuItem.link_type === 'Category') {
            // if this menu item's category ID is not in this location's category list, exclude it
            const validCategoryIds = categories.map(cat => cat.id)
            if (!validCategoryIds.includes(menuItem.category_id)) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        })
        .sort((a, b) => {
          const orderDifference = a.order - b.order
          if (orderDifference !== 0) {
            return orderDifference
          } else {
            const dateA = new Date(a.created_at),
              dateB = new Date(b.created_at)
            return dateA - dateB
          }
        })
    )
  }

  const navPages = menuItems => {
    let listItems = []
    menuItems.map((page, i) => {
      listItems.push(
        <li key={i}>
          {page.link_type === 'URL' && (
            // links may open in a new tab, so send localStorage to the tab for authentication
            <a
              onClick={e => {
                e.preventDefault()
                const newWindow = window.open(page.url, page.is_internal_link ? '_self' : '_blank')
                if (!page.is_internal_link) {
                  newWindow.onload = function () {
                    const domain = new URL(page.url).origin // Extract domain from the URL to restrict the postMessage to the same domain
                    newWindow.postMessage({ token: localStorage.getItem('authToken') }, domain)
                  }
                }
              }}
              href={page.url}
              className="nav-link"
              target={page.is_internal_link ? '_self' : '_blank'}
            >
              {page.title}
            </a>
          )}

          {page.link_type == 'Page' && (
            <Link to={page.url} className="nav-link">
              {' '}
              {page.title}{' '}
            </Link>
          )}
          {page.link_type == 'Category' &&
            props.hidden_categories &&
            !props.hidden_categories.find(c => c.id == page.category_id) && (
              <Link to={`/shop?categories=${page.category_id}`} className="nav-link">
                {' '}
                {page.title}{' '}
              </Link>
            )}
        </li>
      )
    })

    if (props.portal.portal_has_eshop === true && props.portal.portal_has_eshop_link === true) {
      listItems.push(
        <li key={listItems.length + 1}>
          <Link to="/shop" className="nav-link">
            {localStorage.getItem('shop_def')}
          </Link>
        </li>
      )
    }

    return listItems
  }

  const toggleSidebar = () => {
    document.getElementById('sidebar').classList.toggle('active')
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {/* display above 1024px width */}
      <div className="display-desktop flex-align-center">
        {loading ? <LoadingSpinner /> : null}
        <ul className="iron-header-menu m-auto" onClick={handleNav}>
          {navPages(displayedMenuItems)}
        </ul>
      </div>

      <div className="display-mobile">
        <SidebarMenu open={open} toggleDrawer={toggleDrawer} navPages={navPages(displayedMenuItems)} />
      </div>

      {/* Mobile Menu Icon (Hamburger) */}
      <div
        className="display-mobile"
        onClick={toggleDrawer}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '15px',
          top: '-10px',
        }}
      >
        <Menu />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    portal: state.portal,
    categories: state.categories,
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps, {
  saveSearchQuery,
})(HeaderMenu)
