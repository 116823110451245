import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Component } from 'react'
import _ from 'lodash'

class TagSetFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTags: this.props.selectedTags && this.props.selectedTags.length > 0 ? [...this.props.selectedTags] : [],
    }

    this.updateSelection = this.updateSelection.bind(this)
  }

  updateSelection(tag) {
    let updatedTags = _.clone(this.state.selectedTags)
    if (this.state.selectedTags.includes(tag.id)) {
      let index = updatedTags.indexOf(tag.id)
      updatedTags.splice(index, 1)
    } else {
      updatedTags.push(tag.id)
    }
    this.setState({ selectedTags: updatedTags })
    this.props.updateFilter(updatedTags)
  }
  render() {
    let sortedTags = _.cloneDeep(this.props.tagSet.portal_tags)
    sortedTags.sort((a, b) => a.order - b.order)
    return (
      <div>
        {sortedTags.map(tag => {
          return (
            <div key={tag.id}>
              <div className="individual-category">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="selected_category"
                      id="selected_category"
                      className="selected_category"
                      checked={this.state.selectedTags.includes(tag.id)}
                      onChange={() => this.updateSelection(tag)}
                    />
                  }
                  label={tag.name}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default TagSetFilter
