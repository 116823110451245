import React from 'react'
import {
  fetchLocationProduct,
  addItemToCart,
  fetchCartItems,
  dispatchSweetAlert,
  clearSweetAlert,
  clearProductPage,
} from '../../actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Switch, Route } from 'react-router-dom'
import ProductDetailsShow from './ProductDetailsShow'
import InlineContentLoader2 from '../global/loaders/InlineContentLoader2'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { cartItemsRequireCheckForApproval, returnCartItemsRequireApprovalError } from '../Cart/cartFunctions'

class ProductDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      inStock: props.product.flags && props.product.flags.has_inventory ? props.product.flags.has_inventory : false,
      productLoading: true,
    }
  }

  componentDidMount() {
    this.fetchProduct()
  }

  componentWillUnmount() {
    this.props.clearProductPage()
  }

  redirectIfDigitalProofing = () => {
    // if product, or, if bundle, any child products, are DP, dispatch SweetAlert
    const userId = this.props.currentUser.id
    if (
      !userId &&
      ((this.props.product.flags && this.props.product.flags.is_digital_proofing) ||
        (this.props.product.flags &&
          this.props.product.flags.is_bundle &&
          this.props.product.bundle.products.some(prod => prod.is_digital_proofing)))
    ) {
      const sweetAlertProps = {
        onConfirm: () => this.props.history.push('/' + this.props.portal.custom_login_url),
        onCancel: this.sweetAlertCancelFunction.bind(this),
        confirmBtnText: 'Go to sign in page',
        cancelBtnText: 'Go back',
        alertMessage: 'You must be signed in to view this product.',
        confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
        cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
      }

      this.props.dispatchSweetAlert(sweetAlertProps)
    }
  }

  sweetAlertCancelFunction = () => {
    this.props.history.goBack()
    this.props.clearSweetAlert()
  }

  componentDidUpdate = (prevProps, prevState) => {
    const prevPropsProductId = prevProps.match.params.product_id
    const thisPropsProductId = this.props.match.params.product_id

    // when a new product is loaded:
    if (prevPropsProductId !== thisPropsProductId) {
      this.redirectIfDigitalProofing()
    }

    const location_id = this.props.currentLocation.id

    // re-fetch product if current location changes
    if (prevProps.currentLocation.id && location_id && prevProps.currentLocation.id !== location_id) {
      this.fetchProduct()
    }
    // re-fetch product when visiting a new product
    else if (
      (!prevProps.currentLocation.id && location_id) ||
      (prevPropsProductId && thisPropsProductId && prevPropsProductId !== thisPropsProductId && location_id)
    ) {
      this.fetchProduct()
    }

    // check stock on each update
    // if (this.props.product && this.props.stocks[this.props.product.sku] && this.props.stocks[this.props.product.sku] > 0 && this.state.inStock === false && prevState.inStock === false) {
    if (
      this.props.product &&
      this.props.product.flags &&
      this.props.product.flags.has_inventory === true &&
      this.state.inStock !== true &&
      prevState.inStock !== true
    ) {
      this.setState({ inStock: true })
      // this.setInStock()
    } else if (
      this.props.product &&
      this.props.stocks[this.props.product.sku] &&
      this.props.stocks[this.props.product.sku] <= 0 &&
      this.state.inStock === true &&
      prevState.inStock === true
    ) {
      // this.outOfStock()
    }

    const { product } = this.props

    if (product && product.flags && product.flags.bundle_type === 'Breakable') {
      this.setInStock(true)
    }
  }

  setInStock(val) {
    if (val === true && this.state.inStock !== true) {
      this.setState({ inStock: true })
    } else if (val === false && this.state.inStock !== false) {
      this.setState({ inStock: false })
    }
  }
  outOfStock() {
    if (this.state.inStock !== false) {
      this.setState({ inStock: false })
    }
  }

  fetchProduct() {
    this.props.fetchLocationProduct(this.props.match.params.product_id).then(() => {
      this.checkLoad()
      this.setState({ productLoading: false })
    })
  }

  checkLoad() {
    const { customerGroup, product } = this.props

    if (
      (customerGroup.id && customerGroup.hide_products) ||
      (customerGroup.hidden_products_list &&
        customerGroup.hidden_products_list.find(p => p && product && p.id === product.id))
    ) {
      this.props.history.push('/')
      return
    }

    this.redirectIfDigitalProofing()
  }

  // *** ADD TO CART FUNCTION ***

  // onSuccess: function to call after a successful add to cart
  addProductToBasket = (product, quantity, digitalProofingProduct, onSuccess = null, closePreviewCallback = null) => {
    const productCopy  = JSON.stringify(product)
    let productForForm = JSON.parse(productCopy)
    let userId = this.props.currentUser.id

    // Check for requires approval rule for cart items and product
    if (cartItemsRequireCheckForApproval(product, this.props.cartItems, this.props.currentLocation)) {
      this.props.returnCartItemsRequireApprovalError(
        this.props.cartItems,
        product,
        this.addProductToBasket,
        digitalProofingProduct,
        quantity,
        closePreviewCallback
      )
      return
    }

    const formData = new FormData()

    formData.append('quantity', quantity)

    if (digitalProofingProduct) {
      formData.append('digital_proofing_product', JSON.stringify(digitalProofingProduct))
    }

    // NON-BUNDLE TEMPLATES
    // if template fields are present (non-bundle)
    if (Object.keys(this.props.templateFields).length > 0) {
      const formattedTemplateFields = this.formatTemplateFields(Object.values(this.props.templateFields))
      const template = {
        template_id: Object.values(this.props.templateFields)[0].template_id,
        nested_data: formattedTemplateFields,
      }
      productForForm.template_field_values = template.nested_data
      productForForm.template_id = template.template_id
      delete productForForm.template
    }

    // if artwork is present
    if (product.artwork && Object.keys(product.artwork).length > 0) {
      productForForm.artwork = product.artwork.artwork_id
    }

    // *** FOR BUNDLES ***

    // to get the full sku with variant suffix
    const buildSkuSuffix = (selectedOptionsByVariantId, bundleProduct) => {
      const sortedVariants = bundleProduct.variants //.sort( v => v.order)
      // map each variant to its sku based on the user selection

      const sortedSkuCodes = sortedVariants
        .map(variant => {
          // find the user selection. If user has not made one, return null
          const selectedOption = selectedOptionsByVariantId[variant.variant_id]
          if (!selectedOption) {
            return null
          } else {
            const skuCode = selectedOption.sku_code
            return skuCode
          }
        })
        .filter(skuCode => skuCode !== null) // remove null values

      // Build SKU suffix to be appended to base SKU
      // map each code to a formatted string with "-"
      let skuSuffix = sortedSkuCodes.map(skuCode => {
        return `-${skuCode}`
      })

      // concatenate the array of formatted codes to a single string and return
      skuSuffix = skuSuffix.join('')
      return skuSuffix
    }

    if (product.bundle && product.bundle.products && product.bundle.products.length > 0) {
      // get artwork, configurable, and DP selection data from redux
      const configurableSelection = this.props.configurableSelection
      const artworkSelection = this.props.artworkSelection
      const digitalProofingSelection = this.props.digitalProofingSelection
      const templateSelection = this.props.templateFields

      // get bundle products and
      // map each product to itself plus selected_options
      let newProducts = product.bundle.products.map(bundleProduct => {
        let bundleProductWithSelectedOptions = { ...bundleProduct, selected_options: [] }

        // find the selected options (including artwork) for this product
        // (they are mapped to their bundle_group_product_id)
        let thisProductSelectedConfigs = configurableSelection[bundleProduct.bundle_group_product_id]
          ? configurableSelection[bundleProduct.bundle_group_product_id].selected_options
          : {}
        let thisProductSelectedArtwork = artworkSelection[bundleProduct.bundle_group_product_id]
          ? artworkSelection[bundleProduct.bundle_group_product_id].selected_options[0]
          : {}
        let thisProductSelectedDp = digitalProofingSelection[bundleProduct.bundle_group_product_id]
          ? digitalProofingSelection[bundleProduct.bundle_group_product_id].proofingPreview
          : {}

        // assign product_full_sku in case it was cleared from de-selection. TODO: improve redux synchronicity
        if (thisProductSelectedConfigs && !_.isEmpty(thisProductSelectedConfigs)) {
          const skuSuffix = buildSkuSuffix(thisProductSelectedConfigs, bundleProduct)
          bundleProductWithSelectedOptions.product_full_sku = bundleProductWithSelectedOptions.product_sku + skuSuffix
        }

        // Exclusive cases (configurable, artwork, DP, etc):

        // if prod is configurable
        if (Object.keys(thisProductSelectedConfigs).length > 0) {
          thisProductSelectedConfigs = Object.values(thisProductSelectedConfigs).map(option => {
            // API only needs the key configurable_option_id for the option
            return {
              // ...option,
              configurable_option_id: option.id,
            }
          })
          // assign to selected_options and return
          bundleProductWithSelectedOptions.selected_options = thisProductSelectedConfigs

          // else if product is an artwork
        } else if (Object.keys(thisProductSelectedArtwork).length > 0) {
          bundleProductWithSelectedOptions.selected_options = [{ artwork_id: thisProductSelectedArtwork.artwork_id }]

          // else if product is digital proofing
        } else if (bundleProduct.is_digital_proofing) {
          bundleProductWithSelectedOptions.selected_options = [
            { digital_proofing_product_id: thisProductSelectedDp.id },
          ]
        }

        // Inexclusive cases (templates):
        if (bundleProduct.has_template && templateSelection) {
          const thisProductTemplateFields = templateSelection[bundleProduct.bundle_group_product_id]
          if (thisProductTemplateFields) {
            const bundle_product_template_id = Object.values(thisProductTemplateFields)[0].template_id
            const thisProductTemplateFieldsWithValues = this.formatTemplateFields(
              Object.values(this.props.templateFields[bundleProduct.bundle_group_product_id])
            )
            bundleProductWithSelectedOptions.template_field_values = thisProductTemplateFieldsWithValues
            bundleProductWithSelectedOptions.template_id = bundle_product_template_id
          }
        }

        // remove nested_data on all bundle products before calling API
        return _.omit(bundleProductWithSelectedOptions, ['variants', 'nested_data'])
      })
      productForForm.bundle.products = newProducts
    } // *** END FOR BUNDLES

    function prepareProductDataForCart(productData) {
      const fieldsToInclude = ['id', 'quantity', 'sku', 'artwork', 'template_field_values', 'bundle', 'product_sku_id']
      let preparedData = {}

      fieldsToInclude.forEach(field => {
        if (productData.hasOwnProperty(field)) {
          preparedData[field] = productData[field]
        }
      })
      return preparedData
    }

    productForForm = prepareProductDataForCart(productForForm)

    // append product last because we modify its artwork field, and bundle products
    formData.append('product', JSON.stringify(productForForm))

    this.props
      .addItemToCart(formData)
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }

        this.props.dispatchSweetAlert({
          alertMessage: 'Product successfully added to cart',
          type: 'success',
          confirmBtnText: 'Go To Cart',
          onConfirm: this.goToCart,
          cancelBtnText: 'Keep Shopping',
          confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
          cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
        })

        const locationId = this.props.currentLocation.id
        this.props.fetchCartItems(userId, locationId, this.props.portal, this.props.currentLocation)
      })
      .catch(err => {
        toast.error('Product could not be added to the cart.')
      })
  } // end add to cart function

  formatTemplateFields = templateFields => {
    return templateFields
      .map(fieldWithValue => {
        let formattedValue
        if (typeof fieldWithValue.value === 'object') {
          formattedValue = _.cloneDeep(fieldWithValue.value)
          formattedValue = JSON.stringify(formattedValue)
        } else {
          formattedValue = JSON.stringify(fieldWithValue.value)
        }
        return {
          ...fieldWithValue,
          value: formattedValue,
          template_field_id: fieldWithValue.template_field_id,
          id: fieldWithValue.template_field_id,
        }
      })
      .map(el => _.pick(el, ['template_field_id', 'input_type', 'value', 'template_id']))
  }

  goToCart = () => {
    this.props.clearSweetAlert()
    this.props.history.push('/cart')
  }

  goToShop = () => {
    this.props.clearSweetAlert()
    this.props.history.push('/shop')
  }

  render() {
    const { product } = this.props

    if (product && Object.keys(product).length > 1) {
      return (
        <>
          <ProductDetailsShow
            addProductToBasket={this.addProductToBasket}
            product_loading={this.props.product_loading}
            inStock={this.state.inStock}
            setInStock={this.setInStock.bind(this)}
          />
        </>
      )
    } else {
      return <InlineContentLoader2 />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bundle:
      state.bundle[
        state.products[ownProps.match.params.product_id]
          ? state.products[ownProps.match.params.product_id].bundle_id
          : null
      ],
    product: state.productPage.product,
    templateFields: state.productPage.templateFields,
    locations: Object.values(state.locations),
    customerGroup: state.customerGroup,
    stocks: state.stocks,
    portal: state.portal,
    currentLocation: state.currentLocation,
    product_loading: state.productPage.product_loading,
    artworkSelection: state.productPage.artworkSelection,
    cartItems: state.cartItems,
    configurableSelection: state.productPage.configurableSelection,
    digitalProofingSelection: state.productPage.digitalProofingSelection,
    currentUser: state.currentUser,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchLocationProduct,
    addItemToCart,
    fetchCartItems,
    dispatchSweetAlert,
    clearSweetAlert,
    clearProductPage,
    returnCartItemsRequireApprovalError,
  })
)(ProductDetails)
