import React, { useEffect, useState, useRef } from 'react'
import {
  updateDeliveryAddress,
  createDeliveryAddress,
  deleteTraditionalAddress,
  validateAddress,
  dispatchSweetAlert,
  createDeliveryAddressNew,
} from '../../../actions'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import './FormAddressWrapper.css'
import { setBillingAddress, setDeliveryAddress } from '../../Checkout/checkoutFunctions'
import AddressList from '../../addresses/AddressList'
import EditAddressModal from '../../addresses/EditAddressModal'
import AddAddressModal from '../../addresses/AddAddressModal'

const FormAddressWrapper = ({
  currentUser,
  setAddressesCallback,
  addressType,
  portal,
  manageAddresses,
  setManageAddresses,
  delivery_addresses,
  updateDeliveryAddress,
  validateAddress,
  deleteTraditionalAddress,
  dispatchSweetAlert,
  afterCreateAddress,
  ...props
}) => {
  const [newAddressModal, setNewAddressModal] = useState(false)
  const [editAddressModal, setEditAddressModal] = useState(false)
  const [address, setAddress] = useState({})
  const anotherElementRef = useRef(null)
  useEffect(() => {
    if (manageAddresses) {
      document.getElementById('testtt').focus()
    }
  }, [manageAddresses])

  const toggleNewAddressModal = () => {
    setNewAddressModal(!newAddressModal)
  }
  const closeNewAddressModal = () => {
    setNewAddressModal(false)
  }

  const toggleEditAddressModal = address => {
    if (editAddressModal) {
      // resetFormFields()
      setEditAddressModal(!editAddressModal)
    } else {
      setAddress(address)
      setEditAddressModal(!editAddressModal)
    }
  }

  const handleDeleteTraditionalAddress = (address_id, address_type) => {
    dispatchSweetAlert({
      alertMessage: 'Are you sure you want to delete this address?',
      type: 'warning',
      cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
      showCancel: true,
      onConfirm: () => confirmDeleteTraditionalAddress(address_id, address_type),
      confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      confirmBtnText: 'Yes, delete!',
    })
  }

  const confirmDeleteTraditionalAddress = (address_id, address_type) => {
    let user_id = currentUser.id
    let portal_id = portal.id
    deleteTraditionalAddress(address_id, user_id, portal_id, address_type)
  }

  const closeEditModal = () => {
    setEditAddressModal(false)
  }

  const handleUpdateAddress = addressData => {
    const formData = new FormData()
    Object.entries(addressData).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('is_shipping', true)

    updateDeliveryAddress({ address_id: addressData.id, values: formData, callback: closeEditModal })
  }

  const afterCreateAddressCallback = address => {
    if (afterCreateAddress) {
      afterCreateAddress(address)
    }
    closeNewAddressModal()
    setManageAddresses(false)
  }

  const handleCreateAddress = addressData => {
    addressData.is_shipping = true
    props.createDeliveryAddressNew({
      addressData,
      callback: createAddressCallback,
      isInternationalShipping: portal.is_international_shipping,
    })
  }

  const handleValidateAddress = (addressData, method = 'create') => {
    let onSaveAddress
    if (method === 'update') {
      onSaveAddress = handleUpdateAddress
    } else {
      onSaveAddress = handleCreateAddress
    }
    validateAddress({ addressData, onSaveAddress, isInternationalShipping: portal.is_international_shipping })
  }

  const handleClickManageAddresses = () => {
    setManageAddresses(!manageAddresses)
    anotherElementRef.current.focus()
  }

  return (
    <div className="float-right">
      <div id="testtt" className="dp-address-form pb-3">
        {manageAddresses ? (
          <Button
            id="close-address-mgmt-btn"
            className="mf-outline-danger-btn mt-1.5 float-right mr-3"
            size="sm"
            onClick={handleClickManageAddresses}
          >
            Close Address Management
          </Button>
        ) : (
          <Button
            id="add-edit-address-btn"
            className="mf-outline-btn float-right mr-3"
            size="sm"
            onClick={handleClickManageAddresses}
          >
            Add / Edit Addresses
          </Button>
        )}
        {/* dummy div to prevent autoselecting above button */}
        <div tabIndex={-1} ref={anotherElementRef}>
        </div>
        {manageAddresses && !props.customerGroup.hide_create_shipping_address && (
          <Button
            size="sm"
            className="mf-outline-btn mr-3 float-right default-border-radius"
            onClick={toggleNewAddressModal}
          >
            Add New Address
          </Button>
        )}
      </div>
      {manageAddresses && <AddressList onEdit={toggleEditAddressModal} onDelete={handleDeleteTraditionalAddress} />}

      <EditAddressModal
        isOpen={editAddressModal}
        toggle={toggleEditAddressModal}
        address={address}
        handleValidateAddress={handleValidateAddress}
        closeEditModal={closeEditModal}
      />

      <AddAddressModal
        isOpen={newAddressModal}
        toggle={toggleNewAddressModal}
        afterCreateAddress={afterCreateAddressCallback}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    portal: state.portal,
    delivery_addresses: Object.values(state.delivery_addresses).filter(address => address.address_type === 'delivery'),
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    currentUser: state.currentUser,
    customerGroup: state.customerGroup,
  }
}

export default connect(mapStateToProps, {
  updateDeliveryAddress,
  createDeliveryAddress,
  deleteTraditionalAddress,
  dispatchSweetAlert,
  setBillingAddress,
  setDeliveryAddress,
  validateAddress,
  createDeliveryAddressNew,
})(FormAddressWrapper)
