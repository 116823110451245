import { titleizeField } from "../../util/utils"
import {connect} from 'react-redux'
import _ from 'lodash'

const CheckoutErrorsPanel = ({ errors, checkoutErrors }) => {

  // filter out errors that don't have a value
  const errorsPresent = _(errors).omitBy(_.isNull).value()

  if (Object.values(errorsPresent).length > 0) {
    return (
      <div className="alert alert-danger" role="alert">
        {Object.entries(errorsPresent).map( ( [key, val], i ) => {
          if (val) { // if error is present
            return (
              <div key={i}>
                <p><strong>{titleizeField(key)}:</strong></p>
                <p>{val.message}</p>
              </div>
            )
          }
        })}
        {errors['shipping_errors']}
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    checkoutErrors: state.checkout.errors,
  }
}

export default connect(mapStateToProps)(CheckoutErrorsPanel)