/**
 * featuresv2 component
 */
/* eslint-disable */
import React from 'react';
import { Row, Col } from 'reactstrap';

function FeaturesV2(props) {

   const { siteFeatures } = props;
   return (
     <div>
       <Row className="iron-features-v2 my-0 pt-50">
         {siteFeatures.map((siteFeature, index) => (
           <Col key={index} xs={12} sm={12} md={4} lg={4} xl={4} className="iron-col py-0 mb-25 mb-md-0">
             <Row className="iron-features-post iron-shadow p-15 d-lg-flex rounded">
               <Col xs={12} md={2} className="iron-feature-icon py-md-0 py-15 mx-auto">
                 <span className="d-flex justify-content-center align-items-center mx-auto">
                   {siteFeature.icon && siteFeature.icon.url ? (
                     <img src={siteFeature.icon.url} alt="feature-icon" />
                   ) : (
                     <i className="material-icons">{siteFeature.material_icon}</i>
                   )}
                 </span>
               </Col>
               <Col xs={12} md={10} className="iron-features-content pl-lg-40 pl-md-60 pl-sm-15 text-md-left text-center">
                 <h4 className="text-capitalization font-bold mb-10">{siteFeature.title}</h4>
                 <p className="text-capitalize mb-5">{siteFeature.description}</p>
               </Col>
             </Row>
           </Col>
         ))}
       </Row>
     </div>
   )
}

export default FeaturesV2;
