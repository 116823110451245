import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const sumProductQty = (a, b) => a + b.quantity

/**
 * MaxQuantity component displays the remaining quantity of a product the user can purchase.
 *
 * @param {object} props - The component props.
 * @param {object} props.maxQuantities - The maximum quantities allowed for products.
 * @param {object} props.product - The product information.
 * @param {object} props.currentUser - The current user information.
 * @param {array} props.cartItems - The items currently in the user's cart.
 * @param {object} props.portal - The portal information.
 * @returns {JSX.Element|null} - The rendered component or null.
 */

const MaxQuantity = ({ maxQuantities, product, currentUser, cartItems, portal }) => {
  const productCartItems = cartItems.filter(item => item.product_id === product.id)
  const cartItemQuantity = productCartItems.length > 0 ? productCartItems.reduce(sumProductQty, 0) : 0
  const logInUrlRedirect = '/' + (portal.custom_login_url || 'log-in') + '?redirect_to=' + window.location.pathname
  const initialMaxQuantities = product.max_quantity?.max_qty || 0
  const thisProductMaxQty = maxQuantities[product.id] || 0
  const productHasMaxQtyDefinition = Object.keys(maxQuantities).includes(product.id.toString())

  return productHasMaxQtyDefinition && thisProductMaxQty - cartItemQuantity >= 0 ? (
    <div className="max mb-12">
      <hr className="equal-margin" />
      <span className="text-14 ml-1 mb-12">
        {currentUser && currentUser.id ? (
          <>
            Your Quantity Remaining: {thisProductMaxQty - cartItemQuantity}
            <span className="grey-out-text"> of {initialMaxQuantities} assigned</span>
          </>
        ) : (
          <>Please {<Link to={logInUrlRedirect}>Sign In</Link>} To See Your Allotted Quantity</>
        )}
      </span>
    </div>
  ) : null
}

MaxQuantity.propTypes = {
  maxQuantities: PropTypes.object.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    max_quantity: PropTypes.shape({
      max_qty: PropTypes.number,
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  portal: PropTypes.shape({
    custom_login_url: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = state => ({
  product: state.productPage.product,
  customerGroup: state.customerGroup,
  portal: state.portal,
  maxQuantities: state.maxQuantities,
  cartItems: state.cartItems,
  currentUser: state.currentUser,
})

export default connect(mapStateToProps)(MaxQuantity)
