import {useState, useEffect} from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from 'react-redux';

const CategoryFilter = props => {
  // const [openCategories, setOpenCategories] = useState([])
  const [displayedCategories, setDisplayedCategories] = useState([])

  useEffect(() => {
    setDisplayedCategories(props.categories && props.categories.sort((a, b) => a.name.localeCompare(b.name)))
  }, [props.categories])
  
  return (
    <div>
      {displayedCategories.map((parent, index) => {
        if (parent.enabled === true) {
          // Parent Category
          return (
            <div key={parent.id}>
              <div className="individual-category">
              <FormControlLabel
                  control={
                  <Checkbox
                      name="selected_category"
                      id="selected_category"
                      className="selected_category"
                      checked={props.selectedCategories.includes(parent.id)}
                      onChange={() => props.loadCategoryProducts(parent.id)}
                  />
                  } 
                  label={parent.name_alias && parent.name_alias.length > 0 ? parent.name_alias : parent.name}
              />
              </div>

              {parent.children_category !== [] && parent.children_category.filter(cat => cat && !props.hidden_categories.find(c => c.id == cat.id)).sort((a, b) => a.name.localeCompare(b.name)).map((child, childIndex) => {
                // Child category
                return (
                  <div className="child-category" key={childIndex}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="selected_category"
                          id="selected_category"
                          className="selected_category"
                          checked={props.selectedCategories.includes(child.id)}
                          onChange={() => props.loadCategoryProducts(child.id)}
                        />
                      } 
                      label={child.name_alias && child.name_alias.length > 0 ? child.name_alias : child.name}
                    />
                  </div>
                )
              })}
            </div>
          )
        }
      })
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps)(CategoryFilter)