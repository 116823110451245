import {
  CREATE_DELIVERY_ADDRESS,
  UPDATE_DELIVERY_ADDRESS,
  DELETE_DELIVERY_ADDRESS,
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_DELIVERY_ADDRESSES':
      return action.payload || state
    case CREATE_DELIVERY_ADDRESS:
      return action.payload || state
    case UPDATE_DELIVERY_ADDRESS:
      return action.payload || state
    case DELETE_DELIVERY_ADDRESS:
      return action.payload || state
    default:
      return state
  }
}
