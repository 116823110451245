import { useEffect } from 'react'
import { legacyLogOut } from '../util/utils'
import { STORE_APP_VERSION } from '../constants'
import { toast } from 'react-toastify'

export const checkApiVersion = apiVersion => {
  const storedApiVersion = localStorage.getItem('apiVersion')

  if (apiVersion && (!storedApiVersion || apiVersion !== storedApiVersion)) {
    // API version mismatch, store the new version and log out the user
    localStorage.setItem('apiVersion', apiVersion)
    legacyLogOut()
    
    toast.info('A new version of the app is available. Please log in again to access your account.', {
      toastId: 'newVersionAvailable',
      autoClose: 5000,
    })
  }
}

export const checkStoreVersion = () => {
  const currentVersion = STORE_APP_VERSION
  const storedVersion = localStorage.getItem('appVersion')

  if (!storedVersion || compareVersions(storedVersion, currentVersion) < 0) {
    // Stored version is older than the current version, log out & reload the page
    console.info('New version detected, logging out and reloading the page')
    localStorage.setItem('appVersion', currentVersion)
    legacyLogOut()
    window.location.reload()
  }

  // Update the stored version in localStorage
  localStorage.setItem('appVersion', currentVersion)
}

const useVersionCheck = () => {
  useEffect(() => {
    checkStoreVersion()
  }, [])
}

// Helper function to compare semantic versions in the format "5.12.31"
const compareVersions = (v1, v2) => {
  const v1Parts = v1.split('.').map(Number)
  const v2Parts = v2.split('.').map(Number)

  for (let i = 0; i < v1Parts.length; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1
    } else if (v1Parts[i] > v2Parts[i]) {
      return 1
    }
  }

  return 0
}

export default useVersionCheck
