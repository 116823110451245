import dollar_price from '../general/DollarPrice'
import { connect } from 'react-redux'
import { Popover, PopoverBody } from 'reactstrap'
import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

const CreditDisplayBox = ({ currentLocation, userCredits, portal, currentUser, history }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeKey, setActiveKey] = useState(-1)
  const [displayedCreditsKey, setDisplayedCreditsKey] = useState('')
  const creditsRef = useRef(null)

  // credits are ordered by priority, so the first one with an amount > 0 will be displayed in the header
  const creditTypes = ['lms_points', 'coop_credit', 'credit_note', 'gift_card_credit']

  useEffect(() => {
    // find the key that will be displayed in the header
    // this is the first available credit with an amount
    const newKey = creditTypes.find((key) => {
      // for lms points and co-op, we need to check the portal/location
      if (key === 'lms_points' && !portal.use_lms_points) return false
      if (key === 'coop_credit' && (!portal.coop_enabled || !currentLocation.coop_enable)) return false
      return userCredits[key].remaining > 0
    })

    setDisplayedCreditsKey(newKey)
  }, [userCredits, portal])

  const toggle = (index) => {
    if (activeKey === index) {
      setActiveKey(-1)
    } else {
      setActiveKey(index)
    }
    setIsOpen(!isOpen)
  }
  const contextDictionary = portal.context_dictionary

  const creditDefinitionLabels = {
    credit_note: contextDictionary['account_credit_def'],
    lms_points: contextDictionary['lms_points'],
    coop_credit: contextDictionary['coop_def'],
    gift_card_credit: 'Gift Cards',
  }

  // key refers to the key in the userCredits object, should have corresponding label in creditDefinitionLabels
  // main indicates it's rendering inside the display box in header (not the popover)
  const RenderCredits = ({ credits_key, main }) => {
    // Only show credits in certain conditions and if amount > 0
    const showLmsFunds = portal.use_lms_points
    const showCreditFunds = currentLocation.show_price === true
    const showCoOpFunds =
      currentUser && portal.coop_enabled && currentLocation.coop_enable && currentLocation.show_price === true
        ? true
        : false
    const amountInCents =
      userCredits[credits_key] && userCredits[credits_key].remaining >= 0 ? userCredits[credits_key].remaining : 0
    if (credits_key === 'lms_points' && !showLmsFunds) return null
    if (credits_key === 'credit_note' && !showCreditFunds) return null
    if (credits_key === 'coop_credit' && !showCoOpFunds) return null
    if (!amountInCents && credits_key !== 'coop_credit') return null
    if (credits_key === displayedCreditsKey && !main) return null

    const label = creditDefinitionLabels[credits_key]
    return (
      <div>
        {label ? <p>{label}:</p> : <p>{credits_key === 'lms_points' ? 'Points' : <>&nbsp;</>}</p>}
        <h6
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {dollar_price(amountInCents)}
        </h6>
      </div>
    )
  }

  const CreditsForPopover = creditTypes
    .reduce((memo, key) => {
      // exclude any with amount === 0
      if (key === displayedCreditsKey) return memo
      if (key === 'lms_points' && !portal.use_lms_points) return memo

      // exclude coop if not enabled
      if (key === 'coop_credit' && (!portal.coop_enabled || !currentLocation.coop_enable)) return memo
      return userCredits[key] && userCredits[key].remaining ? [...memo, key] : memo
    }, [])
    .map((key, index) => {
      return <RenderCredits key={key} credits_key={key} />
    })

  const displayedAmount = userCredits[displayedCreditsKey] && userCredits[displayedCreditsKey].remaining

  const handleClick = (e) => {
    history.push('/customer/account/credit-balance', { showTab: 'credits' })
  }

  if (!displayedAmount) return null

  if (CreditsForPopover.length === 0) {
    // we don't want to show the box at all if show_price is false
    if (currentLocation.show_price === false && displayedCreditsKey !== 'lms_points') return null
    return (
      <div
        id={'no-credits-popover'}
        className="credits-container"
        onClick={handleClick}
      >
        <RenderCredits key={displayedCreditsKey} credits_key={displayedCreditsKey} main />
      </div>
    )
  } else {
    return (
      <div
        ref={creditsRef}
        className="credits-container"
        onClick={handleClick}
      >
        <RenderCredits key={displayedCreditsKey} credits_key={displayedCreditsKey} main />
        {CreditsForPopover.length > 0 && creditsRef.current && (
          <Popover
            trigger="hover click"
            boundariesElement="viewport"
            placement="bottom"
            target={creditsRef}
            isOpen={isOpen}
            toggle={toggle}
            modifiers={{
              offset: {
                enabled: true,
              },
              computeStyle: {
                enabled: true,
                y: 'right',
              },
            }}
            className="credits-popper-shadow"
          >
            <PopoverBody
              style={{
                backgroundColor: 'rgb(255,255,255,0.5)',
                color: 'white',
                border: 'none',
              }}
            >
              {CreditsForPopover}
            </PopoverBody>
          </Popover>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userCredits: state.userCredits,
    portal: state.portal,
    currentLocation: state.currentLocation,
    currentUser: state.currentUser.first_name ? state.currentUser : false,
  }
}

export default withRouter(connect(mapStateToProps)(CreditDisplayBox))
