import React, { useState, useEffect } from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import { connect } from 'react-redux'
import BundleProduct from './BundleProducts/BundleProduct.js'
import _ from 'lodash'
import { Container, Input } from 'reactstrap'
import { fetchBundle } from '../../../actions'
import BundleProofForm from '../BundleDigitalProofing/BundleProofForm'
import { setErrors } from '../productPageFunctions.js'

const BundleProductList = ({
  bundle,
  inStock,
  setInStock,
  productPage,
  stocks,
  setErrors,
  setShowProductModal,
  quantity,
  selectedArtworks,
}) => {
  const [stockStatus, setStockStatus] = useState({})

  // this watches the stock status of all child products and sets inStock accordingly
  useEffect(() => {
    // for unbreakable bundles, just check stocks for sku
    if (bundle.flags.bundle_type === 'Unbreakable') {
      if (stocks[bundle.sku] > 0 && inStock !== true) {
        setInStock(true)
        setErrors({ stock: false })
      }
      return
    }

    // if we have a stock status for each product and all are in stock,
    // and there is a stock error, change it.

    // const keysOfProductsInside = bundle.bundle.products.map(pr => (pr.bundle_group_product_id))
    const bundleProducts = bundle.bundle.products
    const bundleGroups = productPage.product.bundle.bundleGroups
    const numberOfBundleGroups = bundleGroups.length
    const numberOfSelectGroups = bundleGroups.filter(group => group.bundle_group_type === 'select_group').length
    const radioGroups = bundleGroups.filter(group => group.bundle_group_type === 'radio_group')

    // ** for buildable bundles, we can not simply compare length of the keys in stockStatus.
    //    we need to know which products the user has selected. **
    const allSelectedProductsHaveStock = bundleProducts.every(
      bundleProduct => stockStatus[bundleProduct.bundle_group_product_id] !== false
    )

    // if only one group, we can simply compare length of stockStatus' keys to # of products:
    if (
      Object.keys(stockStatus).length === bundleProducts.length &&
      Object.values(stockStatus).every(status => status === true) &&
      numberOfBundleGroups === 1
    ) {
      setInStock(true)
      setErrors({ stock: false })
    } else if (
      // if more than one group (buildable)
      numberOfBundleGroups > 1 &&
      allSelectedProductsHaveStock
    ) {
      setInStock(true)
      setErrors({ stock: false })
    } else {
      // only change if inStock isn't in sync with stockStatus
      setInStock(false)
      setErrors({ stock: true })
    }
  }, [stockStatus, stocks])

  useEffect(() => {
    const bundleProducts = bundle.bundle.products
    const bundleGroups = productPage.product.bundle.bundleGroups

    // if all radio groups have a selection, OK. set error false if not already

    const radioGroups = bundleGroups.filter(group => group.bundle_group_type === 'radio_group')
    const allRadioGroupsHaveASelection = radioGroups.every(group => {
      const thisBundleGroupId = group.bundle_group_id
      // search bundle products for an item belonging to this group
      const bundleGroupHasASelection = bundleProducts.find(product => product.bundle_group_id === thisBundleGroupId)
      return !!bundleGroupHasASelection
    })

    if (allRadioGroupsHaveASelection) {
      setErrors({ radioSelection: false })
    } else {
      // else set error
      setErrors({ radioSelection: true })
    }
  }, [bundle.bundle.products])

  return (
    <Container className="included-bundle">
      <h5>Included in this bundle:</h5>
      <div className="short-description">
        {bundle.bundle.products.map((product, i) => {
          return (
            <React.Fragment key={product.bundle_group_product_id}>
              <div>
                <BundleProduct
                  bundleProducts={bundle.bundle.products}
                  bundleProduct={product}
                  breakable={bundle.flags.bundle_type === 'Breakable'}
                  bundle_group_id={product.bundle_group_id}
                  setShowProductModal={setShowProductModal}
                  setStockStatus={setStockStatus}
                  stockStatus={stockStatus}
                  quantity={quantity}
                />

                {/* Artwork Selection for child bundle product */}
                {product.allow_artwork === true && this.state.artworks[product.product.id] ? (
                  <div>
                    <Input
                      type="select"
                      value={selectedArtworks[product.product.product_id]}
                      onChange={e => this.setSelectedArtworks(e.target.value, product.product.id)}
                      placeholder="-1"
                    >
                      <option disabled value={'-1'}>
                        Please select an artwork
                      </option>
                      {/* {artworkOptions.map( artwork => <option key={artwork.id} value={`${artwork.artwork_id}`}>{artwork.description}</option> )} */}
                    </Input>
                    <br />
                  </div>
                ) : null}

                {product.is_digital_proofing ? (
                  <BundleProofForm
                    products={bundle.bundle.products.filter(prod => {
                      return prod.is_digital_proofing === true
                    })}
                    bundle={bundle}
                  />
                ) : null}
              </div>
              <hr />
            </React.Fragment>
          )
        })}
      </div>
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    stocks: state.stocks,
    currentLocation: state.currentLocation,
    portal: state.portal,
    productPage: state.productPage,
    bundle: state.productPage.product,
  }
}

export default connect(mapStateToProps, { fetchBundle, setErrors })(BundleProductList)
