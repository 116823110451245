import { useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { Link } from 'react-router-dom'
import { Menu } from 'react-feather'
import { connect } from 'react-redux'

const SidebarMenu = ({toggleDrawer, portal, navPages, open}) => {
  // const [open, setOpen] = useState(false)

  return (
    <div onClick={toggleDrawer} style={{ position: 'relative' }}>
      <SwipeableDrawer
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className="sidebar-area mobile-sidebar-menu "
      >
        <div className="iron-app-logo-sidebar">
          <Link to="/">
            {portal.secondary_image.url ? (
              <img
                src={portal.secondary_image.url}
                alt="UserImage"
                className="img-responsive"
                key={portal.secondary_image.url}
                onClick={open ? toggleDrawer : () => {}}
              />
            ) : null}
          </Link>
        </div>

        <div className="vertical-menu py-40">
          <ul className="sidebar-links">{navPages}</ul>
        </div>
      </SwipeableDrawer>

      {/* <div
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '15px',
          // right: 'calc(100% - 20px)',
        }}
      >
        <Menu />
      </div> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    portal: state.portal,
  }
}

export default connect(mapStateToProps)(SidebarMenu)
