import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'

import reducers from './reducers'

import * as actionCreators from './components/Checkout/checkoutFunctions'

// ** init middleware
const middleware = [thunk]

// Options for Redux Dev Tools
const devOptions = {
  // enables stack trace for actions unless we are in production
  trace: true,
  actionCreators,
}
const prodOptions = {
  trace: false,
}

const options = process.env.NODE_ENV === 'production' ? prodOptions : devOptions

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        ...options,
      })
    : compose

// ** Create store **
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)))

export { store }
