import { validationRules } from './utils'

const validateField = (fieldName, value, isInternationalShipping, label) => {
  if (!validationRules[fieldName]) {
    return null
  }
  const rules = { ...validationRules[fieldName] }

  if (label && rules.required !== false) {
    rules.required = !label.toLowerCase().includes('optional');
  }

  let error = ''

  if (rules.required && !value) {
    error = `${label} is required`
  } else if (fieldName === 'full_name') {
    const names = value.split(' ')
    const firstName = names[0]
    const lastName = names.slice(1).join(' ')
    if (firstName.length > 50) {
      error = 'Please ensure your first name is within 50 characters'
    } else if (lastName.length > 50) {
      error = 'Please ensure your last name is within 50 characters'
    }
  } else if (fieldName.includes('address_line')) {
    return;
  }
  else if (rules.maxLength && value.length > rules.maxLength) {
    error = `Please ensure your ${label?.replace("(Optional)", "").trim()} is within ${rules.maxLength} characters`
  } else if (rules.validate && !rules.validate(value, isInternationalShipping)) {
    error = 'Invalid phone number'
  }

  return error
}

const validateForm = (formData, isInternationalShipping, labels, formErrors) => {
  let errors = {};

  Object.keys(formData).forEach(fieldName => {
    const value = formData[fieldName];
    const error = validateField(fieldName, value, isInternationalShipping, labels[fieldName]);

    if (error) {
      errors[fieldName] =  error;
    } else if(formErrors[fieldName] ) {
      errors[fieldName] = formErrors[fieldName];   
    }

  });
  const isValid = Object.keys(errors).length === 0;
  return {
    errors,
    isValid
  };
}

const  createLabels = (selectedCountry)  => {
  return {
    full_name: 'Full Name',
    address_phone: 'Phone number',
    address_line_1: 'Address Line 1',
    zip_code: selectedCountry.labels.postcode,
    city: selectedCountry.labels.city,
    region: selectedCountry.labels.region,
  };
}

export { validateForm, validateField, createLabels }
