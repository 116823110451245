import { FETCH_TAX } from '../actions/types'

const initialState = {
  tax_status: '',
  total_tax_cents: 0,
  tax_info: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAX:
      return action.payload
    default:
      return state
  }
}
