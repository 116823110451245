import { VERIFY_PASSWORD_TOKEN_SUCCESS, VERIFY_PASSWORD_TOKEN_FAILURE } from '../actions/types'

const initialState = {
  tokenVerification: null,
  error: null,
}

const passwordTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        tokenVerification: action.payload,
        error: null,
      }

    case VERIFY_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        tokenVerification: null,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export default passwordTokenReducer
