import { useState } from 'react'
import { Input, Label, Col, InputGroup, Button, Form, FormGroup, Alert } from 'reactstrap'
import { Plus } from 'react-feather'
import { formatPhoneNumber, validateAddressLines } from '../../util/utils'
import { connect } from 'react-redux'
import StateProvinceSelect from '../addresses/StateProvinceSelect'
import CountrySelect from '../addresses/CountrySelect'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import supportedCountries from '../../configs/supportedCountries'
import { PropTypes } from 'prop-types'
import { validateForm, validateField, createLabels } from '../../util/forms'
import { createDeliveryAddressNew, validateAddress } from '../../actions'
import { TextInputField } from '../addresses/EditAddressForm'

const NewAddressForm = ({
  setNewAddressMode = () => {},
  newAddressMode = false,
  portal,
  afterCreateAddress = () => {},
  validateAddress,
  createDeliveryAddressNew,
}) => {
  const [showCompanyName, setShowCompanyName] = useState(false)
  const [showAddress2, setAddress2] = useState(false)
  const [addressData, setAddressData] = useState({
    full_name: '',
    title: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    address_phone: '',
    country: 'United States',
    company_name: '',
    country_code: 'US',
    default_shipping: false,
  })
  const [formErrors, setFormErrors] = useState({
    full_name: null,
    address_line_1: null,
    address_line_2: null,
    company_name: null,
    city: null,
    state: null,
    country: null,
    zip_code: null,
    address_phone: null,
  })

  const toggleCompanyName = () => setShowCompanyName(!showCompanyName)
  const toggleAddress2 = () => setAddress2(!showAddress2)
  const handleMakeDefault = () => {
    setAddressData({ ...addressData, default_shipping: !addressData['default_shipping'] })
  }

  const PhoneInputField = (international = false) => {
    const countries = supportedCountries(portal.supported_countries)
    let phoneInputProps = {
      name: 'address_phone',
      value: addressData.address_phone || '',
      onChange: handleChangePhone,
    }

    if (portal.international_shipping) {
      phoneInputProps = {
        ...phoneInputProps,
        international: true,
        countryCallingCodeEditable: false,
        countries: portal.international_shipping ? countries.map(country => country.country_code) : ['US'],
        withCountryCallingCode: true,
        defaultCountry: addressData.country_code ? addressData.country_code : 'US',
      }

      return (
        <FormGroup row>
          <Label sm={4} className="address-format-label">{`Phone`}</Label>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <PhoneInput {...phoneInputProps}/>
            {formErrors.address_phone && <span className="text-danger small">{formErrors.address_phone}</span>}
          </Col>
        </FormGroup>
      )
    } else {
      phoneInputProps = {
        ...phoneInputProps,
        onChange: e => handleChangePhone(formatPhoneNumber(e.target.value, 'spaces_parentheses')),
      }
      return (
        <FormGroup row>
          <Label sm={4} className="address-format-label">{`Phone`}</Label>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <Input type="tel" {...phoneInputProps} />
            {formErrors.address_phone && <span className="text-danger small">{formErrors.address_phone}</span>}
          </Col>
        </FormGroup>
      )
    }
  }

  const handleChange = event => {
    const label = event.target.labels?.[0]?.textContent.trim() || event.target.name;
    const { name, value } = event.target
    if (name === 'address_line_1' || name === 'address_line_2') {
      setAddressData(prevAddressData => ({
        ...prevAddressData,
        [name]: value,
      }))

      const newErrors = validateAddressLines(
        name,
        name === 'address_line_1' ? value : addressData.address_line_1,
        name === 'address_line_2' ? value : addressData.address_line_2,
        formErrors
      )
      
        setFormErrors(newErrors);
        return;
    }

    const error = validateField(name, value, portal.international_shipping, label)

    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }))

    setAddressData(prevAddressData => ({
      ...prevAddressData,
      [name]: value,
    }))
  }

  const handleChangeCountry = countryObject => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      city: '',
      zip_code: '',
    }))
    setAddressData({
      ...addressData,
      country: countryObject.name,
      country_code: countryObject.country_code,
    })
  }
  const handleChangePhone = value => {
    setFormErrors(prevErrors => ({
      ...prevErrors,
      address_phone: '',
    }))
    setAddressData({ ...addressData, address_phone: value })
    return value
  }

  const handleSaveAddress = address => {
    address.is_shipping = true
    createDeliveryAddressNew({
      addressData: address,
      callback: afterCreateAddress,
    })
  }

  const handleCreateAddress = () => {
    const labels = createLabels(selectedCountry)
    const isInternationalShipping = portal.international_shipping
    const { errors, isValid } = validateForm(addressData, isInternationalShipping, labels, formErrors)    

    if (!isValid) {
      setFormErrors(errors)
      return
    }

    validateAddress({
      addressData,
      onSaveAddress: handleSaveAddress,
      callbackFunction: afterCreateAddress,
      isInternationalShipping: portal.international_shipping,
    })
  }

  const countries = supportedCountries(portal.supported_countries)
  const selectedCountry = countries.find(c => c.country_code === addressData.country_code) || {
    labels: {
      postcode: 'Postal Code',
      region: 'State / Region',
      city: 'Town / City',
    },
  }

  return (
    <Form style={{ paddingRight: '15px' }}>
      <br />

      <TextInputField
        fieldName="full_name"
        value={addressData.full_name}
        handleChange={handleChange}
        error={formErrors['full_name'] ?? null}
      />

      {PhoneInputField()}

      {/* Country Select */}
      {!portal.international_shipping ? null : (
        <FormGroup row>
          <Label sm={4} className="address-format-label" for="country">
            Country
          </Label>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <CountrySelect handleChange={handleChangeCountry} countryCode={addressData.country_code} />
          </Col>
        </FormGroup>
      )}

      {/* TODO: this will become a portal setting */}
      {/* {showCompanyName ? ( */}
      <TextInputField
        fieldName="company_name"
        value={addressData.company_name}
        handleChange={handleChange}
        error={formErrors['company_name'] ?? null}
      />
      {/* ) : (
        <FormGroup row>
          <Col sm={4}></Col>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <p onClick={toggleCompanyName} style={{ cursor: 'pointer', marginBottom: 0 }}>
              <Plus size={16} />
              &nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Add Company Name (optional)</span>
            </p>
          </Col>
        </FormGroup>
      )} */}

      <TextInputField
        fieldName="address_line_1"
        value={addressData.address_line_1}
        handleChange={handleChange}
        error={formErrors['address_line_1'] ?? null}
      />

      {showAddress2 ? null : (
        <FormGroup row>
          <Col sm={4}></Col>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <p onClick={toggleAddress2} style={{ cursor: 'pointer', marginBottom: 0 }}>
              <Plus size={16} />
              &nbsp;&nbsp;<span style={{ textDecoration: 'underline' }}>Add Address Line 2</span>
            </p>
          </Col>
        </FormGroup>
      )}

      {showAddress2 ? (
        <TextInputField
          fieldName="address_line_2"
          value={addressData.address_line_2}
          handleChange={handleChange}
          error={formErrors['address_line_2'] ?? null}
          disable={formErrors['address_line_1'] ? true : false}
        />
      ) : null}

      <TextInputField
        fieldName="zip_code"
        label={selectedCountry.labels.postcode}
        value={addressData.zip_code}
        handleChange={handleChange}
        error={formErrors['zip_code'] ?? null}
      />
      <TextInputField
        fieldName="city"
        label={selectedCountry.labels.city}
        value={addressData.city}
        handleChange={handleChange}
        error={formErrors['city'] ?? null}
      />

      {/* State/Province Select */}
      {selectedCountry.labels.region === 'N/A' ? null : (
        <FormGroup row>
          <Label sm={4} className="address-format-label" for="state">
            {selectedCountry.labels.region}
          </Label>
          <Col sm={8} style={{ alignSelf: 'center' }}>
            <StateProvinceSelect
              countryCode={addressData.country_code}
              handleChangeState={stateProvince => setAddressData({ ...addressData, state: stateProvince.value })}
              stateValue={addressData.state}
            />
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Col sm={4}></Col>
        <Col sm={8}>
          <InputGroup style={{ float: 'right', width: 'auto' }}>
            <Label check className="address-format-label">
              <Input type="checkbox" onChange={handleMakeDefault} value={addressData['default_shipping']} />
              Set as Default Shipping Address
            </Label>
          </InputGroup>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button className="mf-primary-btn" onClick={handleCreateAddress}>
            Save Address
          </Button>
          {newAddressMode ? (
            <>
              &nbsp;&nbsp;&nbsp;
              <Button className="mf-outline-btn" onClick={() => setNewAddressMode(false)}>
                Cancel
              </Button>
            </>
          ) : null}
        </Col>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = state => {
  return {
    userHasNoAddresses: state.checkout.userHasNoAddresses,
    portal: state.portal,
    currentUser: state.currentUser,
  }
}

// dafult value for addresstype is delivery
PropTypes.NewAddressForm = {
  addressType: PropTypes.string,
  setNewAddressMode: PropTypes.func,
  newAddressMode: PropTypes.bool,
}

export default connect(mapStateToProps, { validateAddress, createDeliveryAddressNew })(NewAddressForm)
