import React, { Component, Fragment } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { Divider, Card, CardContent, IconButton, CardMedia } from '@material-ui/core'
import { Button } from 'reactstrap'
import placeholder from './../Shop/placeholder-image.jpg'

import dollar_price from '../general/DollarPrice'

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 800,
  autoplaySpeed: 6000,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

class BannerSliderFour extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedCategory: null,
    }
  }

  componentDidMount() {
    if (!this.props.hide_category_selector && this.props.categories[0]) {
      this.setState({
        selectedCategory: this.props.categories[0].id,
      })
    }
  }

  dollar_price(price_in_cents, dec) {
    if (typeof price_in_cents !== 'number' || typeof dec !== 'number') return false
    var num_sign = price_in_cents >= 0 ? 1 : -1
    return (Math.round((price_in_cents / 100) * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)).toFixed(dec)
  }

  coopPrice(price) {
    let coopPercent = this.props.coopPercent
    let coopDeduction = this.dollar_price(price * coopPercent, 2)
    let prodPrice = this.dollar_price(price, 2)
    return dollar_price((prodPrice - coopDeduction).toFixed(2))
  }

  loadCategoryProducts = category => {
    if (this.state.selectedCategory !== category) {
      this.setState({
        selectedCategory: category,
      })
    } else {
      this.setState({
        selectedCategory: null,
      })
    }
  }

  filteredProducts() {
    let filtered_products = []
    let found = false

    if (this.state.selectedCategory) {
      this.props.sliderData.map(productItem => {
        found = false
        productItem.categories.map(category => {
          if (!found && category.id == this.state.selectedCategory) {
            filtered_products.push(productItem)
            found = true
          }
        })
      })
    } else {
      filtered_products = this.props.sliderData
    }

    return filtered_products
  }

  render() {
    let filtered_products = this.filteredProducts()
    if (filtered_products.length == 1) {
      settings.slidesToShow = 1
    } else if (filtered_products.length == 2) {
      settings.slidesToShow = 2
    } else if (filtered_products.length == 3) {
      settings.slidesToShow = 3
    } else {
      settings.slidesToShow = 4
    }

    let currDate = new Date()
    let currTimestamp = currDate.getTime()

    return (
      <div className="product-slider iron-banner-slider-v2 iron-post-wrap slider-style">
        {this.props.title != '' && <h2 className="product-slider-title">{this.props.title}</h2>}
        {this.props.title != '' && <br />}
        {!this.props.hide_category_selector && (
          <div className="product-slider-categories">
            {this.props.categories.map((cat, index) => {
              if (cat.enabled === true) {
                return (
                  <Button
                    key={index}
                    className={
                      this.state.selectedCategory == cat.id
                        ? 'product-slider-selected-category'
                        : 'product-slider-category'
                    }
                    onClick={() => this.loadCategoryProducts(cat.id)}
                  >
                    {cat.name}
                  </Button>
                )
              }
            })}
          </div>
        )}
        {!this.props.hide_category_selector && <br />}
        <Slider {...settings}>
          {filtered_products
            .sort((a, b) => a.order - b.order)
            .map((slidedata, index) => {
              return (
                <Card key={index} className="iron-product-item post-rounded iron-shadow">
                  <div className="iron-overlay-wrap overflow-hidden d-flex justify-content-center align-items-center">
                    <Link to={'/product/' + slidedata.product.id + '/' + this.props.portal.id} className="d-block">
                      <CardMedia
                        style={{ height: '200px' }}
                        component="img"
                        image={
                          slidedata.image && slidedata.image.file != null ? slidedata.image.file.small.url : placeholder
                        }
                      />
                    </Link>
                  </div>
                  <Divider />
                  <CardContent className="iron-product-content p-20 pt-30 border">
                    <h5 className="text-truncate">
                      <Link to={'/product/' + slidedata.product.id + '/' + this.props.portal.id} className="d-block">
                        {slidedata.product.name}
                      </Link>
                    </h5>

                    <div>
                      {slidedata.product.sale_price > 0 &&
                      new Date(slidedata.product.sale_price_from_date_and_time).getTime() < currTimestamp &&
                      new Date(slidedata.product.sale_price_to_date_and_time).getTime() > currTimestamp ? (
                        <div className="price-wrap">
                          <span>
                            <del className="mr-20"> {dollar_price(slidedata.product.price, 2)}</del>
                          </span>
                          <span> {dollar_price(slidedata.product.sale_price, 2)}</span>
                        </div>
                      ) : (
                        <div className="price-wrap">
                          <span> {dollar_price(slidedata.product.price, 2)}</span>
                        </div>
                      )}

                      {slidedata.product.coop_enabled && this.props.portal.coop_enabled && (
                        <div className="price-wrap rAlign">
                          <span>
                            {localStorage.getItem('coop_def')} {this.coopPrice(slidedata.product.price)}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="iron-btn-grp">
                      <Fragment>
                        <Link to={'/product/' + slidedata.product.id + '/' + this.props.portal.id} className="d-block">
                          <IconButton className="btn-wrap">
                            <i className="material-icons">pageview</i>
                          </IconButton>
                        </Link>
                      </Fragment>
                    </div>
                  </CardContent>
                </Card>
              )
            })}
        </Slider>
      </div>
    )
  }
}

export default BannerSliderFour
