import { store } from '../store'

// For a list of all possible props view the docs:
// https://djorg83.github.io/react-bootstrap-sweetalert/

// inital props will be merged with each new alert, acting as the "default"

// use this as the default function for confirm button; it simply clears the alert
async function onCancel() {
  store.dispatch({ type: 'CLEAR_SWEETALERT' })
}

// use this to append clear alert to the confirm function
function onConfirm(incomingCallback) {
  //
  if (incomingCallback) {
    incomingCallback()
  }
  onCancel()
}

const sweetalertInitialProps = {
  show: false, // main display toggle
  // showAlert: true,  // main display toggle
  onCancel: onCancel,
  onConfirm: onCancel,
  showCancel: true, // show or hide the cancel button
  alertType: 'success',
  alertMessage: '',
  // closeOnClickOutside: true,
  title: '',
  confirmBtnText: 'Confirm',
  closeOnClickOutside: true,
}
const initialState = sweetalertInitialProps

export default (state = initialState, action) => {
  let incomingProps = action.payload ? action.payload : {}
  switch (action.type) {
    case 'SHOW_SWEETALERT':
      // always merge incoming props with initial state,
      // we do not want any old alert data carrying over

      // replace the onConfirm with our own, so we can add our clear function
      // if (incomingProps.onConfirm) {
      //   //
      //   incomingProps.onConfirm = onConfirm(incomingProps.onConfirm)
      // }

      return Object.assign({}, initialState, { show: true }, incomingProps, {
        onConfirm: () => onConfirm(incomingProps.onConfirm),
      })

    case 'CLEAR_SWEETALERT':
      return initialState

    default:
      return state
  }
}
