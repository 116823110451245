import { SET_NET_TERM, SET_NET_TERM_PO_NUMBER } from '../actions/types';

const initialState = {
  netTermDef: null,
  netTermPoNumber: null
};

const netTermReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NET_TERM:
      return {
        ...state,
        netTermDef: action.payload
      };
    case SET_NET_TERM_PO_NUMBER:
      return {
        ...state,
        netTermPoNumber: action.payload
      };
    default:
      return state;
  }
};

export default netTermReducer;



