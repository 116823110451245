import {store} from '../../store.js'
import CurrencyIcon from '../global/currency/CurrencyIcon';

const  dollar_price = (priceInCents, dec = 2, orderConversionRatio = null, noCurrencyChar = false) => {
  // if location has show_price = false, return empty string
  const reduxState = store.getState()
  if (reduxState.currentLocation.show_price === false) {
    return ''
  }

  const usesLmsPoints = reduxState.portal.use_lms_points && !!(reduxState.currentLocation.conversion_ratio || orderConversionRatio)

  priceInCents = parseInt(priceInCents, 10)

  // if cost is 0, just return 0 with currency icon
  if (!priceInCents) {
    return formatPriceCurrency(priceInCents, usesLmsPoints)
  }

  const numSign = priceInCents >= 0 ? 1 : -1;

  let result = (Math.round(((priceInCents/100) * Math.pow(10,dec)) + (numSign*0.0001))/Math.pow(10,dec)).toFixed(dec)
  let formatter = new Intl.NumberFormat('en-US', {
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  let formattedPrice = formatter.format(result)

  // if showing order. use conversion ratio placed with order, else location conversion ratio for store
  const conversionRatio = usesLmsPoints ? (orderConversionRatio ? parseFloat(orderConversionRatio) : parseFloat(reduxState.currentLocation.conversion_ratio)) : null

  if (usesLmsPoints && !noCurrencyChar) {
    formattedPrice = priceInCents * numSign
  }

  formattedPrice = formatPriceCurrency(formattedPrice, usesLmsPoints, conversionRatio, noCurrencyChar)

  return formattedPrice
}

function formatPriceCurrency(price, usesLmsPoints, conversionRatio, noCurrencyChar = false) {
  // return price with correct currencyIcon
  if (noCurrencyChar) {
    price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return price
  } else if (usesLmsPoints) {
    price = !!price ? Math.round(price * conversionRatio).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
    return <>{price} <CurrencyIcon usesLmsPoints={usesLmsPoints} price={price}/></>
  } else {
    price = !!price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'
    return <><CurrencyIcon />{price}</>
  }
}

export default dollar_price