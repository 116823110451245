import React, { Component } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import BundleProofProduct from './BundleProofProduct'
import moment from 'moment-timezone';
import { Row, Col, Collapse, Card, CardHeader, CardBody, Button } from 'reactstrap'
import {connect} from 'react-redux'
import _ from 'lodash'
import { ChevronDown } from 'react-feather';

class BundleProofForm extends Component {
  constructor(props){
    super(props)

    // expand accordions by default
    let accordion = _.mapKeys(props.products, 'bundle_group_product_id')
    accordion = _.mapValues(accordion, (el) => !!el)

    this.state = {
      products: this.props.products,
      accordion: accordion
    }

    this.approveProof = this.approveProof.bind(this)
    this.cancelProof = this.cancelProof.bind(this)
  }

  approveProof(productIndex, formData, artwork_id, coupon_id){
    let products = [...this.state.products]
    products[productIndex].proofComplete = true;
    products[productIndex].formData = formData
    this.setState({ products: products })
    this.props.proof(products[productIndex], formData, artwork_id, coupon_id)
  }

  cancelProof(productIndex){
    let products = [...this.state.products]
    products[productIndex].proofComplete = false;
    this.setState({ products: products })
  }

  humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  toggleCollapse = title => {
    this.setState({ accordion: {
      ...this.state.accordion,
      [title]: !this.state.accordion[title]
    }})
  }

  collapseDpForm = bundleProofProductId => {
    this.setState({ accordion: {
      ...this.state.accordion,
      [bundleProofProductId]: false
    }})
  }

  render(){
    let proofComplete = true
    this.state.products.map((product) => {
      if (product.proofComplete === false){
        proofComplete = false
      }
    })

    return (
      <Row>
        <Col className="included-bundle container m-0">
          <p>Please complete proofs for the digital products within your bundle:</p>

          {this.state.products.map((product, index) =>{

            const isThisProofComplete = () => {
              return !!this.props.digitalProofingSelection[product.bundle_group_product_id]
            }
            
            return(
              <Card className='bundleconfig m-0 p-0' key={index} id={`bundle-product-dp-accordion-${product.bundle_group_product_id}`}>
                <CardHeader
                  onClick={() => this.toggleCollapse(product.bundle_group_product_id)}
                  className={isThisProofComplete() ?
                    'bundleconfig-header m-0 p-0'
                    :
                    'bundleconfig-header m-0 p-0'
                  }
                  >
                    <h5 className="m-0">{product.product_name}</h5>
                    <ChevronDown style={{color:'#212529'}}/>  
                  </CardHeader>
                  <Collapse isOpen={this.state.accordion[product.bundle_group_product_id]}>
                  <CardBody className='bundleconfig-body m-0 p-0'>
                    { product.proofComplete ?
                    <div>
                      <p><strong>Proof Complete:</strong></p>
                      {Object.keys(product.formData).map((key) =>{
                        if (key !== "coupon_expiration_date") {
                          let attribute = product.formData[key]
                          if (typeof attribute === "string" || typeof attribute === "number") {
                            return (
                              <p className="number-format-label w-100"><strong>{`${this.humanize(key)}:`}</strong> {`${attribute}`}</p>
                            )
                          }else{
                            if(key == 'approved_date'){
                              return ( <p className="number-format-label w-100"><strong>{`${this.humanize(key)}:`}</strong> {`${ moment(attribute).format('MMMM Do YYYY, h:mm a')}`}</p>)
                            }else{
                              return(
                                <p className="number-format-label w-100"><strong>{`${this.humanize(key)}:`}</strong> {`${attribute.title}, ${attribute.address_line_1}`}</p>
                              )

                            }
                            
                          }
                        }
                      })}
                      <Button 
                        className="button btn-active btn-sm mr-15 mt-4 mb-20 mb-sm-0"
                        onClick={() => this.cancelProof(index)}
                      >Cancel Proof</Button>
                    </div>
                    : 
                    <BundleProofProduct
                      product={product}
                      product_id={product.product_id}
                      portal_id={this.props.portal_id}

                      collapseDpForm={this.collapseDpForm}
                      
                      // index={index}
                      // complete={this.approveProof}
                      // history={this.props.history}
                    />
                  }
                </CardBody>
                </Collapse>
              </Card>
            )
          })}
        </Col>

      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    digitalProofingSelection: state.productPage.digitalProofingSelection
  }
}

export default connect(mapStateToProps)(BundleProofForm)