import { useEffect, useState } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '../../productPageFunctions'

const BundleProductArtworkSelection = ({ setInStock, bundleProduct, artworkSelection, updateArtworkSelection }) => {
  const [selectedArtworkId, setSelectedArtworkId] = useState('-1')
  const [selectedArtwork, setSelectedArtwork] = useState({})
  const [artworkUrl, setArtworkUrl] = useState('')

  // when selection changes, set selected artwork
  useEffect(() => {
    const artworks = bundleProduct.artwork.nested_data
    if (artworks) {
      setSelectedArtwork(artworks.find(art => art.artwork_id === parseInt(selectedArtworkId, 10)))
      setInStock(true)
    } else if (selectedArtworkId === '-1') {
      setInStock(false)
    }
  }, [selectedArtworkId])

  // if valid artwork is selected, send to redux
  useEffect(() => {
    if (selectedArtwork && selectedArtwork.artwork_id) {
      updateArtworkSelection({
        product_id: bundleProduct.product_id,
        bundle_group_product_id: bundleProduct.bundle_group_product_id,
        artwork: selectedArtwork,
      })

      // additionally, set the art url
      setArtworkUrl(selectedArtwork.image2)
    }
  }, [selectedArtwork])

  const handleSelect = e => {
    const artworkId = e.target.value
    setSelectedArtworkId(artworkId)
  }

  const artwork = bundleProduct.artwork
  const artworks = artwork.nested_data
  return (
    <>
      <Row>
        <Col xs="12">
          <Input
            type="select"
            value={
              artworkSelection[bundleProduct.bundle_group_product_id]
                ? artworkSelection[bundleProduct.bundle_group_product_id].selected_options[0].artwork_id
                : '-1'
            }
            onChange={handleSelect}
          >
            <option disabled value="-1">
              Please Select
            </option>
            {artworks &&
              artworks.map((art, i) => {
                return (
                  <option key={i} value={art.artwork_id}>
                    {art.name}
                  </option>
                )
              })}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col>{artworkUrl ? <img src={artworkUrl} alt="selected artwork image" /> : null}</Col>
      </Row>
    </>
  )
}

const mapStateToProps = state => {
  return {
    bundle_product_config_selection: state.bundle_selections,
    artworkSelection: state.productPage.artworkSelection,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleProductArtworkSelection)
