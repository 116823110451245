import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { deleteCartItem } from '../../actions'
import { Col, Row, Container } from 'reactstrap'
import CartItemsList from './CartItemsList'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import OrderSummary from '../Checkout/CheckoutOrderSummary'
import { dispatchSweetAlert, clearSweetAlert } from '../../actions'
import { productHasError, resolveBundleProductErrorMessage, cartItemsRequireCheckForApproval, returnCartItemsRequireApprovalError } from './cartFunctions'
import { setCheckoutErrorMessage } from '../Checkout/checkoutFunctions'
import { updateCartItems } from '../../actions/index'

const errorMessages = {
  maxQtyReached: 'You have exceeded the maximum quantity for a product.',
}

const Cart = (props) => {
  useEffect(() => {
    // apply credits according to selections
    const cartItemsWithAppliedCredits = applyCoopCreditsToCartItems(props.cartItems)
    props.updateCartItems(cartItemsWithAppliedCredits)
  }, [])

  useEffect(() => {
    const firstError = Object.entries(props.errors).find(([key, val]) => val === true)
    if (firstError) {
      const errorMessage = errorMessages[firstError[0]]
      props.setCheckoutErrorMessage(errorMessage)
    } else {
      props.setCheckoutErrorMessage('')
    }
  }, [props.errors])

  // check cart items for errors if on main cart page
  useEffect(() => {
    const productWithError = props.cartItems.find((ci) => productHasError(ci))
    if (productWithError) {
      let errorMessage
      if (!productWithError.error) {
        // error is on child product
        errorMessage = resolveBundleProductErrorMessage(productWithError.bundle.products)
      } else {
        errorMessage = productWithError.error.message
      }
      // show error to user, and allow them to either:
      // - delete item
      // - delete item and go to product page
      const propsForSweetAlert = {
        confirmBtnText: 'Remove & view product',
        onConfirm: () => handleConfirmError(productWithError),
        alertMessage: errorMessage,
        cancelBtnText: 'Remove',
        onCancel: () => handleCancelError(productWithError),
        confirmBtnCssClass: 'mf-primary-btn',
        cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
        closeOnClickOutside: false,
      }
      props.dispatchSweetAlert(propsForSweetAlert)
    }
  }, [props.cartItems])

  // Check if cart items contain both requires approval and does not require approval
  useEffect(() => {
    if (props.cartItems && props.cartItems.length > 0 && cartItemsRequireCheckForApproval(null, props.cartItems, props.currentLocation)) {
      props.returnCartItemsRequireApprovalError(props.cartItems, null)
    }
  }, [props.cartItems])

  const handleConfirmError = (cartItemWithError) => {
    props.deleteCartItem(cartItemWithError.cart_item_id)
    props.history.push(`/product/${cartItemWithError.product_id}/${props.portal.id}/${props.currentLocation.id}`)
  }
  const handleCancelError = (cartItemWithError) => {
    props.deleteCartItem(cartItemWithError.cart_item_id)
    props.clearSweetAlert()
  }

  const titleStyle = () => {
    if (props.portal && props.portal.product_page_background_image && props.portal.product_page_background_image.url) {
      return {
        backgroundImage: props.portal.product_page_background_image.url,
      }
    } else {
      return {}
    }
  }

  const clearAppliedCredits = (cartItems) => {
    return cartItems.map((cartItem) => {
      return {
        ...cartItem,
        priceData: {
          ...cartItem.priceData,
          appliedCredits: {},
        },
      }
    })
  }

  // accepts cart items, returns cart items with updated priceData containing applied credits
  const applyCoopCreditsToCartItems = (cartItems) => {
    // first, clear all discounts to start from a clean slate
    cartItems = clearAppliedCredits(cartItems)
    // get the desired credits to be used
    let coopCreditsToBeApplied = props.userCredits.coop_credit.remaining

    let result = cartItems.map((cartItem) => {
      const newPriceData = {
        ...cartItem.priceData,
        appliedCredits: {},
      }

      // if there is a discount applied to subtotal, caluclate amount to apply to each product
      // (this should always be 0 if on cart page)
      const perProductDiscount = 0

      // * Co-Op *
      // If user wants to apply coop credits and priceData has coop_deduction_cents, (is eligible)
      // apply as many coopcredits as possible
      if (coopCreditsToBeApplied && newPriceData.coop_deduction_cents) {
        // get the total amount possible that can be applied
        let applicableCoopDeduction = newPriceData.coop_deduction_cents

        // If a discount is present, use the adjusted price and coop deduction
        if (perProductDiscount) {
          applicableCoopDeduction = newPriceData.adjusted_coop_deduction_cents
        }

        applicableCoopDeduction = applicableCoopDeduction * cartItem.quantity
        // if we have enough for the full deduction, apply it
        if (coopCreditsToBeApplied >= applicableCoopDeduction) {
          coopCreditsToBeApplied -= applicableCoopDeduction
          newPriceData.appliedCredits.coop = applicableCoopDeduction
        } else {
          // else apply our remaining credit
          newPriceData.appliedCredits.coop = coopCreditsToBeApplied
          coopCreditsToBeApplied -= coopCreditsToBeApplied
        }
      }

      return {
        ...cartItem,
        priceData: newPriceData,
      }
    })
    return result
  }

  // If Cart is empty, show empty cart page:
  if (props.cartItems.length === 0 && props.cart_loading === false) {
    return (
      <div className="section-pad text-center">
        <div className="mb-30">
          <img src={require('../../assets/images/empty-cart.png')} alt="shop-cart" />
        </div>
        <h4>Your Shopping Cart is empty.</h4>
        <Link to="/shop" className="text-capitalize">
          Go Shopping
        </Link>
      </div>
    )
  } else {
    return (
      <>
        <div></div>
        <Container className="section-pad">
          <Row>
            <Col xs="12" md="7" lg={8} className="">
              <CartItemsList isCheckout={false} />
            </Col>

            <Col xs={12} md={5} lg={4}>
              <OrderSummary isCheckout={false} />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentLocation: state.currentLocation,
    cartItems: state.cartItems,
    portal: state.portal,
    errors: state.checkout.errors,
    cart_loading: state.checkout.cart_loading,
    userCredits: state.userCredits,
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    deleteCartItem,
    dispatchSweetAlert,
    clearSweetAlert,
    setCheckoutErrorMessage,
    updateCartItems,
    returnCartItemsRequireApprovalError,
  })
)(Cart)
