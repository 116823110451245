import React, { useState, useEffect } from "react";
import { state_list } from '../../general/stateList'
import { Input, Label, Row, Col, Button } from "reactstrap";
import _ from 'lodash'
import { formatPhoneNumber, titleizeField } from '../../../util/utils';
import { TextInputField, AddressInputDropdown } from "./form_fields";

const BusinessCardForm = props => {
  const [allAddresses, setAllAddresses] = useState({
    front: '-1', 1: '-1', 2: '-1', 3: '-1', 4: '-1', 5: '-1', 6: '-1',
  })
  const [phoneNumbers, setPhoneNumbers] = useState({
    front: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '',
  })
  const [formValues, setFormValues] = useState({
    dealer_name:        "",
    employee_name:      "",
    employee_position:  "",
    employee_email:     "",
    address_1_state:    '-1',
    address_1_line_1:   '',
    address_1_city:     '',
    address_1_zip_code: '',
  })
  const [errors, setErrors] = useState({  })
  const [errorMessage, setErrorMessage] = useState('Please complete all required fields')
  const [isFormValid, setIsFormValid] = useState(false)
  const [numberOfAddresses, setNumberOfAddresses] = useState(0)
  const [formConfiguration, setFormConfiguration] = useState({
    renderForm:                null, // funciton to render form fields
    renderAdditionalAddresses: null, // boolean to render additional address
    manualAddressInput:        null, // boolean to render individual address fields
    fields:                    []    // array of field names inside form to validate
  })

  // initialize keys for new forms and errors
  useEffect(() => {
    const initialErrorObject = _.chain(formConfiguration.fields).keyBy(field => field).mapValues(field => true).value()
    setErrors({ ...initialErrorObject, initial: true })
    const initialFormValues = _.chain(formConfiguration.fields).keyBy(field => field).mapValues(field => '').value()
    setFormValues(initialFormValues)
  }, [formConfiguration])

  // when form values change, validate fields
  useEffect(() => {
    // check each field
    const fieldNames = formConfiguration.fields
    const areAllFieldsValid = validateFields(fieldNames)

    if ( areAllFieldsValid === true) {
      setIsFormValid(true)
      setErrorMessage('')
    } else {
      setIsFormValid(false)
      setErrorMessage('Please complete all required fields')
    }

  }, [formValues])

  const validateFields = fieldNames => {

    // for each field name, set error based on validity
    return fieldNames.every( fieldName => {
      return fieldIsValid(fieldName)
    })
  }

  const fieldIsValid = fieldName => {
    return !!formValues[fieldName] && formValues[fieldName] !== '-1'
  }

  // when artwork changes, either reset form or set new configuration
  useEffect( () => {
    configureForm()
  }, [props.selectedArtwork])

  const FormTextInput = (label, name, required=true) => {
    return <TextInputField
              label={label}
              name={name}
              required={required}
              onChange={handleChange}
              value={formValues[name]}
            />
  }

  // FORMS
  const FillInTheBlankBaseForm = () => {
    return(<>
        {FormTextInput('Approved Dealer Name', 'dealer_name', true)}
        {AddressInputSelect('front', 'Front Address', true)}
        {PhoneInputField('front')}
      </>)
  }
  const FbForm = () => {
    return(<>
        {FillInTheBlankBaseForm()}
        {RenderExtraAddresses()}
      </>)
  }
  // "TW Wireless - Name"
  const FbFormTW = () => {
    return (<>
        {FormTextInput("Employee First and Last name", 'employee_name', true )}
        {FormTextInput("Employee Position"           , 'employee_position', true )}
        {FormTextInput("Dealer Business Name"        , 'dealer_name', true )}
        {AddressInputSelect('front', 'Front Address')}
        {PhoneInputField('front')}
      </>)
  }
  // "TW Wireless - Blank"
  const FbFormTWBlank = () => {
    return (<>
        {FormTextInput("Dealer Business Name", 'dealer_name')}
        {AddressInputSelect('front', 'Front Address')}
        {PhoneInputField('front')}
      </>)
  }
  const WingsnobBaseForm = () => {
    return (<>
        {FormTextInput("Employee First and Last name", 'employee_name')}
        {FormTextInput('Position/Title', 'employee_position')}
        {PhoneInputField(1)}
        {PhoneInputField(2)}
        {FormTextInput('E-Mail', 'employee_email')}
      </>)
  }
  const WingsnobFormWithAddress = () => { // WS form WITH address field
    return (<>
        {WingsnobBaseForm()}
        {FormTextInput('Street Address', 'address_1_line_1')}
        {FormTextInput('City', 'address_1_city')}
        {StateSelection('address_1_state', 'State')}
        {FormTextInput('Zip Code', 'address_1_zip_code')}
      </>)
  }

  const PetEvFormPhoneAddress = () => {
    return (<>
        {FormTextInput('Street Address', 'address_1_line_1')}
        {FormTextInput('City', 'address_1_city')}
        {StateSelection('address_1_state', 'State')}
        {FormTextInput('Zip Code', 'address_1_zip_code')}
        {PhoneInputField('front')}
      </>)
  }



  const NameAndPositionForm = () => {
    return (<>
        {FormTextInput("Approved Dealer Name", 'dealer_name')}
        {FormTextInput("Employee First and Last name", 'employee_name')}
        {FormTextInput("Employee Position", 'employee_position')}
        {FormTextInput("Employee Email", 'employee_email')}
        {AddressInputSelect('front')}
        {PhoneInputField('front')}
      </>)
  }
  const NameAndPositionFormWithAdditionalAddresses = () => {
    return(<>
        {NameAndPositionForm()}
        {RenderExtraAddresses()}
      </>)
  }
  const PetEvolutionAppointmentCard = () => {
    return(<>
        {FormTextInput('Street Address', 'address_1_line_1')}
        {FormTextInput('City', 'address_1_city')}
        {StateSelection('address_1_state', 'State')}
        {FormTextInput('Zip Code', 'address_1_zip_code')}
        {PhoneInputField('front')}
      </>)
  }
  // END FORMS

  const configureForm = () => {
    resolveFormConfiguration()
  }

  const baseFormState = () => ({
    dealer_name: '',
    front_address: '-1',
    front_phone: ''
  })

  const formMap = {
    "Fill in the Blank": {
      form: FbForm,
      fieldsInitialState: baseFormState()
    },
    "Fill in the Blank - Color": {
      form: FbForm,
      fieldsInitialState: baseFormState()
    },
    "Fill in the Blank - CSAT": {
      form: FillInTheBlankBaseForm,
      fieldsInitialState: baseFormState()
    },
    "Name and Position": {
      form: NameAndPositionForm,
      fieldsInitialState: {
        ...baseFormState(),
        employee_name: '',
        employee_position: '',
        employee_email: ''
      }
    },
    "Name and Position - Color": {
      form: NameAndPositionForm,
      fieldsInitialState: {
        ...baseFormState(),
        employee_name: '',
        employee_position: '',
        employee_email: ''
      }
    },
    "Name and Position - CSAT": {
      form: NameAndPositionFormWithAdditionalAddresses,
      fieldsInitialState: {
        ...baseFormState(),
        employee_name: '',
        employee_position: '',
        employee_email: ''
      }
    },
    "TW Wireless - Name": {
      form: FbFormTW,
      fieldsInitialState: {
        ...baseFormState(),
        employee_name: '',
        employee_position: '',
      }
    },
    "TW Wireless - Blank": {
      form: FbFormTWBlank,
      fieldsInitialState: baseFormState()
    },
    "WS Without Address": {
      form: WingsnobBaseForm,
      fieldsInitialState: {
        ...baseFormState(),
        employee_name: '',
        employee_position: '',
      }
    },
    "WS With Address": {
      form: WingsnobFormWithAddress
    },
    "PE Appointment Card": {
      // yyy
      form: PetEvFormPhoneAddress,
      fieldsInitialState: {
        ...baseFormState(),
        phone_front: '',
        address_1_line_1: '',
        address_1_city: '',
        address_1_state: '-1',
        address_1_zip_code: ''
      }
    }
  }

  // returns form configuration object
  const resolveFormConfiguration = () => {
    const result = {
      fields: [],
      renderAdditionalAddresses: false,
      manualAddressInput: false,
      form: null
    }

    switch (props.selectedArtwork.business_card_type) {
      case "Fill in the Blank":
      case "Fill in the Blank - Color":
        result.form = FbForm
        result.fields = [ 'dealer_name' ]
        break;
      case "Fill in the Blank - CSAT":
        result.form = FillInTheBlankBaseForm
        result.fields = [ 'dealer_name' ]
        break;
      case "Name and Position":
      case "Name and Position - Color":
        result.form = NameAndPositionForm
        result.fields = [
          'employee_name',
          'employee_position',
          'employee_email',
          'dealer_name',
        ]
        break;
      case "Name and Position - CSAT":
        result.form = NameAndPositionFormWithAdditionalAddresses
        result.fields = [
          'employee_name',
          'employee_position',
          'employee_email',
          'dealer_name',
        ]
        break;
      case "TW Wireless - Name":
        result.form = FbFormTW
        result.fields = [
          'dealer_name',
          'employee_name',
          'employee_position',
        ]
        break;
      case "TW Wireless - Blank":
        result.form = FbFormTWBlank
        result.fields = [
          'dealer_name'
        ]
        break;
      case "WS Without Address":
        result.form = WingsnobBaseForm
        result.fields = [
          'employee_email',
          'employee_name',
          'employee_position',
        ]
        break;
      case "WS With Address":
        result.form = WingsnobFormWithAddress
        result.fields = [
          'employee_email',
          'employee_name',
          'employee_position',
          'address_1_line_1',
          'address_1_city',
          'address_1_zip_code',
        ]
        result.manualAddressInput = true
        break;
      case "PE Appointment Card":
        result.form = PetEvFormPhoneAddress
        result.fields = [
          // 'address_phone_front',
          'address_1_line_1',
          'address_1_city',
          // 'address_1_state',
          'address_1_zip_code',
        ]
        result.manualAddressInput = true
        break;
      default:
        break;
    }
    setFormConfiguration(result)
    return result
  }

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    setFormValues({ ...formValues, [name]: value })
    validateInput(name, value)
  }

  const validateInput = (name, value) => {
    if (!!value && value !== '-1') {
      // set initial to false onChange of any input
      setErrors({ ...errors, initial: false, [name]: value })
    }
  }

  const handleChangePhone = (key, value, required) => {
    setPhoneNumbers({
      ...phoneNumbers,
      [key]: formatPhoneNumber(value) // store formatted phone in state
    })
  }

  const handleChangeAddress = (addressNum, value) => {
    setAllAddresses({ ...allAddresses, [addressNum]: value })
  }

  const handleChangeState = (name, value) => {
    setFormValues({ ...formValues, [name]: value })
  }

  const validateDuplicateAddresses = () => {
    // check for duplicate addresses being used
    const addresses = Object.values(allAddresses)
    const checkIfArrayIsUnique = (myArray) => {
      return myArray.length === new Set(myArray).size;
    }
    if (!checkIfArrayIsUnique(addresses.filter(add => add !== '-1'))) {
      return false
    } else {
      return true
    }
  }

  const handlePreviewProof = () => {
    // for each named field, append to form data
    const formData = new FormData()
    formConfiguration.fields.forEach(fieldName => {
      formData.append(fieldName, formValues[fieldName])
    });

    formData.append('address_1_state', formValues['address_1_state'])

    // append addresses and phone numbers
    // addresses and phone numbers have same keys
    Object.keys(allAddresses).forEach(key => {
      formData.append(`address_${key}`, allAddresses[key] && allAddresses[key] !== '-1' ? allAddresses[key] : '')
      formData.append(`phone_${key}`,   phoneNumbers[key] && phoneNumbers[key] !== '-1' ? phoneNumbers[key] : '')
    })

    // submit form data
    props.complete(formData)
  }

  // FIELDS
  const RenderExtraAddresses = () => {
    return (<>
        {NumberOfAddressesInput()}
        {RenderAllPhoneAddressInputPairs()}
      </>)
  }

  const AddressInputSelect = (addressNumber) => {
    return <AddressInputDropdown
              label={addressNumber === 'front' ? 'Front Address' : `Address ${addressNumber}`}
              value={allAddresses[addressNumber]}
              onChange={(e) => handleChangeAddress(addressNumber, e.target.value)}
              options={RenderAddressOptions(addressNumber)}
           />
  }

  const RenderAddressOptions = () => {
    // filter out addresses that have already been used as a selection
    const availableAddressOptions = props.resolvedAddresses //.filter(address => !usedAddressIds.includes(address.title+address.address_line_1))
    let options = availableAddressOptions.map((address, i)=> {
      return (
        <option value={address.id} key={i}>
          { address.title ?
          (address.title + " - " + (address.full_name ? address.full_name + ", " : '') + address.address_line_1) :
          (address.full_name + ", " + address.address_line_1)
          }
        </option>
      )
    })
    return options
  }

  const PhoneInputField = (key, label, required=false) => {
    return (
      <Row>
        <Col xs='12'>
          <Label className="number-format-label">{required ? '* ': ''}{label ? label : key === 'front' ? 'Front Phone' : `Phone Number ${key}`}</Label>
          <Input
            type="phone"
            className="number-format-field"
            value={phoneNumbers[key]}
            name={`address_phone_${key}`}
            id={`address_phone_${key}`}
            onChange={(e) => handleChangePhone(key, e.target.value, required)}
            autoComplete='false'
          />

          {errors.phone_numbers && errors.phone_numbers[key] ? (
            <><br />
            <span className="address_error">{errors.phone_numbers[key]}</span>
            </>
          ) : null}
        </Col>
      </Row>
    )
  }
  const RenderAllPhoneAddressInputPairs = () => {
    const numberOfPairs = parseInt(numberOfAddresses, 10)
    const renderSingleAddressPair = num => {
      return (
        <>
          {AddressInputSelect(num)}
          {PhoneInputField(num)}
          <hr />
        </>
      )
    }

    return (
      <>
        { Array(numberOfPairs).fill().map((x,i)=>i+1).map( num => {
          return renderSingleAddressPair(num)
        }) }
      </>
    )
  }
  const NumberOfAddressesInput = () => {
    return (
      <Row>
        <Col>
          <Label for='addresses' className="number-format-label">Addresses</Label>
          <Input
            type="select"
            name='addresses'
            value={numberOfAddresses}
            onChange={(e) => setNumberOfAddresses(e.target.value)}
            >
            {[0,1,2,3,4,5,6].map(num => <option value={num}>{num === 0 ? 'No' : num} Address{num === 1 ? null : 'es'}</option>)}
          </Input>
        </Col>
      </Row>
    )
  }
  const StateSelection = (fieldName, label) => {
    return (
      <Row>
        <Col xs="12">
          <Label className="number-format-label" for={fieldName}>{label}</Label>
          <Input
            type='select'
            value={formValues['address_1_state']}
            onChange={(e) => handleChangeState(fieldName, e.target.value)}
            name={fieldName}
          >
            <option key={"-1"} value="-1" >Select State...</option>
            {Object.keys(state_list).map((abbreviation, i) => {
              return (
                <option key={i} value={abbreviation}>{state_list[abbreviation]}</option>
              )
            })}
          </Input>
        </Col>
      </Row>
    )
  }
  // END FIELDS

  return (
    <div>
      {formMap[props.selectedArtwork.business_card_type] ? formMap[props.selectedArtwork.business_card_type].form() : null}

      <br />

      <Button
        className="button btn-active btn-md mr-15 mb-20 mb-sm-0"
        onClick={handlePreviewProof}
        disabled={
          // button is disabled if there is an error message or if form is not valid
          !!errorMessage || isFormValid === false
        }
      >Preview Proof</Button>

      {errorMessage}

      <br />

    </div>
  )
}

export default BusinessCardForm