import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import _ from 'lodash'
import { fetchTax, fetchShippingQuote, createToken, clearPromotions, fetchCartItems, fetchUserCredit, fetchUser } from '../../actions'
import {
  setSubtotal,
  setTaxQuote,
  setGrandTotal,
  setErrors,
  setShippingLoading,
  checkoutOrderCleanup,
  setCheckoutLoading,
  setTaxLoading,
} from './checkoutFunctions'
import { useMediaQuery } from 'react-responsive'
import { resolveUnitPrice } from './checkoutFunctions'
import Sticky from 'react-sticky-el'
import LoadingSpinner from '../general/LoadingSpinner'
import CheckoutErrorsPanel from './CheckoutErrorsPanel'
import CheckoutAddressSelection from './CheckoutAddressSelection'
import CheckoutCreditBalances from './CheckoutCreditBalances'
import CheckoutCardPanel from './CheckoutCardPanel'
import CartItemsList from '../Cart/CartItemsList'
import PromotionsPanel from './CheckoutPromotionsPanel'
import OrderSummary from './CheckoutOrderSummary'
import CheckoutInformation from './CheckoutInformation'
import { cartItemsRequireCheckForApproval, returnCartItemsRequireApprovalError } from '../Cart/cartFunctions'

const CheckoutPage = (props) => {
  // clear checkout reducer and clear promotions on unMount
  useEffect(() => {
    return function cleanup() {
      props.checkoutOrderCleanup().then(() => {
        props.clearPromotions()
      })
    }
  }, [])

  useEffect(() => {
    initializeCheckout()
  }, [props.currentLocation.id, props.currentUser.id])

  // if there are any legacy errors present, i.e. shipping, set that error in checkoutReducer
  useEffect(() => {
    const legacyShippingErrorExists =
      props.errors && props.errors.shipping_errors && props.errors.shipping_errors.message
    if (legacyShippingErrorExists && !props.checkoutErrors.shipping) {
      props.setErrors({ shipping: true })
    } else if (!legacyShippingErrorExists && props.checkoutErrors.shipping) {
      props.setErrors({ shipping: false })
    }
  }, [props.errors])

  useEffect(() => {
    if (props.tax.tax_status === 'SUCCESS' && props.tax.total_tax_cents >= 0) {
      props.setTaxQuote(props.tax.total_tax_cents)
    }
  }, [props.tax])

  // when any price totals change, fetch tax
  useEffect(() => {
    const multiAddressShippingEnabled = props.portal.multiple_address_shipping

    // For multi-address shipping, make sure each cart item has a selection
    const eachItemHasSelection = multiAddressShippingEnabled
      ? props.cartItems.every(cartItem =>
          props.checkout.multiAddressSelection.map(s => s.cartItemId).includes(cartItem.cart_item_id)
        )
      : true // for single-address shipping, we don't need this condition

    // If there's a selected delivery address, a positive subtotal, and the appropriate shipping settings, fetch tax
    if (
      !_.isEmpty(props.selectedDeliveryAddress) &&
      (multiAddressShippingEnabled ? eachItemHasSelection : !multiAddressShippingEnabled)
    ) {
      fetchTaxQuote()
    }
  }, [
    props.checkout.multiAddressSelection,
    props.selectedDeliveryAddress,
    props.subtotal,
    props.checkout.shippingQuote,
    props.checkout.subtotalReduction,
    props.checkout.shippingReduction,
    props.checkout.productReduction,
  ])


  // when delivery address is selected, fetch shipping quote
  useEffect(() => {
    // call different API if smart address?
    if (!_.isEmpty(props.selectedDeliveryAddress) && props.currentLocation.id) {
      props
        .fetchShippingQuote(
          props.selectedDeliveryAddress,
          props.cartItems,
          props.currentUser.id,
          props.currentLocation.id,
          props.portal.id,
          props.portal.client_id,
          props.checkout.multiAddressSelection
        )
        .then((resp) => {
          props.setShippingLoading(false)
          props.setErrors({ shipping: false })
        })
        .catch((err) => {
          props.setShippingLoading(false)
          props.setErrors({ shipping: true })
        })
    }
  }, [props.selectedDeliveryAddress, props.checkout.multiAddressSelection, props.currentLocation.id])

  // Check if cart items contain both requires approval and does not require approval
  useEffect(() => {
    if (props.cartItems && props.cartItems.length > 0 && cartItemsRequireCheckForApproval(null, props.cartItems, props.currentLocation)) {
      props.returnCartItemsRequireApprovalError(props.cartItems, null)
    }
  }, [props.cartItems])

  // Detect zoom level and set state
  const detectZoom = () => {
    const zoomLevel = Math.round(window.devicePixelRatio * 100)
    if (zoomLevel > 100) {
      setIsZoomedIn(true)
    } else {
      setIsZoomedIn(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', detectZoom)
    return () => window.removeEventListener('resize', detectZoom)
  }, [detectZoom])

  const initializeCheckout = () => {
    props.setCheckoutLoading(true).then(() => {
      props.checkoutOrderCleanup().then(() => {
        props.clearPromotions().then(() => {
          const locationId = props.currentLocation.id
          const userId = props.currentUser.id

          window.scrollTo(0, 0)
          props.createToken(locationId, userId).then(() => {
            props.fetchUser()
            props.fetchUserCredit(locationId)
            props.fetchCartItems(
              userId,
              locationId,
              props.portal,
              props.currentLocation,
              props.userCredits.coop_credit.remaining
            )
          })
        })
      })
    })
  }

  const addTaxablePriceToCartItems = (cartItems) => {
    const incomingCartItems = _.cloneDeep(cartItems)

    return incomingCartItems.map((cartItem) => {
      // taxable price is unit price minus discounts
      // pass true to second arg to include any discount
      const cartItemUnitPriceCents = resolveUnitPrice(cartItem.priceData, true)
      return {
        name: cartItem.name,
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
        taxable_price: cartItemUnitPriceCents,
        cart_item_id: cartItem.cart_item_id
        // ...cartItem,
      }
    })
  }

  const fetchTaxQuote = () => {
    props.setTaxLoading(true)

    let formData = new FormData()
    let delivery_address = props.checkout.selectedDeliveryAddress

    const cartItemsWithTaxablePrice = addTaxablePriceToCartItems(props.cartItems)

    formData.append('delivery_address', JSON.stringify(delivery_address))
    formData.append('cart_items', JSON.stringify(cartItemsWithTaxablePrice))
    formData.append('tax_adjustment', props.checkout.taxReduction)

    // if portal is multi address, send address selections
    if (props.portal.multiple_address_shipping) {
      formData.append('multi_address_selection', JSON.stringify(props.checkout.multiAddressSelection))
    }

    if (props.applied_promotions.length > 0) {
      formData.append('promotion_id', props.applied_promotions[0].promotion_id)
      formData.append('subtotal_reduction', props.checkout.subtotalReduction)
      formData.append('shipping_reduction', props.checkout.shippingReduction)
      formData.append('product_reduction', props.checkout.productReduction)
    }

    if (props.smart_delivery_addresses) {
      formData.append('epcid_zero', props.smart_delivery_addresses.epcid)
    }

    props
      .fetchTax(formData)
      .then(resp => {
        props.setTaxLoading(false)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.error('Request aborted:', err.message)
        } else {
          // Only set taxLoading to false for errors other than cancel
          props.setTaxLoading(false)
        }
      })
  }

  const isBigScreen = useMediaQuery({ query: '(min-width: 753px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  // CALCULATES HEIGHT OF SCROLL DOWN MENU AND ADDS MARGIN ON STICKY SIDEBAR
  const [isZoomedIn, setIsZoomedIn] = useState(false)

  const fixedHeaderElement = document.querySelector('.iron-fixed-header')
  const menuHeight = fixedHeaderElement !== null ? fixedHeaderElement.offsetHeight : '100px'

  const screenHeight = window.screen.availHeight
  const responseHeight = screenHeight !== null && screenHeight <= 770 ? true : false

  const showCheckoutInformation = props.currentLocation.show_checkout_information && props.checkoutForm.length > 0

  return (
    <div>
      <Container className="mt-30 mb-30">
        <Row>
          <Col xs={12} md={7} lg={8}>
            {showCheckoutInformation ? <CheckoutInformation /> : null}

            <CheckoutErrorsPanel />

            <CheckoutAddressSelection />

            {props.cart_loading ? <LoadingSpinner /> : <CheckoutCreditBalances />}

            {props.youPay > 0 ? (
              <CheckoutCardPanel youPay={props.youPay} extraFields={props.currentUser.run_automation_and_extra_fields} />
            ) : null}

            <CartItemsList isCheckout={true} />
          </Col>

          <Col
            xs={12}
            md={5}
            lg={4}
            className="right-panel-promotions-summary"
          >
            <Sticky
              className="sticky-right-panel"
              topOffset={0}
              stickyStyle={{
                top: menuHeight >= 90 ? '160px' : '100px',
                zIndex: 2,
              }}
              disabled={isTabletOrMobile || responseHeight || isZoomedIn}
            >
              <PromotionsPanel />

              <OrderSummary isCheckout={true} />
            </Sticky>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    checkout: state.checkout,
    errors: state.errors,
    portal: state.portal,
    userCredits: state.userCredits,
    delivery_addresses: Object.values(state.delivery_addresses),
    smart_delivery_addresses: state.smart_delivery_addresses,
    location: state.locations[0],
    currentLocation: state.currentLocation,
    cart_multiple_smart_delivery_addresses: state.cart_multiple_smart_delivery_addresses,
    outOfStockItems: state.errors.out_of_stock,
    quote: state.order_shipping_quote,
    success_message: Object.values(state.success_message),
    applied_promotions: Object.values(state.applied_promotions),
    orderFallback: state.order_fallbacks.fallback,
    customerGroup: state.customerGroup,
    token: state.token,
    updated_cart: state.updated_cart,
    maxQuantities: state.maxQuantities,
    cartItems: state.cartItems,
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    youPay: state.checkout.youPay,
    cart_loading: state.checkout.cart_loading,
    tax: state.tax,
    order_shipping_quote: state.order_shipping_quote,
    checkoutErrors: state.checkout.errors,
    currentUser: state.currentUser,
    subtotal: state.checkout.subtotal,
    checkoutForm: state.checkoutForm
  }
}

export default connect(mapStateToProps, {
  fetchTax,
  fetchShippingQuote,
  setSubtotal,
  createToken,
  setTaxQuote,
  setGrandTotal,
  setErrors,
  setShippingLoading,
  checkoutOrderCleanup,
  setCheckoutLoading,
  clearPromotions,
  fetchCartItems,
  setTaxLoading,
  fetchUserCredit,
  fetchUser,
  returnCartItemsRequireApprovalError
})(CheckoutPage)
