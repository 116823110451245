import React, { Component } from 'react';

class LoadingSpinner extends Component {

	render(){
		return(
			<div className="animated fadeIn pt-1 text-center"><div className="spinner sk-spinner-pulse sk-spinner-green"></div></div>
		)
	}
}

export default LoadingSpinner
