import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { connect } from 'react-redux'
import ProductVariantSelect from './ProductVariantSelect'
import NumericInput from 'react-numeric-input'
import {
  clearProductPage,
  productIsOnSale,
  validateTemplateField,
  productRequiresApproval,
  productStockError,
} from './productPageFunctions'
import dollar_price from '../general/DollarPrice'
import { calculateFinalProductPrice, setErrors, updateProduct } from './productPageFunctions'
import { fetchStockCount } from '../../actions'
import ProductTemplateFields from './ProductTemplateFields'
import DigitalProofingAndArtworkForm from './DigitalProofingForms/DigitalProofingAndArtworkForm'
import BundleProductList from './BundleGroups/BundleProductList'
import { compose } from 'redux'
import { addPriceDataToBundleProducts } from '../../helpers/cartItemsFormatter'
import { resolvePrice, resolveUnitPrice } from '../Checkout/checkoutFunctions'
import { useMediaQuery } from 'react-responsive'
import BundleGroups from './BundleGroups/BundleGroups'
import { fetchMaxQuantities, saveSearchQuery } from '../../actions'
import MaxQuantity from './MaxQuantity'

const placeholderImage = require(`./placeholder-image.jpg`)

const ProductDetailsShow = props => {
  const { portal, product, currentLocation } = props

  // if product has qty increments, set initial quantity value to required increment
  const [quantity, setQuantity] = useState(
    product && product.minimum_qty_allowed_in_shopping_cart
      ? product.minimum_qty_allowed_in_shopping_cart
      : product.enable_qty_increments
      ? product.qty_increments
      : 1
  )
  // Set primary image to the first one, or placeholder image if product has none
  const [primaryImage, setPrimaryImage] = useState(
    product && product.images && product.images.length > 0 ? product.images[0].original : placeholderImage.default
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [displayedSku, setDisplayedSku] = useState(product.sku)
  const [showSpecModals, setShowSpecModals] = useState({ 1: false, 2: false, 3: false })
  const [showProductModal, setShowProductModal] = useState(false)
  const [selectedVariants, setSelectedVariants] = useState({})
  const [selectedArtwork, setSelectedArtwork] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState(false)
  const [selectedAddOn, setSelectedAddOn] = useState(false)
  const [selectedAddOn2, setSelectedAddOn2] = useState(false)
  const [allConfigSelectionsMade, setAllConfigSelectionsMade] = useState(false)

  // when errors change, resolve error message
  useEffect(() => {
    setErrorMessage(resolveErrorMessages(props.errors))
  }, [props.errors])

  // when stocks are updated, check to see if product is in stock
  useEffect(() => {
    const { product } = props
    if (
      (props.inStock === false && props.stocks[displayedSku] > 0) ||
      (product.flags.is_bundle === true && product.flags.bundle_type === 'Breakable')
    ) {
      props.setInStock(true)
    } else {
      if (props.inStock === true && props.stocks[displayedSku] <= 0) {
        props.setInStock(false)
      }
    }
  }, [props.stocks])

  // When selected variants change, selections have been made, fetch stock count with new sku
  useEffect(() => {
    if (validateConfigSelections() === true && (!props.product.flags.is_bundle || props.product.flags.bundle_type === "Unbreakable")) {
      const selectedConfigOptionIds = Object.values(selectedVariants).map(variant => variant.id)
      const productSkuId = props.product.product_sku_id

      props.fetchStockCount(displayedSku, props.portal.id, props.currentLocation.id, selectedConfigOptionIds, props.product.id, null, productSkuId).then(() => {
        if (props.stocks[displayedSku] > 0) {
          props.setInStock(true)
        } else {
          props.setInStock(false)
        }
      })
    }
  }, [selectedVariants, displayedSku, props.currentUser.id])

  // componentDidMount - sets coopPercent
  useEffect(() => {
    // If product is a configurable, set initial configSelection error to true
    // so when page loads you are asked to make a selection
    if (props.product.flags.is_configurable === true) {
      props.setErrors({ configSelection: true })
    }
  }, [])

  // on any update, if all selections have been made, clear selection error
  useEffect(() => {
    if (
      props.product.flags.is_configurable === true &&
      props.product.variants.length === Object.keys(selectedVariants).length &&
      props.errors.configSelection === true
    ) {
      props.setErrors({ configSelection: false })
    }
  })

  // re-calculate total price when sku, template, or configs change
  useEffect(() => {
    // if product is a breakable bundle,
    // we need to calculate price for each of the child products first, then sum the totals

    if (props.product.flags.bundle_type === 'Breakable') {
      // first add price data to child products,
      // making sure to pass in qty of parent bundle
      const bundleProductsWithPriceData = addPriceDataToBundleProducts(
        props.product.bundle.products,
        props.portal,
        props.currentLocation,
        props.cartItems,
        quantity,
        props.configurableSelection,
        props.templateFields,
        false
      )

      const newBreakableBundle = {
        ...props.product,
        bundle: {
          ...props.product.bundle,
          products: bundleProductsWithPriceData,
        },
      }

      const newPriceData = calculateFinalProductPrice(
        props.product,
        quantity,
        props.product.flags.is_bundle ? props.configurableSelection : selectedVariants,
        _.cloneDeep(props.templateFields),
        props.cartItems,
        props.portal,
        props.currentLocation,
        null,
        true,
        quantity
      )
      props.updateProduct({ ...newBreakableBundle, priceData: newPriceData }, props.cartItems).then(() => {
        validateCart()
      })
    } else {
      // calculate price then update qty
      const newPriceData = calculateFinalProductPrice(
        props.product,
        quantity,
        props.product.flags.is_bundle ? props.configurableSelection : selectedVariants,
        _.cloneDeep(props.templateFields),
        props.cartItems,
        props.portal,
        props.currentLocation,
        null,
        true,
        quantity
      )
      props
        .updateProduct({ ...props.product, quantity: quantity, priceData: newPriceData }, props.cartItems)
        .then(() => {
          validateCart()
        })
    }
  }, [
    selectedVariants,
    quantity,
    displayedSku,
    props.templateFields,
    props.configurableSelection,
    props.inStock,
    selectedArtwork,
    props.artworkSelection,
    props.cartItems,
    props.digitalProofingSelection,
  ])

  // check max qty
  useEffect(() => {
    maxQtyProductHandler(quantity)
  }, [quantity, props.cartItems, props.maxQuantities[props.product.id]])

  useEffect(() => {
    if (props.product.product_id && props.customerGroup.id) {
      const productIds = props.product.product_id ? [props.product.product_id] : []
      const customerGroupId = props.customerGroup.id
      props.fetchMaxQuantities({ productIds, customerGroupId })
    }
  }, [props.currentUser, props.customerGroup, props.product.id])

  // Check quantity to stock for product
  useEffect(() => {
    if (props.product && quantity && Object.values(props.stocks).length > 0) {
      let product = { ...props.product }

      if (product.flags.is_bundle && product.flags.bundle_type === 'Breakable') {
        product = product.bundle.products
      } else {
        if (product.flags.is_configurable && displayedSku) {
          product['product_full_sku'] = displayedSku
        }
        product = [product]
      }

      const stockError = productStockError(product, quantity, props.stocks)
      props.setErrors({ stock: stockError })
    }
  }, [props.product, quantity, props.stocks, selectedVariants])

  const validateConfigSelections = () => {
    if (!props.product) {
      return false
    } else {
      const result =
        !props.product.variants ||
        (props.product.variants && Object.keys(props.product.variants).length === Object.keys(selectedVariants).length)
      setAllConfigSelectionsMade(result)
      return result
    }
  }

  const coopEnabledOnPortalProductAndLocation = () => {
    return props.product.coop_enabled && portal.coop_enabled && currentLocation.coop_enable
  }

  const handleAddProduct = product => {
    const product_sku = displayedSku
    const product_with_sku = {
      ...product,
      // ...props.product,
      sku: product_sku,
      artwork: selectedArtwork,
      product_sku_id: product.product_sku_id
    }
    props.addProductToBasket(product_with_sku, quantity)
  }

  
  const ImageContainer = () => {
    const images = props.product.images

    return (
      <Container className="iron-product-gallery">
        <Row style={{ padding: '8px' }}>
          {/* MAIN PRODUCT IMAGE  */}
          <Col xs={12} sm={12} md={12} lg={12} className="p0 box-shadow mf-img-container">
            {/* DISPLAY CUSTOM CSS FROM PORTAL TAGS */}
            {props.product.css_portal_tags &&
              props.product.css_portal_tags.length > 0 &&
              props.product.css_portal_tags.map(tag => <div key={tag.name} className={tag.name}></div>)}

            {/* Requires Approval Flag */}
            {productRequiresApproval(product, props.currentLocation) && (
              <div className="requires-approval-product-card-box requires-approval-product-page-box">
                <span>Requires Approval</span>
              </div>
            )}

            <img src={primaryImage} alt="poster-image" className="img-border w-100" />
          </Col>
        </Row>
        {images && images.length > 1 && (
          <Row>
            {/* ADDITIONAL PRODUCT IMAGES  */}
            <div className="additionalimages">
              {images &&
                images.map((image, index) => {
                  return (
                    <div key={index + 1} className="product-gallery-item box-shadow mf-additional-img-container">
                      <div
                        onMouseEnter={() => setPrimaryImage(image.original)}
                        onClick={() => setPrimaryImage(image.original)}
                      >
                        <img src={image.small} alt="product-item" className="img-border" />
                      </div>
                    </div>
                  )
                })}
            </div>
          </Row>
        )}
      </Container>
    )
  }

  const getCartItemsQuantities = cartItems => {
    // count products by mapping to their IDs
    const quantities = {}

    // count all occurrences of product in cart
    cartItems.forEach(cartItem => {
      if (quantities[cartItem.product_id]) {
        quantities[cartItem.product_id] += cartItem.quantity
      } else {
        quantities[cartItem.product_id] = cartItem.quantity
      }
    })

    return quantities
  }

  const maxQtyProductHandler = qty => {
    // get total count in cart for this product. set to 0 if not existing
    const productId = props.product.id
    const cartQuantities = getCartItemsQuantities(props.cartItems)
    const totalProductCount = cartQuantities[productId] || 0

    // get qty available for this product from redux
    const quantityAvailable = props.maxQuantities[productId] ? props.maxQuantities[productId] : 0

    // check if avaialable and set error appropriately.
    // if keys do not include this product ID, there is no max qty and no error should be set.
    const qtyGrandTotal = qty + totalProductCount

    if (qtyGrandTotal > quantityAvailable && Object.keys(props.maxQuantities).includes(`${productId}`)) {
      props.setErrors({ maxQty: true })
    } else {
      props.setErrors({ maxQty: false })
    }
  }

  const SpecButton = num => {
    const productHasSpec = () =>
      props.product[`product_spec_btn_title_${num}`] && props.product[`product_spec_btn_title_${num}`] !== ' '

    return productHasSpec() ? (
      <Button
        onClick={() => setShowSpecModals({ ...showSpecModals, [num]: true })}
        className="btn-lg mt-2 mr-15 mb-20 mb-sm-0 mf-outline-btn btn-lg-height"
      >
        {props.product[`product_spec_btn_title_${num}`]}
      </Button>
    ) : null
  }

  const QuantityBox = () => {
    const minQtyError = props.errors.minQty
    const incrQtyError = props.errors.incrQty

    return (
      <div
        className="mb-20 increment-quantity mb-20 mb-sm-0  min-height-3125 w-100"
        style={{ width: '100% !important' }}
      >
        <NumericInput
          onChange={newQuantity => handleChangeQuantity(newQuantity)}
          value={quantity}
          min={
            props.product.minimum_qty_allowed_in_shopping_cart
              ? props.product.minimum_qty_allowed_in_shopping_cart
              : props.product.enable_qty_increments
              ? product.qty_increments
              : 1
          }
          max={1000000000}
          step={product.enable_qty_increments ? product.qty_increments : 1}
          precision={0}
          mobile
          className="qtyinputbox min-height-3125 w-100"
          strict
          snap
        />

        {minQtyError && product.minimum_qty_allowed_in_shopping_cart ? (
          <p className="red-text" style={{ marginTop: '10px' }}>
            Minimum quantity is {product.minimum_qty_allowed_in_shopping_cart} items.
          </p>
        ) : (
          ' '
        )}

        {!minQtyError && incrQtyError && quantity && product.minimum_qty_allowed_in_shopping_cart <= 0 ? (
          <p className="red-text" style={{ marginTop: '10px' }}>
            This product must be
            <br />
            purchased in increments of {product.qty_increments}.
          </p>
        ) : (
          ' '
        )}

        {!minQtyError && incrQtyError && quantity && product.minimum_qty_allowed_in_shopping_cart > 0 ? (
          <p className="red-text" style={{ marginTop: '10px' }}>
            This product must be <br /> purchased in increments of {product.qty_increments}, <br />
            with a required minimum quantity of {product.minimum_qty_allowed_in_shopping_cart}.
          </p>
        ) : (
          ' '
        )}
      </div>
    )
  }

  const PriceInfo = () => {
    const productTotalUnitPrice = product ? resolveUnitPrice(product.priceData) : 0
    const productSetupCharge =
      Number.isInteger(product.priceData.setup_charge) && Number.isInteger(product.priceData.setup_charge) > 0
        ? product.priceData.setup_charge
        : 0

    return (
      <>
        <div className="d-flex">
          {/* Regular Price / Sale Price */}
          {product && productIsOnSale(product) ? (
            <div className="price-wrap-sale">
              <div className="price-wrap">
                <div>
                  <p>Retail Price</p>
                  <h4>
                    {' '}
                    <del>{dollar_price(product.price * quantity + productSetupCharge)}</del>
                  </h4>
                </div>
                <div className="ml-20">
                  <p>Sale Price</p>
                  <h4>{dollar_price(productTotalUnitPrice * quantity + productSetupCharge)}</h4>
                </div>
              </div>
              <div>
                <h4 className="active-color">
                  Now Only {dollar_price(productTotalUnitPrice)}
                  {quantity > 1 ? '/ea' : null}
                </h4>
              </div>
            </div>
          ) : (
            <div className="price-wrap float-u">
              <div className="float-b">
                <p>Retail Price</p>

                <div className="float-b">
                  <h4 className="mb-0">{dollar_price(productTotalUnitPrice * quantity + productSetupCharge)}</h4>
                </div>
              </div>
              <div className="float-b">
                <p className="ml-20" style={{ marginBottom: '2px' }}>
                  {quantity > 1 ? (
                    <em>
                      {dollar_price(productTotalUnitPrice)}
                      {'/ea'}
                    </em>
                  ) : null}
                </p>
              </div>
            </div>
          )}

          {/* Co-Op Price */}
          {coopEnabledOnPortalProductAndLocation() && (
            <div className="price-content coop-divider">
              <p className="active-color">{localStorage.getItem('coop_def')} Price</p>
              <h4 className="active-color">{dollar_price(product.priceData.coop_subtotal)}</h4>
            </div>
          )}
        </div>
      </>
    )
  }

  const Modals = () => {
    return (
      <>
        <Modal isOpen={!!showProductModal}>
          <ModalHeader>{showProductModal.product_name}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="8">
                {showProductModal.images && showProductModal.images.length > 0 && (
                  <Col xs="12">
                    <img src={primaryImage.original.url} alt="poster-image" />
                  </Col>
                )}
                <Table borderless size="sm">
                  <tbody>
                    <tr>
                      <th>
                        <span className="font-medium text-14">Product Name</span>
                      </th>
                      <td>
                        <span className="text-14">{showProductModal.product_name}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="font-medium text-14">Product SKU</span>
                      </th>
                      <td>
                        <span className="text-14">
                          {showProductModal.sku ? showProductModal.sku : showProductModal.product_sku}
                        </span>
                      </td>
                    </tr>
                    {props.currentLocation.show_price ? (
                      <tr>
                        <th>
                          <span className="font-medium text-14">Standalone Price</span>
                        </th>
                        <td>
                          <span className="text-14">{dollar_price(showProductModal.product_price_cents, 2)}</span>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Col>
              <Col xs="4">
                <img
                  src={showProductModal.product_images?.length > 0 ? showProductModal.product_images[0].url : ''}
                  alt="poster-image"
                />
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            {showProductModal.product_in_location && !showProductModal.hide_from_shop ? (
              <Link
                to={`/product/${showProductModal.product_id}/${portal.id}/${currentLocation.id}`}
                className="d-block"
              >
                <Button className="mf-outline-btn">View product Page</Button>
              </Link>
            ) : null}
            <Button className="mf-primary-btn w125px" onClick={() => setShowProductModal(false)}>
              Back
            </Button>
          </ModalFooter>
        </Modal>

        {ProductSpecModal(1)}
        {ProductSpecModal(2)}
        {ProductSpecModal(3)}
      </>
    )
  }

  const ProductSpecModal = num => {
    const button = (
      <Button color="danger" size="sm" onClick={() => hideSpecModal(num)}>
        Close
      </Button>
    )

    return (
      <Modal className="product-spec-modal" isOpen={showSpecModals[num]}>
        <ModalHeader>
          {product[`product_spec_btn_title_${num}`]}
          <span className="product-spec-modal-close">{button}</span>
        </ModalHeader>
        <ModalBody>
          <img src={product[`product_spec_url_${num}`]} />
        </ModalBody>
        <ModalFooter>{button}</ModalFooter>
      </Modal>
    )
  }

  const handleChangeQuantity = newQuantity => {
    onChangeUpdateQuantity(newQuantity)
  }

  const onChangeUpdateQuantity = newQuantity => {
    let newQty = parseInt(newQuantity)
    // handle max and min values
    if (newQty < 1) {
      newQty = 1
    } else if (newQty > 1000000000) {
      newQty = 1000000000
    }
    setQuantity(newQty)

    if (product.minimum_qty_allowed_in_shopping_cart && newQty < product.minimum_qty_allowed_in_shopping_cart) {
      props.setErrors({ minQty: true })
      return
    }

    if (product.enable_qty_increments) {
      if (newQty % product.qty_increments !== 0) {
        props.setErrors({ minQty: false, incrQty: true })
        return
      }
    }

    // get here only if no errors
    props.setErrors({ minQty: false, incrQty: false, stock: false })
  }

  const hideSpecModal = num => {
    setShowSpecModals({ ...showSpecModals, [num]: false })
  }

  // handles selection with given option ID and variant
  const selectVariant = (event, variant) => {
    const option_id = parseInt(event.target.value, 10)
    const option = variant.nested_data.find(o => o.id === option_id)

    let selectedOptionsByVariantId = selectedVariants
    selectedOptionsByVariantId[variant.variant_id] = option

    setDisplayedSku(`${product.sku}${buildSkuSuffix(selectedOptionsByVariantId)}`)
    setSelectedVariants(selectedOptionsByVariantId)
  }

  const buildSkuSuffix = selectedOptionsByVariantId => {
    // variants are sorted by order in product.variants
    // map each variant to its sku based on the user selection
    let sortedSkuCodes = product.variants.map(variant => {
      // find the user selection. If user has not made one, return null
      const selectedOption = selectedOptionsByVariantId[variant.variant_id]
      if (!selectedOption) {
        return null
      } else {
        const skuCode = selectedOption.sku_code
        return skuCode
      }
    })
    sortedSkuCodes = _.filter(sortedSkuCodes, skuCode => skuCode !== null) // remove null values

    // Build SKU suffix to be appended to base SKU
    // map each code to a formatted string with "-"
    let skuSuffix = sortedSkuCodes.map(skuCode => {
      return `-${skuCode}`
    })

    // concatenate the array of formatted codes to a single string and return
    skuSuffix = skuSuffix.join('')
    return skuSuffix
  }

  const resolveErrorMessages = allErrors => {
    const messages = Object.keys(allErrors).map(key => {
      if (props.errors[key] === true) {
        switch (key) {
          case 'configSelection':
          case 'radioSelection':
          case 'template':
            return 'Please make all required selections.'
          case 'stock':
            const stockMessage =
              props.product.flags.is_bundle && props.product.flags.bundle_type === 'Breakable'
                ? 'One or more items is not in stock.'
                : 'Quantity requested exceeds what is available in stock.'
            return stockMessage
          case 'maxQty':
            return 'You have exceeded the maximum allowed qty for this product.'
          case 'digitalProofing':
            return 'Please complete all required proofs.'
          case 'artwork':
            return 'Please make an artwork selection.'
          case 'configOptions':
            return 'One or more options is not available. Please contact support for assistance.'
          default:
            break
        }
      } else {
        return null
      }
    })
    return messages.filter(el => !!el)[0] //[0] ? messages[0] : ''
  }

  const validateCart = () => {
    // check for required template fields
    // (non-bundles)
    let templatesValid = true
    if (product.flags && product.flags.has_template) {
      templatesValid = Object.values(props.templateFields).every(field => {
        return validateTemplateField(field)
      })
    }

    const newErrorsObject = {}
    let artworksValid = false
    if (product.flags && product.flags.is_artwork) {
      // for artwork products, we can add to cart here, i.e. not through proof generation
      // artwork is valid if artwork is selected
      artworksValid = selectedArtwork && Object.keys(selectedArtwork).length > 0
      props.setErrors({ artwork: !artworksValid })
      newErrorsObject.artwork = !artworksValid
    } else if (product.flags.is_bundle && product.flags.bundle_type === 'Breakable') {
      // *** Validation for Breakable Bundles ***

      // bundles can be added to the cart if all config/artwork/required template field selections have been made
      // bundle templates:
      // first get all products that have templates
      const bundleProductsWithTemplates = _.filter(product.bundle.products, prod => prod.has_template === true)
      // for each product,
      const allTemplatesValid = bundleProductsWithTemplates.every(bundleProduct => {
        // get template fields with values for this product from Redux (if exists)
        if (props.templateFields[bundleProduct.bundle_group_product_id]) {
          const templateFieldsWithValues = Object.values(props.templateFields[bundleProduct.bundle_group_product_id])
          return templateFieldsWithValues.every(field => {
            return validateTemplateField(field)
          })
        } else {
          return false
        }
      })

      if (allTemplatesValid === true) {
        props.setErrors({ template: false })
      } else {
        props.setErrors({ template: true })
      }

      // check if any products are configurable
      let configsValid = false
      const configurableProducts = product.bundle.products.filter(prod => prod.is_configurable)
      if (configurableProducts && configurableProducts.length > 0) {
        // now, see if all products have selections made for each variant
        configsValid = configurableProducts.every(prod => {
          // get variants for this product
          const variants = prod.nested_data
          const bundle_group_product_id = prod.bundle_group_product_id // config selections are mapped to this unique prod ID
          const thisProductVariantSelections = props.configurableSelection[bundle_group_product_id]
          // if selected options length === number of variants, all have a selection
          if (
            thisProductVariantSelections &&
            variants &&
            thisProductVariantSelections.selected_options &&
            Object.values(thisProductVariantSelections.selected_options).length === variants.length
          ) {
            return true
          } else {
            return false
          }
        })
      } else {
        // if no configs
        configsValid = true
      }

      // next, check for artwork products (non-DP)
      const artworkProducts = product.bundle.products.filter(prod => prod.is_artwork)
      if (artworkProducts && artworkProducts.length > 0) {
        // now, see if all products have selections made for each variant
        artworksValid = artworkProducts.every(prod => {
          // get artworks for this product
          const bundle_group_product_id = prod.bundle_group_product_id // selections are mapped to this unique prod ID
          const thisArtworkSelection = props.artworkSelection[bundle_group_product_id]
          // if selected artworks length === 1 (can only have 1 art per product), requirements are met
          if (
            thisArtworkSelection &&
            thisArtworkSelection.selected_options &&
            Object.values(thisArtworkSelection.selected_options).length === 1
          ) {
            return true
          } else {
            return false
          }
        })
      } else {
        // if no artworks
        artworksValid = true
      }

      // next, check for digital proofing
      // for bundle DP, all proofs must have been generated
      let digitalProofingValid = false
      const dpProducts = product.bundle.products.filter(prod => prod.is_digital_proofing)
      if (dpProducts && dpProducts.length > 0) {
        // dp products exist, so check redux for a selection for each bundle product id
        const dpSelections = props.digitalProofingSelection
        // For every digital proofing product in the bundle,
        // return T/F if a proof has been generated for it
        digitalProofingValid = dpProducts.every(prod => {
          return !!dpSelections[prod.bundle_group_product_id]
        })
      } else {
        // if no DP
        digitalProofingValid = true
      }

      props.setErrors({
        configurable: !configsValid,
        artwork: !artworksValid,
        digitalProofing: !digitalProofingValid,
        template: !allTemplatesValid,
      })

      return configsValid && artworksValid && digitalProofingValid && allTemplatesValid
      // *** End of Validation for Breakable Bundles ***
    } else {
      props.setErrors({ template: !templatesValid })
      return !!(props.inStock && templatesValid)
    }
  }

  const handleShowProductModal = prod => {
    setShowProductModal(prod)
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 752px)' })

  // CALCULATES HEIGHT OF SCROLL DOWN MENU AND ADDS MARGIN ON STICKY SIDEBAR
  const fixedHeaderElement = document.querySelector('.iron-fixed-header')
  const menuHeight = fixedHeaderElement !== null ? fixedHeaderElement.offsetHeight : '100px'
  const screenHeight = window.screen.availHeight
  const responseHeight = screenHeight !== null && screenHeight <= 770 ? true : false
  const locationShowStockCount = props.currentLocation.show_stock_count

  return (
    <>
      <div style={{ width: '100%' }}></div>
      <Container className="product-detail-page section-pad w-100">
        <Row>
          {/* Left Half - Images */}
          <Col xs={12} sm={12} md={6} lg={5}>
            {/* <Sticky
              bottomOffset={100}
              stickyStyle={{
                top: menuHeight >= 90 ? '160px' : '100px',
              }}
              disabled={isTabletOrMobile || responseHeight}
            > */}
            {ImageContainer()}
            {/* </Sticky> */}
          </Col>

          {/* Right Half - Product Details */}
          <Col xs={12} sm={12} md={6} lg={7} style={{ paddingTop: '3px' }}>
            <h3 className="mb-0">{product.name}</h3>
            <p>
              <span className="font-medium text-14"> Product Code:</span>{' '}
              <span className="text-14">{displayedSku}</span>
            </p>

            <div className="price-content">
              {currentLocation.show_price ? <PriceInfo /> : null}

              {/* Tier Price Labels */}
              {product.tier_prices && product.tier_prices.length > 0 && props.currentLocation.show_price === true ? (
                <div className="tier-pricing-container">
                  <h6>Tier Pricing Available:</h6>
                  {product.tier_prices.map((tier_price, i) => {
                    return (
                      <ul key={i} className="tier-pricing-list">
                        <li>
                          {/* future purposes: displays tier qtys and price */}
                          <h6>{tier_price.price_per_qty_text}</h6>
                        </li>
                      </ul>
                    )
                  })}
                </div>
              ) : null}

              {/* Setup Charge Text */}
              {parseInt(product.setup_charge) > 0 && (
                <div className="setup-charge-container">
                  <span className="font-bold text-14">Setup Charge:</span>{' '}
                  <span className="text-14">{dollar_price(product.setup_charge)}</span>
                  <br />
                  <small>** Setup charge will be applied once per line item in cart</small>
                </div>
              )}

              {/* Product Spec Buttons */}
              <div className="mb-20">
                {SpecButton(1)}
                {SpecButton(2)}
                {SpecButton(3)}
              </div>
            </div>

            {/* Configurable Variant/Option Selection */}
            <div className="col-12 col-sm-12 p0 ml-0">
              {product.variants && product.variants.length > 0 ? (
                <ProductVariantSelect
                  product={product}
                  selectedOptionsByVariantId={selectedVariants}
                  selectVariant={selectVariant}
                  isBundle={false}
                  // errors={errors.variants}
                />
              ) : null}
            </div>

            {/* Render All Template Fields */}
            <ProductTemplateFields templateFields={product.template && product.template.template_fields} />

            {/* Digital Proofing Form */}
            {product.flags.is_digital_proofing || product.flags.is_artwork ? (
              <DigitalProofingAndArtworkForm
                product={product}
                quantity={quantity}
                addProductToBasket={props.addProductToBasket}
                selectedArtwork={selectedArtwork}
                setSelectedArtwork={setSelectedArtwork}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                selectedAddOn={selectedAddOn}
                selectedAddOn2={selectedAddOn2}
                setSelectedAddOn={setSelectedAddOn}
                setSelectedAddOn2={setSelectedAddOn2}
              />
            ) : null}

            {/* Bundle Products */}
            {/* Buildable Bundle Groups */}
            {props.product.bundle &&
              props.product.bundle.bundleGroups &&
              props.product.bundle.bundleGroups.length > 1 && (
                <BundleGroups
                  product={props.product}
                  // groups={props.bundle.bundle.bundleGroups}
                />
              )}

            {/* Main Bundle Product List */}
            {product.flags.is_bundle ? (
              <BundleProductList
                // bundle={product}
                group_type={'simple_group'}
                // setBundleValid={setBundleValid}
                setShowProductModal={handleShowProductModal}
                inStock={props.inStock}
                setInStock={props.setInStock}
                quantity={quantity}
                addProductToBasket={props.addProductToBasket}
                selectedArtwork={selectedArtwork}
                setSelectedArtwork={setSelectedArtwork}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
                selectedAddOn={selectedAddOn}
                selectedAddOn2={selectedAddOn2}
                setSelectedAddOn={setSelectedAddOn}
                setSelectedAddOn2={setSelectedAddOn2}
              />
            ) : null}

            {/* Stock Status Display */}
            {/* (Breakable Bundles stock handled differently) */}
            {props.product.flags && props.product.flags.bundle_type === 'Breakable' ? null : (
              <ul className="no-style mb-10">
                <li className="mb-0">
                  <span className="font-medium text-14"> Availability:</span>
                  {props.stocks[displayedSku] > 0 || props.product.flags.has_inventory === true ? (
                    product.show_backorder_text ? (
                      <span className="text-14 ml-1 danger-color">{portal.backorder_stock_text}</span>
                    ) : (
                      <span className="text-14 ml-1 success-color">
                        In Stock:{' '}
                        {locationShowStockCount
                          ? props.stocks[displayedSku] < 100000
                            ? props.stocks[displayedSku]
                            : '100,000+'
                          : null}{' '}
                        Available
                      </span>
                    )
                  ) : allConfigSelectionsMade ? (
                    <span className="text-14 ml-1 danger-color">Out of Stock</span>
                  ) : (
                    <span className="text-14 ml-1">Please select from available options</span>
                  )}
                </li>
              </ul>
            )}

            {/* Backorder Text */}
            {product.show_backorder_text && portal.backorder_text && (
              <div className="mb-10" style={{ marginTop: '-10px' }}>
                <span className="text-14 danger-color">{portal.backorder_text}</span>
              </div>
            )}

            {/* Check that a propduct has max quantities to load the display */}
            <MaxQuantity />

            {/* QTY & Add to cart Row Begins */}
            <Row>
              {/* Quantity Selection */}
              <Col xs="12" md="6" lg="6">
                {QuantityBox()}
              </Col>

              <Col xs="12" md="6" lg="6">
                {/* Add to Cart Button */}
                {/* Don't dispay Add to Cart button for digital proofing;
                they are added from a separate modal */}
                {product.flags.is_digital_proofing ? null : (
                  <div className="detail-btns">
                    <Button
                      style={{ minHeight: '50px' }}
                      disabled={
                        (props.stocks[displayedSku] <= 0 && props.product.flags.bundle_type !== 'Breakable') ||
                        !!errorMessage ||
                        props.product_loading ||
                        props.errors.minQty ||
                        props.errors.incrQty ||
                        props.errors.configOptions
                      }
                      className="mf-primary-btn button btn-active btn-lg mr-15 mb-20 mb-sm-0 w-100"
                      onClick={() => handleAddProduct(product)}
                    >
                      {props.product_loading ? (
                        <Spinner color="secondary" />
                      ) : (
                        `Add To Cart${
                          productRequiresApproval(product, props.currentLocation) ? ' (Approval Required)' : ''
                        }`
                      )}
                    </Button>
                    {productRequiresApproval(product, props.currentLocation) && (
                      <p style={{marginTop: "20px"}}>
                        Please Note: This item requires approval and will place your order on hold after checkout until
                        approval is granted.
                      </p>
                    )}
                    {errorMessage ? (
                      <p className="product-detail-page-error-message">
                        {/* Break lines at periods */}
                        {errorMessage.split('.').map((sentence, index, array) => {
                          return array.length - 1 === index ? (
                            sentence
                          ) : (
                            <>
                              {sentence}.
                              <br />
                            </>
                          )
                        })}
                      </p>
                    ) : null}
                  </div>
                )}
              </Col>
            </Row>

            {/* Product Description */}
            <div className={product.description ? 'description-container' : 'd-none'}>
              <h5>Description:</h5>
              <div className="short-description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
            </div>
          </Col>
        </Row>
      </Container>

      {Modals()}
    </>
  )
}

const mapStateToProps = state => {
  return {
    product: state.productPage.product,
    userCredits: state.userCredits,
    customerGroup: state.customerGroup,
    portal: state.portal,
    currentLocation: state.currentLocation,
    maxQuantities: state.maxQuantities,
    cartItems: state.cartItems,
    stocks: state.stocks,
    templateFields: state.productPage.templateFields,
    artworkSelection: state.productPage.artworkSelection,
    errors: state.productPage.errors,
    currentUser: state.currentUser,
    digitalProofingSelection: state.productPage.digitalProofingSelection,
    configurableSelection: state.productPage.configurableSelection,
  }
}

export default compose(
  connect(mapStateToProps, {
    fetchStockCount,
    setErrors,
    clearProductPage,
    updateProduct,
    fetchMaxQuantities,
    saveSearchQuery,
  })
)(ProductDetailsShow)
