import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import { Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import './pager.css'

/**
 * @typedef {Object} MFPagerRange
 * @property {number} totalItems - Indincates the total number of items handled between all pages
 * @property {number} itemsPerPage - Indincates the number of items handled on each page
 *
 * @typedef {Object} MFPagerProps
 *
 * @property {boolean} showPrevNextNavigationButtons - Indicates whether or not to show previous and next buttons in pagination
 * @property {boolean} showFirstLastNavigationButtons - Indicates whether or not to show first and last buttons in pagination
 * @property {boolean} showPageIndexSelectors - Indicates whether or not to show page index buttons in pagination
 * @property {number} maxIndexSelectors - Indicates the number of page index buttons to show at a time
 * @property {number} pageCount - Indicates the total number of pages pagination is handling
 * @property {number} activePage - Indicates the currently active page in pagination
 * @property {(page: number) => void} onChange - Callback function to handle page changes
 * @property {MFPagerRange} range
 *
 */

/**
 * @type {MFPagerProps}
 */
const propTypes = {
  /**
   * @default true
   */
  showPrevNextNavigationButtons: PropTypes.bool,

  /**
   * @default true
   */
  showFirstLastNavigationButtons: PropTypes.bool,

  /**
   * @default true
   */
  showPageIndexSelectors: PropTypes.bool,

  /**
   * @default 5
   */
  maxIndexSelectors: PropTypes.number,

  /**
   * @default 1
   */
  pageCount: PropTypes.number,

  /**
   * @default 1
   */
  activePage: PropTypes.number,

  /**
   * @default undefined
   */
  onChange: PropTypes.func.isRequired,

  /**
   * @default undefined
   */
  range: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
  }),
}

/**
 * @type {MFPagerProps}
 */
const defaultProps = {
  showPrevNextNavigationButtons: true,
  showFirstLastNavigationButtons: true,
  showPageIndexSelectors: true,
  maxIndexSelectors: 5,
  pageCount: 1,
  activePage: 1,
}

/**
 * MFPager component for pagination.
 * @param {MFPagerProps} props
 * @returns {JSX.Element}
 */
const MFPager = forwardRef(
  (
    {
      showPrevNextNavigationButtons = true,
      showFirstLastNavigationButtons = true,
      showPageIndexSelectors = true,
      maxIndexSelectors = 5,
      pageCount = 1,
      activePage = 1,
      onChange,
      range,
      ...props
    },
    ref
  ) => {
    const pages = useMemo(() => Array.from(Array(pageCount + 1).keys()).slice(1), [pageCount])

    const pagesSelectors = useMemo(() => {
      const pagesLength = pages.length
      const activeIndex = pages.indexOf(activePage)

      const middleIndexPoint = Math.floor(maxIndexSelectors / 2)

      // If activeIndex is too close to the beginning
      if (activeIndex <= middleIndexPoint) {
        return pages.slice(0, maxIndexSelectors)
      }

      // If activeIndex is too close to the end
      if (activeIndex >= pagesLength - middleIndexPoint) {
        return pages.slice(pagesLength - maxIndexSelectors)
      }

      // Default case: activeIndex is centered
      const start = activeIndex - middleIndexPoint
      return pages.slice(start, start + maxIndexSelectors)
    }, [pages, activePage])

    const startItem = (activePage - 1) * range?.itemsPerPage + 1
    const endItem = Math.min(activePage * range?.itemsPerPage, range?.totalItems)

    return (
      <Container ref={ref} fluid className="pager-container" {...props}>
        <Pagination>
          <PaginationItem hidden={!showFirstLastNavigationButtons} disabled={activePage <= 1}>
            <PaginationLink first onClick={() => onChange(1)}>
              <i className="fa-kit fa-caret-backwards" />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem hidden={!showPrevNextNavigationButtons} disabled={activePage <= 1}>
            <PaginationLink previous onClick={() => onChange(activePage - 1)}>
              <i className="fa-regular fa-chevron-left" />
            </PaginationLink>
          </PaginationItem>
          {pagesSelectors[0] > 1 && (
            <PaginationItem disabled>
              <PaginationLink>
                <i className="fa-kit fa-ellipsis-bottom" />
              </PaginationLink>
            </PaginationItem>
          )}
          {pagesSelectors.map(pg => (
            <PaginationItem key={pg} hidden={!showPageIndexSelectors} active={activePage === pg}>
              <PaginationLink onClick={() => onChange(pg)}>{pg}</PaginationLink>
            </PaginationItem>
          ))}
          {pagesSelectors[pagesSelectors.length - 1] !== pages.length && (
            <PaginationItem disabled>
              <PaginationLink>
                <i className="fa-kit fa-ellipsis-bottom" />
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem disabled={activePage === pages.length}>
            <PaginationLink next onClick={() => onChange(activePage + 1)}>
              <i className="fa-regular fa-chevron-right" />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={activePage === pages.length}>
            <PaginationLink last onClick={() => onChange(pages.length)}>
              <i className="fa-kit fa-caret-forwards" />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
        {range && <span className="pager-range">{`${startItem} - ${endItem} of ${range?.totalItems}`}</span>}
      </Container>
    )
  }
)

MFPager.displayName = 'MFPager'
MFPager.propTypes = propTypes
MFPager.defaultProps = defaultProps

export default MFPager
