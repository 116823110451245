import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  deleteCartItem,
  updateCartItem,
  clearSweetAlert,
  dispatchSweetAlert,
  fetchCartItems,
  fetchUserCredit,
} from '../../actions'
import _ from 'lodash'
import { stockErrors } from './cartFunctions'
import { setErrors } from '../Checkout/checkoutFunctions'
import { setMaxQuantityLoading, setCheckoutLoading } from '../Checkout/checkoutFunctions'
import ContentLoader from '../global/loaders/InlineContentLoader'
import CartItemCard from './CartItemCard'

const CartItemsList = props => {
  const [deleteConfirmationItem, setDeleteConfirmationItem] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState({})
  const [maxQtyAnyProduct, setMaxQtyAnyProduct] = useState(false)
  const [maxQtyProductReached, setMaxQtyProductReached] = useState({})
  const [query, setQuery] = useState({ cartItemId: null, qty: null })
  const [queryLoading, setQueryLoading] = useState(false)
  const [cartItemQuantities, setCartItemQuantities] = useState({})

  // set checkout error if query is loading to prevent moving between pages
  useEffect(() => {
    if (queryLoading) {
      props.setCheckoutLoading(true)
    } else {
      props.setCheckoutLoading(false)
    }
  }, [queryLoading])

  useEffect(() => {
    if (query.cartItemId) {
      // runs only if query has id - prevents initial setting of true
      setQueryLoading(true)
      const timeOutId = setTimeout(() => {
        updateCartItemQuantity()
      }, 400)
      return () => clearTimeout(timeOutId)
    }
  }, [query])

  useEffect(() => {
    resolveProductQuantities()
  }, [props.cartItems])

  useEffect(() => {
    if (props.cartItems.length !== Object.keys(cartItemQuantities).length) {
      const cartItemsMappedToIds = _.mapKeys(props.cartItems, 'cart_item_id')
      const cartItemsMappedToQty = _.mapValues(cartItemsMappedToIds, el => el.quantity)
      setCartItemQuantities(cartItemsMappedToQty)
    }
  }, [props.cartItems])

  useEffect(() => {
    // check cart items for stock errors
    const stockError = stockErrors(props.cartItems)
    props.setErrors({ stock: stockError })
  }, [props.cartItems, deleteConfirmationItem])

  useEffect(() => {
    if (Object.values(maxQtyProductReached).some(val => val === true)) {
      setMaxQtyAnyProduct(true)
      props.setErrors({ maxQtyReached: true })
    } else {
      setMaxQtyAnyProduct(false)
      props.setErrors({ maxQtyReached: false })
    }
  }, [maxQtyProductReached])

  // componentDidMount
  useEffect(() => {
    maxQtyProductHandler()

    if (props.errors.stock && !props.mini) {
      props.dispatchSweetAlert({
        type: 'error',
        alertMessage: 'Some items are out of stock! Please remove the highlighted items from your basket to continue.',
        showCancel: false,
        confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      })
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(cartItemQuantities) && !_.isEmpty(props.maxQuantities)) {
      maxQtyProductHandler()
    }
  }, [props.maxQuantities, cartItemQuantities])

  useEffect(() => {
    if (maxQtyAnyProduct === true && !props.mini) {
      toast.error('You have exceeded the maximum allowed quantity for a product.')
    }
  }, [maxQtyAnyProduct])

  // when item is selected for deletion, dispatch sweetalert
  useEffect(() => {
    if (!!deleteConfirmationItem && Object.keys(deleteConfirmationItem).length > 0) {
      handleDeleteCartItem()
    }
  }, [deleteConfirmationItem])

  // Helper functions

  const resolveProductQuantities = () => {
    if (!props.mini) {
      // reset qty value for each cart item after fetching, since qty is not controlled
      props.cartItems.forEach(cartItem => {
        // if there is a discrepancy between displayed value and DB value
        if (cartItem.quantity !== cartItemQuantities[cartItem.cart_item_id]) {
          // set value
          setCartItemQuantities({ ...cartItemQuantities, [cartItem.cart_item_id]: cartItem.quantity })
        }
      })
    }
  }

  const deleteCartItem = () => {
    setQueryLoading(true)
    props.deleteCartItem(deleteConfirmationItem.cart_item_id).then(() => {
      fetchCartItems()
      setDeleteConfirmationItem(false)
      props.clearSweetAlert()
    })
    props.clearSweetAlert()
  }

  const resetDeleteSelection = () => {
    setDeleteConfirmationItem(false)
    props.clearSweetAlert()
    setQueryLoading(false)
  }

  const handleDeleteCartItem = () => {
    const propsForSweetalert = {
      type: 'warning',
      onConfirm: deleteCartItem,
      onCancel: resetDeleteSelection,
      confirmBtnText: 'Remove',
      cancelBtnText: 'Do Not Remove',
      confirmBtnCssClass: 'mf-primary-btn alert-width-btn',
      cancelBtnCssClass: 'mf-outline-btn alert-width-btn',
      alertMessage: 'Remove this item from your cart?' + ' ' + deleteConfirmationItem.name,
    }
    props.dispatchSweetAlert(propsForSweetalert)
  }

  const updateCartItemQuantity = () => {
    const cartItemId = query.cartItemId
    const newQuantity = query.qty

    const products = _.mapKeys(props.cartItems, 'cart_item_id')
    let quantity = parseInt(newQuantity)

    // if new qty is < 1, ask user to delete
    if (!quantity || quantity < 1) {
      setDeleteConfirmationItem(products[cartItemId])
      return
    }

    const thisProduct = products[cartItemId]

    // check if minimum quantity is met
    if (thisProduct && quantity < thisProduct.minimum_qty_allowed_in_shopping_cart) {
      // Set error if min qty is not met
      props.setErrors({
        ...props.errors,
        minQtyError: [...(props.errors.minQtyError || []), cartItemId],
      })
    } else {
      // Remove item ID from errors array if min qty IS met
      props.setErrors({
        ...props.errors,
        minQtyError: _.reject(props.errors.minQtyError, e => e.cart_item_id === cartItemId),
      })
    }
    // end min qty check

    // check for qty increments
    if (thisProduct && thisProduct.enable_qty_increments) {
      // if product comes in increments, validate the incoming quantity
      if (quantity % thisProduct.qty_increments !== 0) {
        quantity = Math.ceil(quantity / thisProduct.qty_increments) * thisProduct.qty_increments
        props.setErrors({
          ...props.errors,
          incrQtyError: [...props.errors.incrQtyError, cartItemId],
        })
      } else {
        props.setErrors({
          ...props.errors,
          incrQtyError: [],
        })
      }
    }
    // end qty increment check

    props.updateCartItem(cartItemId, quantity).then(() => {
      maxQtyProductHandler()
      fetchCartItems()
    })
  }

  const fetchCartItems = () => {
    const userId = props.currentUser.id
    const locationId = props.currentLocation.id
    // we need user credits before fetching cart items so we can apply their coop credits.
    props.fetchUserCredit(locationId).then(() => {
      props
        .fetchCartItems(
          userId,
          locationId,
          props.portal,
          props.currentLocation,
          props.userCredits.coop_credit.remaining
        )
        .then(() => {
          props.setMaxQuantityLoading(false)
          setQueryLoading(false)
        })
    })
  }

  const maxQtyProductHandler = () => {
    // unify all products and map by product_id
    let products_unified = {}
    props.cartItems.map(product => {
      if (products_unified[product.product_id]) {
        products_unified[product.product_id] += product.quantity
      } else {
        products_unified[product.product_id] = product.quantity
      }
    })
    // for each entry in products_unified (for each product_id)
    let newMaxQuantities = {}
    Object.entries(products_unified).map(([id, qty]) => {
      qty = parseInt(qty, 10)
      id = parseInt(id, 10)

      const thisProduct = props.cartItems.find(ci => ci.product_id === parseInt(id, 10))

      // get the count of previously purchased products for this product_id (stored in redux)
      let numberOfProductsAvailable = props.maxQuantities[id] ? props.maxQuantities[id] : 0

      // if max qty limit is enabled for this product
      if (thisProduct && Object.keys(props.maxQuantities).includes(`${thisProduct.product_id}`)) {
        if (qty > numberOfProductsAvailable) {
          newMaxQuantities[id] = true
        } else {
          newMaxQuantities[id] = false
        }
      }
    })
    setMaxQtyProductReached(newMaxQuantities)
  }

  return props.checkout?.cart_loading ? <ContentLoader /> : (
    props.cartItems.map((cartItem, index) => (
      <CartItemCard
        key={cartItem.cart_item_id}
        index={index}
        cartItem={cartItem}
        maxQtyProductReached={maxQtyProductReached}
        {...props}
        {...{ queryLoading, setQueryLoading, query, setQuery }}
        {...{ cartItemQuantities, setCartItemQuantities }}
        {...{ tooltipOpen, setTooltipOpen, setDeleteConfirmationItem }}
      />
    ))
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    portal: state.portal,
    customerGroup: state.customerGroup,
    cartItems: state.cartItems,
    currentLocation: state.currentLocation,
    cart_items_loading: state.productPage.cart_items_loading,
    maxQuantities: state.maxQuantities,
    errors: state.checkout.errors,
    youPay: state.checkout.youPay,
    checkout: state.checkout,
    userCredits: state.userCredits,
    maxQuantityLoading: state.checkout.maxQuantityLoading,
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps, {
  clearSweetAlert,
  deleteCartItem,
  updateCartItem,
  dispatchSweetAlert,
  fetchCartItems,
  setErrors,
  fetchUserCredit,
  setMaxQuantityLoading,
  setCheckoutLoading,
})(CartItemsList)
